import './AddTeamMemberPopup.css';
import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import { useParams } from 'react-router-dom';
import AddButtonIcon from '../../assets/img/add-button-icon.svg';
import { Accordion } from '../Accorion/Accrdion';
import { AddTeamMemberForm } from './AddTeamMemberForm/AddTeamMemberForm';
import { AddTeamMemberList } from './AddTeamMemberList/AddTeamMemberList';
import { useLoader } from 'src/provider/LoaderProvider';
import { useGetUsersAllQuery } from 'src/services/usersService';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { useAddProjectTeamMembersMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';

export const AddTeamMemberPopup = ({ project, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const notification = useNotification();
  const { data: users } = useGetUsersAllQuery();
  const { id } = useParams();
  const [
    addTeamMember,
    {
      data: addTeamMemberRes,
      error: errorAddTeamMember,
      isError: isErrorAddTeamMember,
      isLoading: isLoadingAddTeamMember,
      isSuccess: isSuccessAddTeamMember,
    },
  ] = useAddProjectTeamMembersMutation();
  const [unassignedUsers, setUnassignedUsers] = useState(null);
  const loader = useLoader();

  useEffect(() => {
    if (users) {
      const unassignedUsers = users.filter((user) => {
        return !project?.team_members?.find((teamMember) => teamMember.user.id === user.id);
      });

      setUnassignedUsers(unassignedUsers);
    }
  }, [users]);

  const createTeamMember = (userResponse) => {
    const request = {
      user: userResponse.id,
    };
    addTeamMember({ id, user: request?.user });
  };

  const addTeamMemberFromList = (request) => {
    addTeamMember({ id, user: request?.user });
  };

  useEffect(() => {
    if (isSuccessAddTeamMember) {
      notification.success(`Team Member ${addTeamMemberRes.user.first_name} was added.`);
      setOpen(false);
    }
  }, [isSuccessAddTeamMember]);

  useEffect(() => {
    if (isLoadingAddTeamMember) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingAddTeamMember]);

  useEffect(() => {
    if (isErrorAddTeamMember) {
      notification.error(getErrorMessage(errorAddTeamMember));
    }
  }, [isErrorAddTeamMember]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <Button className="transparent">
          <span>Add user</span>
          <img src={AddButtonIcon} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add team member</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="content">
            <div className="sub-title">
              <h4>Available methods:</h4>
            </div>
            <Accordion className={'add-teammember-accordion'} title={'Create a new user'}>
              <AddTeamMemberForm onSubmit={(response) => createTeamMember(response)} />
            </Accordion>
            <Accordion
              className={'add-teammember-accordion-list'}
              title={'Select user from other your projects'}
            >
              <AddTeamMemberList onSubmit={(request) => addTeamMemberFromList(request)} />
            </Accordion>

            {/*{*/}
            {/*    unassignedUsers && unassignedUsers.map((user) => {*/}
            {/*        return (<div key={user.id}>{`${user.first_name} ${user.last_name}`}</div>)*/}
            {/*    })*/}
            {/*}*/}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
