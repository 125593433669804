import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProjectByIdQuery, useProjectDemoModeMutation } from 'src/services';
import { Switch } from 'src/components/ui/switch';
import { useNotification } from 'src/hooks/useNotification';
import { getErrorMessage } from 'src/utils/get-error-message';
import { ProductsTypeBack } from 'src/constants/products';

export const DemoModeToggle = ({ close = () => {} }) => {
  const { id } = useParams();
  const notification = useNotification();

  const { data: currentProject } = useGetProjectByIdQuery({ id: id });

  const [
    updateDemoMode,
    { isLoading, isError: isUpdateUpdateDemoMode, error: errorUpdateDemoMode, isSuccess },
  ] = useProjectDemoModeMutation();

  useEffect(() => {
    if (isSuccess) {
      close();
      notification.success('Demo mode was updated!');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateUpdateDemoMode) {
      notification.error(getErrorMessage(errorUpdateDemoMode));
    }
  }, [isUpdateUpdateDemoMode]);

  const isDemoMode = currentProject?.is_demo_mode;

  const handleChangeDemoMode = (value) => {
    updateDemoMode({
      id: id,
      project_type: ProductsTypeBack[currentProject?.project_type],
      enable: value,
    });
  };

  return (
    <div className="content">
      <div className="flex items-center gap-2.5">
        <p className="font-medium text-xs text-grey_text">Demo mode</p>
        <Switch
          disabled={isLoading}
          defaultChecked={isDemoMode}
          checked={isDemoMode}
          onCheckedChange={handleChangeDemoMode}
        />
      </div>
    </div>
  );
};
