import React, { useEffect, useMemo, useRef } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useAddPaymentMethodsMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { booleanScheme, stringScheme } from 'src/utils/validation-schemes';
import { clsx } from 'clsx';

const useOptions = () => {
  return useMemo(
    () => ({
      className: 'testClass',
      style: {
        base: {
          fontSize: '16px',
          fontWeight: '500',
          color: '#05050DCC',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#B8C1C6',
          },
        },
        invalid: {
          color: '#FF5151',
        },
      },
    }),
    [],
  );
};

export const SplitElements = ({ handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const notification = useNotification();
  const loader = useLoader();

  const options = useOptions();

  const [
    addPaymentMethod,
    { isLoading, isError: isAddPaymentMethodError, error: addPaymentMethodError, isSuccess },
  ] = useAddPaymentMethodsMutation();

  useEffect(() => {
    if (isLoading) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isAddPaymentMethodError) {
      notification.error(getErrorMessage(addPaymentMethodError));
    }
  }, [isAddPaymentMethodError]);

  useEffect(() => {
    if (isSuccess) {
      notification.success('Payment method was added successfully.');
      handleClose();
    }
  }, [isSuccess]);

  const { handleSubmit, control, setError, watch, clearErrors } = useForm({
    defaultValues: {
      card_number: {},
      expiration: {},
      cvc: {},
    },
    resolver: yupResolver(
      object({
        card_number: object({
          complete: booleanScheme(),
          error: object({
            message: stringScheme(),
          }),
        }).label('Card number'),
        expiration: object({
          complete: booleanScheme(),
          error: object({
            message: stringScheme(),
          }),
        }).label('Expiration date'),
        cvc: object({
          complete: booleanScheme(),
          error: object({
            message: stringScheme(),
          }),
        }).label('CVC'),
      }),
    ),
  });

  const handleAddPaymentMethod = async (paymentMethod) => {
    addPaymentMethod({
      payment_method_id: paymentMethod.paymentMethod.id,
    });
  };

  const handleSubmitCard = async () => {
    if (!stripe || !elements) {
      return;
    }
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    if (payload?.paymentMethod) {
      await handleAddPaymentMethod(payload);
    }
  };

  const watchAllFields = watch(['card_number', 'expiration', 'cvc']);

  const creditRef = useRef(null);
  const expirationRef = useRef(null);
  const cvcRef = useRef(null);

  return (
    <form id="add-payment-method-form" onSubmit={handleSubmit(handleSubmitCard)}>
      <div className={'input-container'}>
        <label>
          Credit / debit card number *
          <div
            ref={creditRef}
            className={clsx(
              'rounded-md border transition duration-300 px-5 py-3.5 bg-white relative',
              watchAllFields[0]?.error?.message && 'border-error',
            )}
          >
            <Controller
              control={control}
              name="card_number"
              render={({ field: { onChange } }) => (
                <>
                  <CardNumberElement
                    options={options}
                    onReady={() => {
                      clearErrors('card_number');
                    }}
                    onChange={(event) => {
                      onChange({
                        complete: event?.complete,
                        error: {
                          message: event?.error?.message,
                        },
                      });
                      if (event?.error?.message) {
                        setError('card_number', {
                          message: event?.error?.message,
                        });
                      }
                    }}
                    onBlur={() => {
                      creditRef?.current.classList.remove('border-malibu');
                      creditRef?.current.classList.remove('[&_.error-message]:hidden');
                    }}
                    onFocus={() => {
                      creditRef?.current.classList.add('border-malibu');
                      creditRef?.current.classList.add('[&_.error-message]:hidden');
                    }}
                  />
                  {watchAllFields[0]?.error?.message && (
                    <div className="max-md:hidden error-message absolute flex items-center justify-center px-2.5 py-2 right-2 top-1/2 transform -translate-y-1/2 h-[calc(100%-14px)] bg-error_bg rounded overflow-hidden">
                      <p className="text-sm font-semibold text-error">
                        {watchAllFields[0]?.error?.message}
                      </p>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </label>
      </div>
      <label>
        Expiration month and year *
        <div
          ref={expirationRef}
          className={clsx(
            'rounded-md border transition duration-300 px-5 py-3.5 bg-white relative',
            watchAllFields[1]?.error?.message && 'border-error',
          )}
        >
          <Controller
            control={control}
            name="expiration"
            render={({ field: { onChange } }) => (
              <>
                <CardExpiryElement
                  options={options}
                  onReady={() => {
                    clearErrors('expiration');
                  }}
                  onChange={(event) => {
                    onChange({
                      complete: event?.complete,
                      error: {
                        message: event?.error?.message,
                      },
                    });
                    if (event?.error?.message) {
                      setError('expiration', {
                        message: event?.error?.message,
                      });
                    }
                  }}
                  onBlur={() => {
                    expirationRef?.current.classList.remove('border-malibu');
                    expirationRef?.current.classList.remove('[&_.error-message]:hidden');
                  }}
                  onFocus={() => {
                    expirationRef?.current.classList.add('border-malibu');
                    expirationRef?.current.classList.add('[&_.error-message]:hidden');
                  }}
                />
                {watchAllFields[1]?.error?.message && (
                  <div className="max-md:hidden error-message absolute flex items-center justify-center px-2.5 py-2 right-2 top-1/2 transform -translate-y-1/2 h-[calc(100%-14px)] bg-error_bg rounded overflow-hidden">
                    <p className="text-sm font-semibold text-error">
                      {watchAllFields[1]?.error?.message}
                    </p>
                  </div>
                )}
              </>
            )}
          />
        </div>
      </label>
      <label>
        CVC/CVV *
        <div
          ref={cvcRef}
          className={clsx(
            'rounded-md border transition duration-300 px-5 py-3.5 bg-white relative',
            watchAllFields[2]?.error?.message && 'border-error',
          )}
        >
          <Controller
            control={control}
            name="cvc"
            render={({ field: { onChange } }) => (
              <>
                <CardCvcElement
                  options={options}
                  onReady={() => {
                    clearErrors('cvc');
                  }}
                  onChange={(event) => {
                    onChange({
                      complete: event?.complete,
                      error: {
                        message: event?.error?.message,
                      },
                    });
                    if (event?.error?.message) {
                      setError('cvc', {
                        message: event?.error?.message,
                      });
                    }
                  }}
                  onBlur={() => {
                    cvcRef?.current.classList.remove('border-malibu');
                    cvcRef?.current.classList.remove('[&_.error-message]:hidden');
                  }}
                  onFocus={() => {
                    cvcRef?.current.classList.add('border-malibu');
                    cvcRef?.current.classList.add('[&_.error-message]:hidden');
                  }}
                />
                {watchAllFields[2]?.error?.message && (
                  <div className="max-md:hidden error-message absolute flex items-center justify-center px-2.5 py-2 right-2 top-1/2 transform -translate-y-1/2 h-[calc(100%-14px)] bg-error_bg rounded overflow-hidden">
                    <p className="text-sm font-semibold text-error">
                      {watchAllFields[2]?.error?.message}
                    </p>
                  </div>
                )}
              </>
            )}
          />
        </div>
      </label>
    </form>
  );
};
