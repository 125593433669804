import { FilesGridViewIcon, FilesListViewIcon } from 'src/assets/icons/FileIcons';
import { getKnowledgeBaseConfig } from 'src/utils/pageStorageConfig';
import { Button } from 'src/components/ui/button';
import React from 'react';

export const ViewTypesButton = ({ onViewTypeChange }) => {
  const { viewType } = getKnowledgeBaseConfig();
  const [viewTypeState, setViewTypeState] = React.useState(viewType || 'list');
  const onClick = () => {
    const newState = viewTypeState === 'list' ? 'grid' : 'list';

    setViewTypeState(newState);
    onViewTypeChange(newState);
  };
  return (
    <Button
      onClick={onClick}
      variant="secondary"
      className="opacity-60 hover:opacity-100 transition gap-[30px] sort-files-button outline-0 h-[36px]"
    >
      {viewTypeState === 'list' ? <FilesListViewIcon /> : <FilesGridViewIcon />}
    </Button>
  );
};
