import { useState, useEffect } from 'react';

export const useSingleAndDoubleClick = (actionSimpleClick, actionDoubleClick, delay = 250) => {
  const [event, setEvent] = useState(false);
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (click === 1) actionSimpleClick(event);
      setClick(0);
    }, delay);

    if (click === 2) actionDoubleClick(event);

    return () => clearTimeout(timer);
  }, [click]);

  return (e) => {
    setEvent(e);
    setClick((prev) => prev + 1);
  };
};
