import React, { useEffect, useRef, useState } from 'react';
import './ProjectsMultiSelect.css';
import { clsx } from 'clsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Checkbox } from 'src/components/ui/checkbox';

const CloseIcon = ({ isOpen }) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isOpen ? 'translate' : ''}
    >
      <path
        d="M7.47395 11.4853L15.9592 19.9706L24.4445 11.4853"
        stroke="#05050D"
        strokeOpacity="0.7"
        strokeWidth="2"
      />
    </svg>
  );
};

const ProjectIcon = ({ projectType, className = '' }) => {
  let color;

  switch (projectType) {
    case 'agent': {
      color = '#75F3FB';
      break;
    }
    case 'customer': {
      color = '#A498FF';
      break;
    }
    case 'bi': {
      color = '#DFF800';
      break;
    }
    default: {
      color = 'grey';
    }
  }

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M0 2.4C0 1.07452 1.07452 0 2.4 0H15.6C16.9255 0 18 1.07452 18 2.4V15.6C18 16.9255 16.9255 18 15.6 18H2.4C1.07452 18 0 16.9255 0 15.6V2.4Z"
        fill={color}
      />
      <path
        d="M6.8231 5.58664C5.92374 5.58664 5.03933 5.94645 4.40338 6.58692C3.76743 7.22739 3.41016 8.09605 3.41016 9.00182C3.41016 9.90758 3.76743 10.7762 4.40338 11.4167C5.03933 12.0572 5.92374 12.417 6.8231 12.417L6.8231 9.00182L6.8231 5.58664Z"
        fill="#05050D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1805 12.4163C13.0654 12.4163 14.5935 10.8873 14.5935 9.00111C14.5935 7.11496 13.0654 5.58594 11.1805 5.58594C9.29561 5.58594 7.76758 7.11496 7.76758 9.00111C7.76758 10.8873 9.29561 12.4163 11.1805 12.4163ZM11.1733 10.3982C11.9444 10.3982 12.5696 9.77268 12.5696 9.00107C12.5696 8.22946 11.9444 7.60395 11.1733 7.60395C10.4022 7.60395 9.77714 8.22946 9.77714 9.00107C9.77714 9.77268 10.4022 10.3982 11.1733 10.3982Z"
        fill="#05050D"
      />
    </svg>
  );
};

export const ProjectsMultiSelect = ({
  projects = [],
  defaultValues,
  onChange = () => {},
  onClose = () => {},
  align,
  label,
  dropdownMenuClassNames = '',
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const labelRef = useRef(null);
  const inputRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (defaultValues && selectedValues !== defaultValues) {
      setSelectedValues(defaultValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (!showMenu) {
      onClose && onClose(selectedValues);
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (
        labelRef.current &&
        !labelRef.current.contains(e.target) &&
        inputRef.current &&
        !inputRef.current.contains(e.target) &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  const handleInputClick = () => {
    setShowMenu((prevState) => !prevState);
  };

  const onItemClick = (checked, option) => {
    let selected;

    if (checked) {
      selected = [...selectedValues, option];
      setSelectedValues(selected);
    } else {
      selected = selectedValues.filter((value) => value.id !== option.id);
      setSelectedValues(selected);
    }

    onChange(selected, option, checked);
  };

  const isSelected = (option) => {
    if (!selectedValues.length) {
      return false;
    }

    return selectedValues.some((value) => value.id === option.id);
  };

  return (
    <div className="projects--multiselect-container">
      {label && (
        <label ref={labelRef} onClick={handleInputClick}>
          {label}
        </label>
      )}
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className={clsx(showMenu ? 'rounded-t-[7px]' : 'rounded-[7px]', 'dropdown-input')}
      >
        <div
          className={`static flex items-center gap-[7px] dropdown-selected-value ${!selectedValues || selectedValues.length === 0 ? 'placeholder' : ''}`}
        >
          <p className="w-[80%] md:w-[86%] text-ellipsis	whitespace-nowrap overflow-hidden absolute">
            {selectedValues?.length
              ? selectedValues.map((selectedValue, index, arr) => {
                  return (
                    <span
                      key={`${selectedValue.name.toLowerCase()}_${index}`}
                      className="text-start hyphens-auto align-middle mr-[10px]"
                    >
                      <ProjectIcon
                        projectType={selectedValue.project_type}
                        className={'inline mr-[10px] mt-[-2px] align-middle text-middle'}
                      />
                      {selectedValue.name}
                      {index !== arr.length - 1 && ', '}
                    </span>
                  );
                })
              : label}
          </p>
        </div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <CloseIcon isOpen={showMenu} />
          </div>
        </div>
      </div>
      {showMenu && (
        <div
          className={clsx(
            dropdownMenuClassNames,
            `dropdown-menu relative alignment--${align || 'auto'} w-full max-h-80`,
          )}
          ref={menuRef}
        >
          {projects &&
            projects.map((project, index) => {
              const key = `${project?.name.toLowerCase().replace(/\s/g, '-')}-${index}`;

              return (
                project?.name && (
                  <TooltipProvider key={key}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div
                          className={`flex flex-row items-center gap-[7px] w-full dropdown-item ${isSelected(project) && 'selected'}`}
                        >
                          <Checkbox
                            id={`multiselect-${key}`}
                            checked={isSelected(project)}
                            className={'rounded-[2px]'}
                            onCheckedChange={(checked) => {
                              onItemClick(checked, project);
                            }}
                          />
                          <ProjectIcon projectType={project.project_type} />
                          <label
                            htmlFor={`multiselect-${key}`}
                            className="text-base font-medium text-ebony"
                          >
                            {project?.name}
                          </label>
                        </div>
                      </TooltipTrigger>
                      {project?.toolTipDescription && (
                        <TooltipContent className="max-w-2xl">
                          {project.toolTipDescription}
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>
                )
              );
            })}
        </div>
      )}
    </div>
  );
};
