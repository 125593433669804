import React from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { clsx } from 'clsx';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { useGetAccountsQuery } from 'src/services/accountsService';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { DescTopTable } from './DescTopTable';
import { MobileTable } from './MobileTable';

export const CurrentSubscriptionPlans = ({ projectsSubscriptionsIsFetching, infoOnly }) => {
  const isMdDown = useMediaQuery('(max-width: 767px)');

  const { data: userInfo } = useGetUsersMeQuery();

  const { data: account, isFetching: accountIsFetching } = useGetAccountsQuery();

  const isEmpty = !Boolean(Object.keys(account?.general_plan || {})?.length);

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  const generalPlan = account?.general_plan;

  const planRequestLimit =
    generalPlan?.custom_plan_settings?.request_limit || generalPlan?.plan?.request_limit;

  const generalPlanRequestLimit = generalPlan?.request_limit;

  return isEmpty ? (
    <div className="empty-message">
      <LoaderWrapper loader={<Skeleton className="w-full h-16" />} isLoading={accountIsFetching}>
        <div className="border rounded-lg px-5 md:py-5 py-4">
          <p className="opacity-70 font-semibold text-base text-ebony">Add subscription plan.</p>
        </div>
      </LoaderWrapper>
    </div>
  ) : (
    <section
      className={clsx(
        'team-list transition',
        projectsSubscriptionsIsFetching && 'animate-pulse blur-sm pointer-events-none',
      )}
    >
      {isMdDown ? (
        <MobileTable
          infoOnly={infoOnly}
          planRequestLimit={planRequestLimit}
          generalPlanRequestLimit={generalPlanRequestLimit}
          generalPlan={generalPlan}
        />
      ) : (
        <>
          <DescTopTable
            infoOnly={infoOnly}
            planRequestLimit={planRequestLimit}
            generalPlanRequestLimit={generalPlanRequestLimit}
            generalPlan={generalPlan}
            isCosupportManager={isCosupportManager}
          />
        </>
      )}
    </section>
  );
};
