import { useContext, useState } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { ThemeColors } from 'src/constants/theme-colors';

const THEME_CONFIG = {
  green: {
    color: '#33FF71',
    colorFill: '#21C007',
    className: ThemeColors.green,
  },
  blue: {
    color: '#75F3FB',
    colorFill: '#75F3FB',
    className: ThemeColors.blue,
  },
  yellow: {
    color: '#E8FD65',
    colorFill: '#E8FD65',
    className: ThemeColors.yellow,
  },
  purple: {
    color: '#BDB4FD',
    colorFill: '#A498FF',
    className: ThemeColors.purple,
  },
};

export function useTheme() {
  return useContext(ThemeContext);
}
export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('green');

  let value = {
    theme,
    setTheme,
    config: THEME_CONFIG[theme],
  };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}
