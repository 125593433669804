import React from 'react';
import { TabsContent } from 'src/components/ui/tabs';
import { clsx } from 'clsx';

export const AnalyticsTabsContent = ({ tabId, className = '', children }) => {
  return (
    <TabsContent className={clsx('TabsContent p-[20px] mt-0 ', className)} value={tabId}>
      {children}
    </TabsContent>
  );
};
