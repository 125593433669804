import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { clsx } from 'clsx';
import { useAppSelector } from 'src/store';
import { getMenuItems } from 'src/components/PageContentWrapper/MenuBarItems';
import { useGetUsersMeQuery } from 'src/services/usersService';

export const MenuBarComponent = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isProjectLocation = location.pathname.includes('projects');
  const isAccountLocation = location.pathname.includes('account');
  const isAnalyticsLocation = location.pathname.includes('analytics');
  const isSubscriptionLocation = location.pathname.includes('subscription');
  const isKnowledgeBaseLocation = location.pathname.includes('knowledge-base');
  const isIntegrationsLocation = location.pathname.includes('integrations');
  const { projectType } = useAppSelector((s) => s.projectsSlices);
  const type = projectType || searchParams.get('type');
  const { data: userInfo } = useGetUsersMeQuery();
  const role = userInfo?.role;

  useEffect(() => {
    if (isProjectLocation) {
      setActiveIndex(0);
    }
    if (isAnalyticsLocation) {
      setActiveIndex(1);
    }
    if (isKnowledgeBaseLocation) {
      setActiveIndex(2);
    }
    if (isIntegrationsLocation) {
      setActiveIndex(3);
    }
  }, []);

  const menuBarItems = getMenuItems(type);

  const barHandleClick = (href) => {
    navigate(`${href}${location.search}`);
  };

  const isProject = isProjectLocation ?? isAnalyticsLocation;
  return (
    <div className="absolute flex ml-5 gap-10 -top-12 z-50">
      {isProject ? (
        menuBarItems
          .filter((item) => item.visibleFor?.includes(type))
          .filter((item) => item.roles.includes(role))
          .map((item, i) => (
            <div
              onClick={() => barHandleClick(item.href)}
              key={item.id}
              className={clsx(
                'flex pb-3.5 border-ebony cursor-pointer transition',
                activeIndex === item.id
                  ? 'border-b-4 pointer-events-none'
                  : 'opacity-50 hover:opacity-100',
                item.disabled && 'opacity-50 pointer-events-none',
              )}
            >
              <p className="font-bold text-[22px]">{item.title}</p>
            </div>
          ))
      ) : (
        <div className="flex pb-3.5 border-b-4 border-ebony cursor-pointer">
          <p className="font-bold text-[22px]">
            {(isAccountLocation && 'Account') || (isSubscriptionLocation && 'Subscription')}
          </p>
        </div>
      )}
    </div>
  );
};
