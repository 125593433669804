import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  Bar,
  BarChart,
} from 'recharts';
import constants from 'src/pages/AnalyticsPage/constants';

export const AnalyticsAgentBarChart = ({
  dataKey = '',
  data = [],
  dateRange = 'day',
  color = '#65D5DC',
  isByUser = false,
  userColors,
}) => {
  const isDayRange = dateRange === 'day';
  const userNames = [];
  const chartData = isByUser
    ? data.map((dataItem) => {
        return Object.keys(dataItem).reduce((previousValue, currentValue) => {
          if (currentValue === 'date') {
            return {
              ...previousValue,
              [currentValue]: dataItem[currentValue],
            };
          }

          if (!userNames.includes(currentValue)) {
            userNames.push(currentValue);
          }

          return {
            ...previousValue,
            [currentValue]: dataItem[currentValue][dataKey] || 0,
          };
        }, {});
      })
    : data;

  let YAxisLabel, xLabelPosition;

  switch (dataKey) {
    case constants.resolved_with_ai:
      YAxisLabel = 'Requests';
      xLabelPosition = 60;
      break;
    case constants.bad_suggestions:
      YAxisLabel = 'Bad Suggestions';
      xLabelPosition = 80;
      break;
    case constants.accuracy:
      YAxisLabel = '%';
      xLabelPosition = 70;
      break;
    default:
      YAxisLabel = 'Requests';
      xLabelPosition = 60;
  }

  const tooltipFormatter = (value) => {
    return `${value} ${YAxisLabel}`;
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      minHeight={'500px'}
      className={'bg-white rounded-[6px]'}
    >
      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 50,
          right: 50,
          left: 10,
          bottom: 50,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="1 1" />
        <XAxis dataKey={'date'}>
          <Label
            value={isDayRange ? 'Days' : 'Hours'}
            offset={-10}
            position="insideBottomRight"
            className={'fill-ebony text-sm font-semibold'}
          />
        </XAxis>
        <YAxis>
          <Label
            value={YAxisLabel}
            position="insideTopLeft"
            content={({ value }) => {
              return (
                <text
                  offset="0"
                  x="10"
                  y="20"
                  className="recharts-text fill-ebony text-sm font-semibold"
                  textAnchor="middle"
                  fill="#808080"
                >
                  <tspan x={xLabelPosition} dy="0.71em">
                    {value}
                  </tspan>
                </text>
              );
            }}
            className={'fill-ebony text-sm font-semibold'}
          />
        </YAxis>
        <Tooltip
          formatter={tooltipFormatter}
          labelStyle={{
            color: '#FFF',
            fontFamily: 'Bai Jamjuree',
            fontSize: '14px',
          }}
          contentStyle={{
            borderRadius: '5px',
            background: '#05050D',
          }}
          itemStyle={{
            fontFamily: 'Bai Jamjuree',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
          }}
        />
        {Boolean(data.length) && (
          <ReferenceLine
            x={data[parseInt((data.length / 2).toFixed(0)) - 1]['date']}
            stroke="#CCCCCC"
            strokeDasharray="1 1"
          />
        )}
        {isByUser ? (
          userNames.map((userName) => {
            return (
              <Bar
                stackId="stackId"
                key={userName}
                name={userName}
                dataKey={userName}
                fill={userColors ? userColors[userName] : color}
              />
            );
          })
        ) : (
          <Bar name={dataKey} dataKey={dataKey} fill={color} />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
