import React from 'react';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'src/services';
import { ChangeNameModal } from './ChangeNameModal';

export const ChangeName = () => {
  const { id } = useParams();
  const { data: currentProject, isFetching: isProjectFetching } = useGetProjectByIdQuery({
    id: id,
  });

  return (
    <div className="flex flex-col gap-1.5 w-full">
      <div className="title">
        <p className="text-grey_text font-medium text-xs">Name</p>
      </div>
      <div
        className={
          'input-container full-width border rounded-[7px] h-full px-4 py-1 flex justify-between items-center gap-2'
        }
      >
        <LoaderWrapper
          loader={
            <div className="flex justify-between w-full items-end">
              <Skeleton className="h-4 w-full" />
            </div>
          }
          isLoading={isProjectFetching}
        >
          <p
            title={currentProject?.name}
            className="md:text-base text-sm font-medium text-ebony truncate"
          >
            {currentProject?.name}
          </p>
        </LoaderWrapper>
        <ChangeNameModal />
      </div>
    </div>
  );
};
