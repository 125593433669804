import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { AddIcon } from 'src/assets/img/add-icon';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox } from 'src/components/ui/checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, number, object } from 'yup';
import { menuItemsProducts } from 'src/components/Menu/menuItems';
import Button from 'src/components/Button/Button';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setProducts } from 'src/store/slices/projectsSlices';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import { useLocation, useNavigate } from 'react-router-dom';

export const ProductCreateModal = ({ disabled = false, projectsByType }) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isProjectLocation = location.pathname.includes('projects');

  const { products, projectType } = useAppSelector((s) => s.projectsSlices);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      products_id: products?.map((product) => ({ id: product.id })) || [],
    },
    resolver: yupResolver(
      object({
        products_id: array(object({ id: number() }))
          // .min(1, 'You must select at least one product.')
          .required()
          .label('Product'),
      }),
    ),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const submit = (formValues) => {
    const filteredProducts = menuItemsProducts.filter((item) =>
      formValues.products_id.some((el) => el.id === item.id),
    );

    dispatch(
      setProducts({
        products: filteredProducts,
      }),
    );
    handleClose();

    if (!filteredProducts.some((item) => item.type === projectType) && isProjectLocation) {
      navigate(`${PATH_DASHBOARD.ACCOUNT}`);
    }
  };

  const activateSubscriptionCheckBoxHandler = (checked, onChange, value, product) => {
    checked
      ? onChange([...value, { id: Number(product.id) }])
      : onChange(value.filter((val) => val.id !== product.id));
  };

  useEffect(() => {
    if (products) {
      reset({ products_id: products?.map((product) => ({ id: product.id })) });
    }
  }, [products]);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <button className="flex items-center justify-start py-2 px-7 gap-2 opacity-40 hover:opacity-100 transition-all rounded">
          <AddIcon strokeColor={'#FFFFFF'} />
          <span className="text-white">Add Product</span>
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-ebony">Add Product</DialogTitle>
        </DialogHeader>
        <form id="add-product-form" onSubmit={handleSubmit(submit)}>
          <div className="flex flex-col relative">
            <Controller
              control={control}
              name="products_id"
              render={({ field: { onChange, value } }) =>
                menuItemsProducts.map((product) => (
                  <div key={product.id} className="flex items-center space-x-5 py-6 border-b">
                    <Checkbox
                      disabled={projectsByType[product.type].length}
                      id={`sub-${product.id}`}
                      checked={value?.some((val) => val.id === product.id)}
                      onCheckedChange={(checked) => {
                        activateSubscriptionCheckBoxHandler(checked, onChange, value, product);
                      }}
                    />
                    <label
                      htmlFor={`sub-${product.id}`}
                      className="text-base flex items-center gap-2.5 text-ebony cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {product?.icon}
                      {product?.title}
                    </label>
                  </div>
                ))
              }
            />
            {errors?.products_id?.message && (
              <p className="text-sm font-semibold text-error absolute top-full mt-3">
                {errors.products_id.message}
              </p>
            )}
          </div>
        </form>
        <DialogFooter>
          <Button type="submit" form="add-product-form" className="green min-w-28 !w-auto">
            Add
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
