import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useDeleteUserMutation } from 'src/services/usersService';

export const DeleteUserPopup = ({ user, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const notification = useNotification();
  const loader = useLoader();
  const [
    deleteUser,
    {
      isSuccess: isSuccessDeleteUser,
      isError: isErrorDeleteUser,
      error: errorDeleteUser,
      isLoading: isLoadingDeleteUser,
    },
  ] = useDeleteUserMutation();

  const onDeleteUserClick = () => {
    deleteUser({ userId: user.id });
  };

  useEffect(() => {
    if (isSuccessDeleteUser) {
      notification.success(`User ${user.first_name} ${user.last_name} Deleted.`);
      setOpen(false);
    }
  }, [isSuccessDeleteUser]);

  useEffect(() => {
    if (isErrorDeleteUser) {
      notification.error(getErrorMessage(errorDeleteUser));
    }
  }, [isErrorDeleteUser]);

  useEffect(() => {
    if (isLoadingDeleteUser) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingDeleteUser]);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'outline-0 cursor-pointer')}
      >
        <button className={'user-settings-button red'}>Delete</button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete user</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <p className="text-base	font-medium">
            Are you sure you want to delete this user irretrievably?
          </p>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button style={{ width: 'auto' }} className="transparent red" onClick={onDeleteUserClick}>
            Yes, delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
