import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import React, { useState } from 'react';
import { ProductsTypeBackPretty } from 'src/constants/products';
import { Separator } from 'src/components/ui/separator';

export const UserProjectsToolTip = ({ projects }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="cursor-pointer self-start">
      <TooltipProvider>
        <Tooltip open={open} onOpenChange={setOpen}>
          <TooltipTrigger asChild>
            <div
              className={
                'bg-light_badge p-1 text-xs font-jamjuree font-bold rounded-sm text-ebony_opacity_50 hover:text-ebony'
              }
              onClick={() => setOpen(true)}
            >
              <span className={'select-none'}>+{projects.length}</span>
            </div>
          </TooltipTrigger>
          <TooltipContent
            side={'left'}
            className="max-w-2xl text-[0.75rem] text-[rgba(5, 5, 13, 0.50)] select-none py-4 px-2"
          >
            {projects.map(({ name, project_type: type }, index) => {
              return (
                <div key={`${name}-${type}-${index}`} className={'flex flex-col font-medium'}>
                  <span className={'text-base text-ebony'}>{name}</span>
                  <span className={'text-xs text-ebony_opacity_50'}>
                    {ProductsTypeBackPretty[type]}
                  </span>
                  {index < projects.length - 1 && <Separator className={'my-[15px]'} />}
                </div>
              );
            })}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
