import './AnalyticsCard.css';
import React, { useState } from 'react';
import { clsx } from 'clsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';

export const AnalyticsCard = ({
  toolTipDescription = null,
  icon = null,
  metric = null,
  percentage = null,
  className = '',
  title,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div
      className={clsx(
        'flex flex-col size-full md:gap-[10px] gap-[6px] pl-[16px] pb-[16px] pt-[10px] pr-[10px] bg-[#E5E6FF] md:pb-[20px] md:pl-[20px] rounded-[5px] md:rounded-[7px] shadow-card shadow-black/10',
        className,
      )}
    >
      <div className="flex flex-row size-full justify-between">
        <div className="flex flex-row gap-[8px] items-center">
          <div className="text-[0.875rem] font-semibold text-ebony">{title}</div>
          {toolTipDescription && (
            <div className="cursor-pointer">
              <TooltipProvider>
                <Tooltip open={tooltipOpen} onOpenChange={setTooltipOpen}>
                  <TooltipTrigger asChild>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      onClick={() => setTooltipOpen(true)}
                    >
                      <path
                        d="M6.3 10.5H7.7V6.3H6.3V10.5ZM7 4.9C7.19833 4.9 7.3647 4.8328 7.4991 4.6984C7.6335 4.564 7.70046 4.39787 7.7 4.2C7.69953 4.00213 7.63233 3.836 7.4984 3.7016C7.36446 3.5672 7.19833 3.5 7 3.5C6.80166 3.5 6.63553 3.5672 6.5016 3.7016C6.36767 3.836 6.30047 4.00213 6.3 4.2C6.29953 4.39787 6.36673 4.56423 6.5016 4.6991C6.63647 4.83397 6.8026 4.90093 7 4.9ZM7 14C6.03167 14 5.12167 13.8161 4.27 13.4484C3.41833 13.0807 2.6775 12.582 2.0475 11.9525C1.4175 11.323 0.918867 10.5821 0.551601 9.73C0.184334 8.87786 0.000467553 7.96786 8.86075e-07 7C-0.00046578 6.03213 0.183401 5.12213 0.551601 4.27C0.919801 3.41787 1.41843 2.67703 2.0475 2.0475C2.67657 1.41797 3.4174 0.919333 4.27 0.5516C5.1226 0.183867 6.0326 0 7 0C7.9674 0 8.8774 0.183867 9.73 0.5516C10.5826 0.919333 11.3234 1.41797 11.9525 2.0475C12.5816 2.67703 13.0804 3.41787 13.4491 4.27C13.8178 5.12213 14.0014 6.03213 14 7C13.9986 7.96786 13.8147 8.87786 13.4484 9.73C13.0821 10.5821 12.5834 11.323 11.9525 11.9525C11.3216 12.582 10.5807 13.0809 9.73 13.4491C8.87926 13.8173 7.96926 14.0009 7 14ZM7 12.6C8.56333 12.6 9.8875 12.0575 10.9725 10.9725C12.0575 9.8875 12.6 8.56333 12.6 7C12.6 5.43667 12.0575 4.1125 10.9725 3.0275C9.8875 1.9425 8.56333 1.4 7 1.4C5.43667 1.4 4.1125 1.9425 3.0275 3.0275C1.9425 4.1125 1.4 5.43667 1.4 7C1.4 8.56333 1.9425 9.8875 3.0275 10.9725C4.1125 12.0575 5.43667 12.6 7 12.6Z"
                        fill="#05050D"
                      />
                    </svg>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-2xl text-[0.75rem] text-[rgba(5, 5, 13, 0.50)] select-none">
                    {toolTipDescription}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
        </div>
        {icon}
      </div>
      <div className="flex flex-row size-full items-center gap-[8px]">
        {metric && <div className="flex text-2xl font-bold text-ebony items-center">{metric}</div>}
        {typeof percentage !== 'undefined' && percentage !== null && (
          <div
            className={clsx(
              'text-xs font-semibold flex flex-row',
              percentage > 0 ? 'text-[#877CDB]' : 'text-[#EA4949]',
            )}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                {percentage > 0 ? (
                  <path d="M8.5 4L12.3971 10.75H4.60289L8.5 4Z" fill="#877CDB" />
                ) : (
                  <path d="M8.5 12L4.60289 5.25H12.3971L8.5 12Z" fill="#EA4949" />
                )}
              </svg>
            </div>
            {percentage}%
          </div>
        )}
      </div>
    </div>
  );
};
