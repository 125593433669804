import './FileSettingsPopover.css';
import React from 'react';
import { clsx } from 'clsx';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { FileSettingsIcon } from 'src/assets/icons/FileIcons';
import { MoveFilesPopup } from 'src/components/FileManager/MoveFilesPopup/MoveFilesPopup';
import { RenameFilePopup } from 'src/components/FileManager/RenameFilePopup/RenameFilePopup';

export const FileSettingsPopover = ({
  item,
  disabled = false,
  moveItem,
  renameItem,
  deleteItem,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className={clsx('flex items-center relative z-50')}>
      <Popover modal={true} open={open} onOpenChange={(openState) => setOpen(openState)}>
        <PopoverTrigger asChild>
          <div className="dropdown-trigger flex gap-2 text-[#05050D] cursor-pointer transition">
            <FileSettingsIcon />
          </div>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="min-w-[120px] shadow transition bg-[#FFFFFF66] backdrop-blur rounded-lg w-max h-max p-0 flex flex-col"
        >
          <MoveFilesPopup
            moveFilesCallback={moveItem}
            item={item}
            triggerEl={<button className={'file-settings-button'}>Move</button>}
          />
          <RenameFilePopup
            renameFile={renameItem}
            item={item}
            triggerEl={<button className={'file-settings-button'}>Rename</button>}
          />
          <button
            className={'file-settings-button red'}
            onClick={() => {
              deleteItem();
              setOpen(false);
            }}
          >
            Delete
          </button>
        </PopoverContent>
      </Popover>
    </div>
  );
};
