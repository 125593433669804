import React from 'react';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { useParams } from 'react-router-dom';
import { useGetAllProjectsQuery, useGetProjectByIdQuery } from 'src/services';
import { ChangeKnowledgeBaseModal } from './ChangeKnowledgeBaseModal';

export const ChangeKnowledgeBase = () => {
  const { id } = useParams();
  const { data: currentProject, isFetching: isProjectFetching } = useGetProjectByIdQuery({
    id: id,
  });

  const { data: projects } = useGetAllProjectsQuery();

  const aiModelProject = projects?.find(
    (project) => project.id === currentProject?.cocbi_project_id,
  );

  return (
    <div className="flex flex-col gap-1.5 w-full">
      <div className="title">
        <p className="text-grey_text font-medium text-xs">Knowledge base</p>
      </div>
      <div
        className={
          'input-container full-width border rounded-[7px] h-full px-4 py-1 flex justify-between items-center gap-2'
        }
      >
        <LoaderWrapper
          loader={
            <div className="flex justify-between w-full items-end">
              <Skeleton className="h-4 w-full" />
            </div>
          }
          isLoading={isProjectFetching}
        >
          <p
            title={aiModelProject?.name}
            className="md:text-base text-sm font-medium text-ebony truncate opacity-40"
          >
            Default
          </p>
        </LoaderWrapper>
        <ChangeKnowledgeBaseModal currentProject={currentProject} disabled />
      </div>
    </div>
  );
};
