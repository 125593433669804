export const ROLES = {
  COSSUPORT_MANAGER: 'cosupport_manager',
  ADMIN: 'admin',
  MANAGER: 'manager',
  SUPPORT_TEAM_LEAD: 'support_team_lead',
  SUPPORT: 'support',
};
export const ROLES_PRETTY = {
  cosupport_manager: 'CoSupport',
  admin: 'Admin',
  manager: 'Manager',
  support_team_lead: 'Team Lead',
  support: 'User',
};

export const SUBSCRIPTION_ACCESS_ROLES = [ROLES.COSSUPORT_MANAGER, ROLES.ADMIN];
export const OVERVIEW_ACCESS_ROLES = [
  ROLES.COSSUPORT_MANAGER,
  ROLES.ADMIN,
  ROLES.MANAGER,
  ROLES.SUPPORT_TEAM_LEAD,
  ROLES.SUPPORT,
];
export const KNOWLEDGE_BASE_ACCESS_ROLES = [
  ROLES.COSSUPORT_MANAGER,
  ROLES.ADMIN,
  ROLES.MANAGER,
  ROLES.SUPPORT_TEAM_LEAD,
  ROLES.SUPPORT,
];
export const INTEGRATION_ACCESS_ROLES = [
  ROLES.COSSUPORT_MANAGER,
  ROLES.ADMIN,
  ROLES.MANAGER,
  ROLES.SUPPORT_TEAM_LEAD,
  ROLES.SUPPORT,
];
export const ANALYTICS_ACCESS_ROLES = [
  ROLES.COSSUPORT_MANAGER,
  // ROLES.ADMIN,
  // ROLES.MANAGER,
  // ROLES.SUPPORT_TEAM_LEAD,
];
