import { ProductIcon } from 'src/assets/img/product-icon';

export const ProductsOrder = {
  agent: 1,
  customer: 2,
  bi: 3,
};

export const ProductsType = {
  agent: 'agent',
  bi: 'bi',
  customer: 'customer',
};

export const ProductsTypeBack = {
  agent: 'coagent',
  bi: 'cobi',
  customer: 'cocustomer',
};

export const ProductsTypeBackPretty = {
  agent: 'CoAgent',
  bi: 'CoBI',
  customer: 'CoCustomer',
};

export const ProductsTypeColor = {
  agent: 'blue',
  bi: 'yellow',
  customer: 'purple',
};

export const ProductItems = [
  {
    label: 'CoSupport Agent',
    icon: <ProductIcon />,
    disabled: false,
    target_service: 'coagent',
  },
  {
    label: 'CoSupport Customer',
    icon: <ProductIcon variant="customer" />,
    disabled: true,
    target_service: 'cocustomer',
  },
  {
    label: 'CoSupport BI',
    icon: <ProductIcon variant="bi" />,
    disabled: true,
    target_service: 'cobi',
  },
];

export const ProductItemsIcons = {
  agent: <ProductIcon />,
  bi: <ProductIcon variant="bi" />,
  customer: <ProductIcon variant="customer" />,
};

export const ProductItemsLabels = {
  agent: 'CoSupport Agent',
  bi: 'CoSupport BI',
  customer: 'CoSupport Customer',
};
