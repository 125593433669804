import React, { useState } from 'react';
import Button from 'src/components/Button/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { ProjectActivateContent } from 'src/components/ProjectSettings/Popups/ProjectActivateContent';
import { useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from 'src/services';

export const ActivateProjectModal = ({ disabled = false }) => {
  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const { data: currentProject } = useGetProjectByIdQuery({ id: id });

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <Button
          className={clsx(
            disabled && 'pointer-events-none opacity-50',
            'cursor-pointer transparent max-md:mt-0 flex items-center gap-2',
          )}
        >
          <p className="max-md:text-xs max-md:font-medium">
            {currentProject?.is_active ? (
              <span>
                Deactivate <span className="max-md:hidden">project</span>
              </span>
            ) : (
              <span>
                Activate <span className="max-md:hidden">project</span>
              </span>
            )}
          </p>
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 0C4.4122 0 3.34884 0.322569 2.44437 0.926917C1.5399 1.53126 0.834947 2.39025 0.418665 3.39524C0.00238306 4.40023 -0.106535 5.5061 0.105683 6.57299C0.317902 7.63989 0.841726 8.6199 1.61091 9.38908C2.3801 10.1583 3.36011 10.6821 4.427 10.8943C5.4939 11.1065 6.59977 10.9976 7.60476 10.5813C8.60975 10.1651 9.46873 9.4601 10.0731 8.55563C10.6774 7.65116 11 6.5878 11 5.5C11 4.77773 10.8577 4.06253 10.5813 3.39524C10.3049 2.72795 9.89981 2.12163 9.38909 1.61091C8.87836 1.10019 8.27205 0.695063 7.60476 0.418662C6.93747 0.142262 6.22227 0 5.5 0ZM4.4 7.975V3.025L7.7 5.5L4.4 7.975Z"
              fill="#05050D"
            />
          </svg>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {currentProject?.is_active ? 'Deactivate project' : 'Activate project'}
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <ProjectActivateContent close={closePopup} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
