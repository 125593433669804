export const PasswordIcon = ({ height = '13', width = '17', fill = '#05050D', className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17V5.66667H2.4375V4.04762C2.4375 2.92778 2.83373 1.97335 3.62619 1.18433C4.41865 0.395318 5.37658 0.000540235 6.5 5.52576e-07C7.62342 -0.00053913 8.58162 0.394239 9.37463 1.18433C10.1676 1.97443 10.5636 2.92886 10.5625 4.04762V5.66667H13V17H0ZM6.5 12.9524C6.94688 12.9524 7.32956 12.794 7.64806 12.4772C7.96656 12.1604 8.12554 11.7791 8.125 11.3333C8.12446 10.8876 7.96548 10.5065 7.64806 10.1903C7.33065 9.87403 6.94796 9.71537 6.5 9.71429C6.05204 9.71321 5.66963 9.87187 5.35275 10.1903C5.03588 10.5087 4.87663 10.8897 4.875 11.3333C4.87337 11.777 5.03263 12.1582 5.35275 12.4772C5.67288 12.7961 6.05529 12.9545 6.5 12.9524ZM4.0625 5.66667H8.9375V4.04762C8.9375 3.37302 8.70052 2.7996 8.22656 2.32738C7.7526 1.85516 7.17708 1.61905 6.5 1.61905C5.82292 1.61905 5.2474 1.85516 4.77344 2.32738C4.29948 2.7996 4.0625 3.37302 4.0625 4.04762V5.66667Z"
        fill={fill}
      />
    </svg>
  );
};
