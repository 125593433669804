import { useState } from 'react';

const INITIAL_PAGINATION_DATA = {
  page: 1,
  rowsPerPage: 10,
};

export const usePagination = (initialData) => {
  const [pagination, setPagination] = useState(initialData || INITIAL_PAGINATION_DATA);

  const setPaginationHandle = (key, value) => {
    setPagination((prevState) => ({ ...prevState, [key]: value }));
  };

  const resetPagination = () => {
    setPagination(INITIAL_PAGINATION_DATA);
  };

  return {
    onSearch: (value) => {
      if (typeof value === 'string') {
        setPaginationHandle('search', value);
        setPaginationHandle('page', 1);
      } else if (value?.target) {
        setPaginationHandle('search', value?.target?.value);
        setPaginationHandle('page', 1);
      }
    },
    onChangePage: (page) => setPaginationHandle('page', page),
    onChangeRowsPerPage: (value) => {
      setPaginationHandle('rowsPerPage', parseInt(value, 10));
      setPaginationHandle('page', 1);
    },
    pagination,
    resetPagination,
  };
};
