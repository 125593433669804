import './UserSettingsPopover.css';
import React from 'react';
import { clsx } from 'clsx';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { FileSettingsIcon } from 'src/assets/icons/FileIcons';
import { EditUserPopup } from 'src/components/EditUserPopup/EditUserPopup';
import { DeleteUserPopup } from 'src/components/DeleteUserPopup/DeleteUserPopup';

export const UserSettingsPopover = ({ user }) => {
  const [open, setOpen] = React.useState(false);

  const onEditPopupOpenChange = (editPopupOpenState) => {
    if (!editPopupOpenState) {
      setOpen(false);
    }
  };

  return (
    <div className={clsx('flex items-center relative z-50')}>
      <Popover modal={true} open={open} onOpenChange={(openState) => setOpen(openState)}>
        <PopoverTrigger asChild>
          <div className="dropdown-trigger flex gap-2 text-[#05050D] cursor-pointer transition">
            <FileSettingsIcon />
          </div>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="min-w-[120px] shadow transition bg-[#FFFFFF66] backdrop-blur rounded-lg w-max h-max p-0 flex flex-col"
        >
          <EditUserPopup user={user} onOpenChange={onEditPopupOpenChange} />
          <DeleteUserPopup user={user} />
        </PopoverContent>
      </Popover>
    </div>
  );
};
