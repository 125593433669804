import { environment, getServerEnvironment } from '../env/env';
import { LOGIN_URL, LOGOUT_URL, REGISTRATION_URL, LOGIN_BY_GOOGLE_URL } from '../shared/constants';
import { fetchRequest, fetchRequestWithAccessToken } from './FetchRequest';

const fetch = () => {};

const auth = {
  signin: async (data) => {
    const url = `${environment.BASE_URL}/${LOGIN_URL}`;

    return fetchRequest('POST', url, data);
  },
  signout: async () => {
    const url = `${environment.BASE_URL}/${LOGOUT_URL}`;
    let tokens = localStorage.getItem('user');

    tokens = tokens && tokens !== 'undefined' && JSON.parse(tokens);

    return fetchRequestWithAccessToken('POST', url, {
      refresh: tokens.refresh,
    });
  },
  signup: async (data) => {
    const url = `${environment.BASE_URL}/${REGISTRATION_URL}`;

    return fetchRequest('POST', url, data);
  },
  signInWithGoogle: async (code) => {
    const serverEnv = await getServerEnvironment();
    const getTokenURL = `https://oauth2.googleapis.com/token`;
    const data = {
      code: decodeURIComponent(code),
      client_id: serverEnv.GOOGLE_OAUTH_CLIENT_ID,
      client_secret: serverEnv.GOOGLE_OAUTH_CLIENT_SECRET,
      redirect_uri: decodeURIComponent(environment.REDIRECT_URI),
      access_type: 'offline',
      grant_type: 'authorization_code',
    };

    try {
      let tokens = await fetchRequest('POST', getTokenURL, data);
      localStorage.setItem('tokens', JSON.stringify(tokens));
    } catch (error) {
      return new Error(error);
    }

    try {
      let tokens = localStorage.getItem('tokens');
      tokens = tokens && JSON.parse(tokens);

      if (tokens && tokens.access_token) {
        const loginResult = await fetchRequest(
          'POST',
          `${environment.BASE_URL}/${LOGIN_BY_GOOGLE_URL}`,
          {
            token: tokens.access_token,
          },
        );

        return loginResult;
      }
    } catch (error) {
      if (error.code === 403 && error.text === 'User already authenticated!') {
        return { success: true };
      } else {
        localStorage.removeItem('tokens');
        return new Error(JSON.stringify(error.text));
      }
    }
  },
};

export { auth };
