import './SortFilesDropdown.css';
import { Button } from 'src/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import React, { useState } from 'react';
import { SORT_MAP } from 'src/utils/fileUtils';
import { getKnowledgeBaseConfig, setKnowledgeBaseConfig } from 'src/utils/pageStorageConfig';
import { ExpandIcon } from 'src/assets/icons/expandIcon';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { SortFilesPopup } from './../SortFilesPopup/SortFilesPopup';

export const SortFilesDropdown = ({ onSortFilesChange }) => {
  const { sortBy } = getKnowledgeBaseConfig();
  const [value, setValue] = useState(sortBy || SORT_MAP.type);
  const [selected, setSelected] = useState(sortBy ? SORT_MAP[sortBy] : SORT_MAP.type);
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const handleSortFilesChange = (val) => {
    setKnowledgeBaseConfig({
      sortBy: val,
    });
    setValue(val);
    setSelected(SORT_MAP[val]);
    onSortFilesChange(val);
  };

  return isLgDown ? (
    <SortFilesPopup handleSortFilesChange={handleSortFilesChange} />
  ) : (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          className="opacity-60 hover:opacity-100 transition gap-[30px] sort-files-button outline-0 h-[36px]"
        >
          <span className={'min-w-[60px]'}>{selected}</span>
          <ExpandIcon width={10} className={'rotate-180'} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-20">
        <DropdownMenuRadioGroup value={value} onValueChange={handleSortFilesChange}>
          <DropdownMenuRadioItem value={'name'}>{SORT_MAP.name}</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={'date'}>{SORT_MAP.date}</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={'type'}>{SORT_MAP.type}</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
