import { baseApi } from './base.api';
import { PATH_PROJECTS_API, PATH_USERS_API } from '../constants/api-routes';

export const projectsService = baseApi.injectEndpoints({
  endpoints: (build) => {
    return {
      getAllProjects: build.query({
        query: () => ({
          url: `${PATH_PROJECTS_API.ALL}`,
        }),
        providesTags: ['Projects'],
      }),
      getProjectById: build.query({
        query: ({ id }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${id}`,
        }),
        providesTags: ['ProjectById'],
      }),
      getProjectTeamMembers: build.query({
        query: ({ id }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${id}/${PATH_PROJECTS_API.TEAM_MEMBERS}`,
        }),
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          try {
            const { data: teamMembers } = await queryFulfilled;

            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id }, (draft) => {
                draft.team_members = teamMembers;
              }),
            );
          } catch (err) {
            console.error('Error get project team members:', err);
          }
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectTeamMembers', id }],
        providesTags: ['ProjectTeamMembers'],
      }),
      addProjectTeamMembers: build.mutation({
        query: ({ id, user, type }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${id}/${PATH_PROJECTS_API.TEAM_MEMBERS}`,
          params: {
            ...(type !== '' && { project_type: type }),
          },
          method: 'POST',
          body: {
            user: user,
          },
        }),
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          try {
            const { data: addedUser } = await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id }, (draft) => {
                draft.team_members.push(addedUser);
              }),
            );
          } catch (err) {
            console.error('Error updating project team members:', err);
          }
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectTeamMembers', id }],
      }),
      deleteProjectTeamMembers: build.mutation({
        query: ({ id, teamMemberId, type }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${id}/${PATH_PROJECTS_API.TEAM_MEMBERS}/${teamMemberId}`,
          params: {
            ...(type !== '' && { project_type: type }),
          },
          method: 'DELETE',
        }),
        async onQueryStarted({ id, teamMemberId }, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id }, (draft) => {
                draft.team_members = draft.team_members.filter(
                  (member) => Number(member.id) !== Number(teamMemberId),
                );
              }),
            );
          } catch (err) {
            console.error('Error updating project team members:', err);
          }
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectTeamMembers', id }],
      }),
      newLimitProjectTeamMembers: build.mutation({
        query: ({ id, teamMemberId, limit }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${id}/${PATH_PROJECTS_API.TEAM_MEMBERS}/${teamMemberId}/${PATH_PROJECTS_API.NEW_LIMIT}`,
          method: 'PUT',
          body: {
            limit,
          },
        }),
        async onQueryStarted({ id, teamMemberId }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedTeamMember } = await queryFulfilled;
            await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id }, (draft) => {
                const currentMember = draft.team_members.find(
                  (member) => member.id === updatedTeamMember.id,
                );

                if (currentMember) {
                  currentMember.requests_left = updatedTeamMember.requests_left;
                }
              }),
            );
          } catch (err) {
            console.error('Error updating project team members:', err);
          }
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectTeamMembers', id }],
      }),
      updateProject: build.mutation({
        query: ({
          projectId,
          name,
          ai_reply_generation,
          ai_text_lense,
          cocbi_project_id = 0,
          use_cocbi_model = false,
        }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}`,
          method: 'PUT',
          body: {
            name,
            cocbi_project_id,
            use_cocbi_model,
            ai_reply_generation,
            ai_text_lense,
          },
        }),
        async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedProject } = await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getAllProjects', undefined, (draft) => {
                const currentProject = draft.find((project) => project.id === updatedProject.id);
                Object.assign(currentProject, updatedProject);
              }),
            );
            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id: projectId }, (draft) => {
                Object.assign(draft, updatedProject);
              }),
            );
          } catch {}
        },
      }),
      deleteProject: build.mutation({
        query: ({ projectId, ...rest }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}`,
          method: 'DELETE',
          body: rest,
        }),
        async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getAllProjects', undefined, (draft) => {
                return draft?.filter((project) => +project.id !== +projectId);
              }),
            );
          } catch {}
        },
      }),
      createProject: build.mutation({
        query: (body) => ({
          url: `${PATH_PROJECTS_API.ROOT}/`,
          method: 'POST',
          body,
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data: createdProject } = await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getAllProjects', undefined, (draft) => {
                draft?.push(createdProject);
              }),
            );
          } catch {}
        },
      }),
      activateProject: build.mutation({
        query: ({ projectId }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/activate`,
          method: 'PUT',
        }),
        async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedProject } = await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getAllProjects', undefined, (draft) => {
                const currentProject = draft.find((project) => project.id === updatedProject.id);
                currentProject.is_active = updatedProject.is_active;
              }),
            );
          } catch {}
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectById', id }],
      }),
      deactivateProject: build.mutation({
        query: ({ projectId }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/deactivate`,
          method: 'PUT',
        }),
        async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedProject } = await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getAllProjects', undefined, (draft) => {
                const currentProject = draft.find((project) => project.id === updatedProject.id);
                currentProject.is_active = updatedProject.is_active;
              }),
            );
          } catch {}
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectById', id }],
      }),
      addIntegration: build.mutation({
        query: ({ id, body }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${id}/${PATH_PROJECTS_API.INTEGRATIONS}`,
          method: 'POST',
          body: body,
        }),
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          try {
            const { data: integration } = await queryFulfilled;

            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id }, (draft) => {
                draft.integrations = [...draft.integrations, integration];
              }),
            );
          } catch (err) {
            console.error('Error add project integration:', err);
          }
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectById', id }],
      }),
      projectDemoMode: build.mutation({
        query: ({ id, project_type, enable }) => ({
          url: `${PATH_PROJECTS_API.DEMO_MODE}/${id}`,
          method: 'POST',
          params: {
            project_id: id,
            project_type,
            enable,
          },
        }),
        async onQueryStarted({ id, enable }, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;

            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id }, (draft) => {
                draft.is_demo_mode = enable;
              }),
            );
          } catch (err) {
            console.error('Error add project integration:', err);
          }
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectById', id }],
      }),
      updateIntegration: build.mutation({
        query: ({ projectId, integrationId, body }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/${PATH_PROJECTS_API.INTEGRATIONS}/${integrationId}`,
          method: 'PUT',
          body: body,
        }),
        async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedIntegration } = await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id: projectId }, (draft) => {
                const index = draft.integrations.findIndex(
                  (integration) => integration.integration_id === updatedIntegration.integration_id,
                );

                if (index !== -1) {
                  draft.integrations[index] = updatedIntegration;
                }
              }),
            );
          } catch (err) {
            console.error('Error updating project integration:', err);
          }
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectById', id }],
      }),
      deleteIntegration: build.mutation({
        query: ({ projectId, integrationId }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/${PATH_PROJECTS_API.INTEGRATIONS}/${integrationId}`,
          method: 'DELETE',
        }),
        async onQueryStarted({ projectId, integrationId }, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id: projectId }, (draft) => {
                const index = draft.integrations.findIndex(
                  (integration) => integration.integration_id === integrationId,
                );

                draft.integrations.splice(index, 1);
              }),
            );
          } catch (err) {
            console.error('Error deleting project integration:', err);
          }
        },
        invalidatesTags: (result, error, { id }) => [{ type: 'ProjectById', id }],
      }),
      getProjectFiles: build.query({
        query: ({ projectId, sync }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/${PATH_PROJECTS_API.FILES}`,
          params: { sync: sync },
        }),
        async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
          try {
            const { data: files } = await queryFulfilled;

            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id: projectId }, (draft) => {
                draft.files = files;
              }),
            );
          } catch (err) {
            console.error('Error get project files:', err);
          }
        },
        providesTags: ['ProjectFiles'],
      }),
      addProjectFile: build.mutation({
        query: ({ projectId, body }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/${PATH_PROJECTS_API.FILES}`,
          method: 'POST',
          body: body,
          formData: true,
        }),
        async onQueryStarted({ projectId, body }, { dispatch, queryFulfilled }) {
          try {
            const { data: file } = await queryFulfilled;

            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id: projectId }, (draft) => {
                draft.files = draft.files || [];
                draft.files.push(file);
              }),
            );
          } catch (err) {
            console.error('Error add project files:', err);
          }
        },
      }),
      addProjectDocument: build.mutation({
        query: ({ projectId, body }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/${PATH_PROJECTS_API.ADD_DOCUMENT}`,
          method: 'POST',
          body: body,
        }),
        async onQueryStarted({ projectId, body }, { dispatch, queryFulfilled }) {
          try {
            const { data: file } = await queryFulfilled;

            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id: projectId }, (draft) => {
                draft.files = draft.files || [];
                draft.files.push(file);
              }),
            );
          } catch (err) {
            console.error('Error add project document:', err);
          }
        },
      }),
      updateProjectFiles: build.mutation({
        query: ({ projectId, body }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/${PATH_PROJECTS_API.FILES}`,
          method: 'PATCH',
          body: body,
        }),
        invalidatesTags: ['ProjectFiles'],
      }),
      deleteProjectFile: build.mutation({
        query: ({ projectId, fileId }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/${PATH_PROJECTS_API.FILES}/${fileId}`,
          method: 'DELETE',
        }),
        async onQueryStarted({ projectId, fileId }, { dispatch, queryFulfilled }) {
          try {
            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id: projectId }, (draft) => {
                draft.files = draft.files.filter((file) => file.id !== fileId);
              }),
            );
          } catch (err) {
            console.error('Error deleting project file:', err);
          }
        },
        invalidatesTags: ['ProjectFiles'],
      }),
      deleteProjectFiles: build.mutation({
        query: ({ projectId, body }) => ({
          url: `${PATH_PROJECTS_API.ROOT}/${projectId}/${PATH_PROJECTS_API.FILES}`,
          method: 'DELETE',
          body: body,
        }),
        async onQueryStarted({ projectId, body }, { dispatch, queryFulfilled }) {
          try {
            dispatch(
              projectsService.util.updateQueryData('getProjectById', { id: projectId }, (draft) => {
                draft.files = draft.files.filter((file) => !body.includes(file.id));
              }),
            );
          } catch (err) {
            console.error('Error deleting project file:', err);
          }
        },
      }),
    };
  },
});

export const {
  useUpdateProjectMutation,
  useGetProjectByIdQuery,
  useGetAllProjectsQuery,
  useDeleteProjectMutation,
  useCreateProjectMutation,
  useGetProjectTeamMembersQuery,
  useAddProjectTeamMembersMutation,
  useDeleteProjectTeamMembersMutation,
  useNewLimitProjectTeamMembersMutation,
  useActivateProjectMutation,
  useDeactivateProjectMutation,
  useAddIntegrationMutation,
  useUpdateIntegrationMutation,
  useDeleteIntegrationMutation,
  useGetProjectFilesQuery,
  useAddProjectFileMutation,
  useAddProjectDocumentMutation,
  useUpdateProjectFilesMutation,
  useDeleteProjectFilesMutation,
  useDeleteProjectFileMutation,
  useProjectDemoModeMutation,
} = projectsService;
