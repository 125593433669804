import React from 'react';

export const UploadFileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
    <path
      d="M1.28827 13.2169C2.91614 14.9245 5.63921 14.9245 7.35393 13.2169L12.1097 8.48928C12.1407 8.45846 12.1578 8.41677 12.1589 8.37326C12.16 8.32976 12.1451 8.28807 12.1158 8.25725L11.4781 7.58835C11.4489 7.5579 11.4089 7.54081 11.3666 7.54081C11.3243 7.54081 11.2834 7.5579 11.2526 7.58835L6.49684 12.316C5.90645 12.9033 5.13299 13.226 4.31998 13.226C3.50696 13.226 2.75025 12.9033 2.19211 12.316C1.63221 11.7287 1.33651 10.9474 1.358 10.1189C1.37955 9.2887 1.71575 8.50922 2.30614 7.92189L7.1531 3.1018L7.93846 2.32051C8.6728 1.58998 9.83552 1.58998 10.5319 2.32051C10.8689 2.674 11.0467 3.1435 11.0337 3.64382C11.0207 4.14413 10.8186 4.61363 10.4633 4.96712L5.65457 9.74914C5.53253 9.86878 5.37458 9.93585 5.20772 9.93585L5.20594 9.93585C5.03908 9.93585 4.88638 9.86878 4.77233 9.74914C4.65654 9.62768 4.59678 9.46816 4.6012 9.29776C4.60557 9.12918 4.67539 8.96966 4.79566 8.85002L8.72601 4.94355C8.75699 4.91274 8.77405 4.87104 8.77518 4.82754C8.77631 4.78403 8.76141 4.74234 8.73203 4.71152L8.09436 4.04262C8.06521 4.01218 8.02512 3.99509 7.98287 3.99509C7.94062 3.99509 7.89965 4.01218 7.86892 4.04262L3.94029 7.95089C3.57768 8.31163 3.37177 8.79019 3.35855 9.29958C3.34533 9.80896 3.52813 10.2893 3.8703 10.6483C4.58055 11.3933 5.76639 11.3915 6.51526 10.6483L6.98179 10.1824L11.3222 5.86624C11.6167 5.57489 11.8532 5.22826 12.0179 4.84645C12.1826 4.46464 12.2723 4.05524 12.2817 3.642C12.3035 2.8027 12.0026 2.01416 11.4375 1.42139C10.8516 0.808684 10.0714 0.50233 9.28327 0.50233C8.4951 0.50233 7.69899 0.808684 7.08309 1.42139L1.449 7.02277C0.619855 7.84938 0.143966 8.94972 0.113626 10.1189C0.0814643 11.29 0.500248 12.3903 1.28827 13.2169Z"
      fill="#05050D"
      stroke="black"
      strokeWidth="0.2"
    />
  </svg>
);
