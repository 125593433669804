import React, { useState } from 'react';
import Button from 'src/components/Button/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import DollarIcon from 'src/assets/img/dollar.svg';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';

export const PaymentDetailsPopup = ({ disabled = false, subscriptionsData, accounts }) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <Button className="transparent">
          <span>Payment details</span>
          <img src={DollarIcon} />
        </Button>
      </DialogTrigger>
      <DialogContent className="laptop:max-w-[444px]">
        <DialogHeader>
          <DialogTitle className="text-[#B8C1C6] font-medium text-base">
            Next payment details
          </DialogTitle>
        </DialogHeader>
        <div className="lg:py-3.5 py-2 flex flex-col w-full text-left">
          <span className="text-xl font-bold text-ebony">
            {subscriptionsData?.generalPlan?.plan?.name}
          </span>
          <span className="text-sm font-medium text-ebony opacity-70">
            Date —{' '}
            {transformDateHelper(
              accounts?.stripe_subscription_end,
              DateFormat.MONTH_DAY_YEAR_WITHOUT_COMA,
            )}
            .
          </span>
        </div>
        <div className="flex flex-col gap-5 pb-7.5">
          <ul className="list-disc pl-4 space-y-2">
            <li>
              <div className="flex flex-col gap-1">
                <p className="font-medium text-sm text-ebony opacity-70">
                  Monthly fee for the next billing cycle:
                </p>
                <p className="font-bold text-base text-ebony">${subscriptionsData.cost}</p>
              </div>
            </li>
            <li>
              <div className="flex flex-col gap-1">
                <p className="font-medium text-sm text-ebony opacity-70">
                  Fee for extra replies in the current billing cycle:
                </p>
                <p className="font-bold text-base text-ebony">${subscriptionsData.extra}</p>
              </div>
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};
