import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { FolderIcon } from 'src/assets/icons/FolderIcon';
import { Checkbox } from 'src/components/Checkbox/Checkbox';
import { FileSettingsPopover } from 'src/components/FileManager/FileSettingsPopover/FileSettingsPopover';
import { useSingleAndDoubleClick } from 'src/hooks/useSingleAndDoubleClick';

export const ListFolderView = ({
  item,
  onClick,
  onDoubleClick,
  onCheckedChange,
  className,
  checkboxVisible,
  handleSingleItemMove,
  handleSingleItemRename,
  handleSingleItemDelete,
  ...props
}) => {
  const [checked, setChecked] = useState(Boolean(item.checked));
  const [isSettingsVisible, setIsSettingsVisible] = useState(
    item.name !== '..' && Boolean(item.children.length),
  );
  useEffect(() => {
    if (!checkboxVisible) {
      setChecked(false);
      item.checked = false;
    }
  }, [checkboxVisible]);

  useEffect(() => {
    setChecked(item.checked);
  }, [item, item.checked]);

  const handleClick = useSingleAndDoubleClick(onClick, onDoubleClick);

  const handleChange = (event, child) => {
    const file = child || item;

    file.checked = event.target.checked;

    if (file.children) {
      for (let i = 0; i < file.children.length; i++) {
        const child = file.children[i];

        child.checked = event.target.checked;

        if (child.children && child.children.length) {
          handleChange(event, child);
        }
      }
    }

    onCheckedChange && onCheckedChange(file);
  };

  return (
    <>
      <div
        className={clsx(className, 'file-list-item folder', item.checked && 'checked')}
        onClick={handleClick}
        {...props}
      >
        <div className={'file-list-name'}>
          <div
            className={clsx(
              item.name === '..'
                ? 'invisible pointer-events-none'
                : checkboxVisible
                  ? 'pointer-events-auto'
                  : 'invisible pointer-events-none',
              'file-list-checkbox',
            )}
            onDoubleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Checkbox
              label={''}
              checked={checked}
              onChange={(event) => {
                setChecked(event.target.checked);
                handleChange(event, item);
              }}
            />
          </div>
          <div className={'file-list-icon'}>
            <FolderIcon />
          </div>
          <div className={'file-list-text md:pl-2.5 text-wrap'}>
            <p
              title={item.name}
              className="w-[inherit] line-clamp-3 hyphens-auto break-words select-none"
            >
              {item.name === '..' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                  className="my-1"
                >
                  <path
                    opacity="0.5"
                    d="M4.21576 0.674395C4.26935 0.729669 4.31186 0.795294 4.34086 0.867521C4.36986 0.939748 4.38479 1.01716 4.38479 1.09534C4.38479 1.17352 4.36986 1.25094 4.34086 1.32316C4.31186 1.39539 4.26935 1.46102 4.21576 1.51629L1.96994 3.83341H6.3462C9.46848 3.83341 12 6.44528 12 9.66668V9.90477C12 10.0626 11.9392 10.214 11.831 10.3257C11.7228 10.4373 11.5761 10.5 11.4231 10.5C11.2701 10.5 11.1233 10.4373 11.0151 10.3257C10.9069 10.214 10.8462 10.0626 10.8462 9.90477V9.66668C10.8462 9.05697 10.7298 8.45324 10.5036 7.88995C10.2775 7.32666 9.94602 6.81484 9.52816 6.38372C9.1103 5.95259 8.61422 5.61061 8.06826 5.37728C7.5223 5.14396 6.93715 5.02387 6.3462 5.02387H1.96994L4.21576 7.34099C4.32397 7.45263 4.38476 7.60405 4.38476 7.76194C4.38476 7.91982 4.32397 8.07124 4.21576 8.18288C4.10755 8.29453 3.96079 8.35724 3.80777 8.35724C3.65474 8.35724 3.50798 8.29453 3.39977 8.18288L0.169029 4.84959C0.115441 4.79431 0.0729322 4.72869 0.0439298 4.65646C0.0149275 4.58423 0 4.50682 0 4.42864C0 4.35046 0.0149275 4.27304 0.0439298 4.20082C0.0729322 4.12859 0.115441 4.06297 0.169029 4.00769L3.39977 0.674395C3.45334 0.619106 3.51695 0.575247 3.58695 0.545324C3.65696 0.515401 3.73199 0.5 3.80777 0.5C3.88354 0.5 3.95857 0.515401 4.02858 0.545324C4.09858 0.575247 4.16219 0.619106 4.21576 0.674395Z"
                    fill="#05050D"
                  />
                </svg>
              ) : (
                item.name
              )}
            </p>
          </div>
          <div className={'file-list-settings folder'}>
            {isSettingsVisible && (
              <div className={'file-list-settings-icon pointer-events-auto'}>
                {!checkboxVisible && !item.disabled && (
                  <FileSettingsPopover
                    item={item}
                    moveItem={(moveToFolder) => handleSingleItemMove(moveToFolder, item)}
                    renameItem={(name) => handleSingleItemRename(name, item)}
                    deleteItem={() => handleSingleItemDelete(item)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
