import './LoginPage.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../AuthPage/Header';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { useAuth } from '../../provider/AuthProvider';
import { useNotification } from '../../hooks/useNotification';
import { EMAIL_REGEX } from '../../shared/constants';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const navigate = useNavigate();
  const auth = useAuth();
  const notification = useNotification();
  const validateEmail = () => {
    const isValid = EMAIL_REGEX.test(email);

    setIsEmailValid(isValid);
  };
  const validatePassword = () => {
    setIsPasswordValid(password && password.length > 2);
  };

  const onPasswordChange = (event) => {
    setPassword(event.currentTarget.value);
  };

  const submit = async (event) => {
    event.preventDefault();

    const isFormInvalid = [isEmailValid, isPasswordValid].some((isValid) => !Boolean(isValid));

    if (isFormInvalid) {
      return;
    }

    const response = await auth.signin({ email, password });

    if (response instanceof Error) {
      return response.message && notification.error(response.message);
    }

    navigate(PATH_DASHBOARD.ACCOUNT);
  };

  return (
    <React.Fragment>
      <div className={'login-page-container'}>
        <div className={'login-block'}>
          <Header></Header>
          <p>Please enter your login credentials:</p>
          <form onSubmit={submit}>
            <div className={'input-container'}>
              <Input
                type={'email'}
                label={'Email'}
                placeholder={'email@email.com'}
                invalidMessage={email ? 'Invalid Format' : 'Required'}
                value={email}
                isValid={isEmailValid}
                onChange={(event) => setEmail(event.currentTarget.value)}
                onBlur={() => validateEmail(false)}
                onFocus={() => setIsEmailValid(true)}
              />
            </div>
            <div className={'input-container'}>
              <Input
                type={'password'}
                label={'Password'}
                placeholder={'••••••••••••••••••••••••••••'}
                invalidMessage={'Invalid'}
                isValid={isPasswordValid}
                value={password}
                onChange={(event) => onPasswordChange(event, false)}
                onBlur={() => validatePassword(false)}
                onFocus={() => setIsPasswordValid(true)}
              />
            </div>
            <div className={'input-container buttons'}>
              <Button className="transparent" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button type="sumbit" className="green">
                Log In
              </Button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
