import React from 'react';
import Button from '../../../../components/Button/Button';
import { AnalyticsDateRangePopupForm } from './AnalyticsDateRangePopupForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';

export const AnalyticsDateRangePopup = ({
  open,
  setOpen,
  disabled = false,
  submitDateFilter = () => {},
  dateDefault,
}) => {
  const submit = (formData) => {
    submitDateFilter(formData);
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className={'text-ebony'}>Calendar</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <AnalyticsDateRangePopupForm onSubmit={submit} dateDefault={dateDefault} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button
            style={{ width: 'auto' }}
            type="submit"
            form="aply-date-filter-form"
            className="purple"
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
