import './SortFilesPopup.css';
import React, { useState } from 'react';
import { Button } from 'src/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { FilesSortIcon } from 'src/assets/icons/FileIcons';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { SORT_MAP } from 'src/utils/fileUtils';
import { getKnowledgeBaseConfig } from 'src/utils/pageStorageConfig';

export const SortFilesPopup = ({ handleSortFilesChange, disabled = false }) => {
  const { sortBy } = getKnowledgeBaseConfig();
  const [value, setValue] = useState(sortBy || SORT_MAP.type);
  const [open, setOpen] = useState(false);

  const onChange = (sortBy) => {
    setValue(sortBy);
    handleSortFilesChange(sortBy);
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <Button
          variant="secondary"
          className="opacity-60 hover:opacity-100 transition gap-[30px] sort-files-button outline-0 h-[36px]"
        >
          <FilesSortIcon />
        </Button>
      </DialogTrigger>
      <DialogContent className={'sort-files-popup'}>
        <DialogHeader>
          <DialogTitle>
            <div className={'flex flex-row items-center gap-[8px] text-[16px]'}>
              <span>
                <FilesSortIcon />
              </span>
              Filter
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="content">
          <RadioGroup
            onChange={onChange}
            onValueChange={onChange}
            defaultValue={value}
            className="flex flex-col space-y-1"
          >
            <div className={clsx('flex items-center space-x-5 space-y-0')}>
              <RadioGroupItem value={'name'} id="sort-by-name" />
              <label htmlFor="sort-by-name" className="cursor-pointer">
                <p className="font-medium text-base text-ebony opacity-70 hover:opacity-100 transition">
                  {SORT_MAP.name}
                </p>
              </label>
            </div>
            <div className={clsx('flex items-center space-x-5 space-y-0')}>
              <RadioGroupItem value={'date'} id="sort-by-date" />
              <label htmlFor="sort-by-date" className="cursor-pointer">
                <p className="font-medium text-base text-ebony opacity-70 hover:opacity-100 transition">
                  {SORT_MAP.date}
                </p>
              </label>
            </div>
            <div className={clsx('flex items-center space-x-5 space-y-0')}>
              <RadioGroupItem value={'type'} id="sort-by-type" />
              <label htmlFor="sort-by-type" className="cursor-pointer">
                <p className="font-medium text-base text-ebony opacity-70 hover:opacity-100 transition">
                  {SORT_MAP.type}
                </p>
              </label>
            </div>
          </RadioGroup>
        </div>
      </DialogContent>
    </Dialog>
  );
};
