import moment from 'moment/moment';

const iso = 'YYYY-MM-DDTHH:mm:ss';

export const DateItems = [
  {
    label: 'Today',
    disabled: false,
    groupBy: 'hour',
    dateRange: 'hours',
    timeAxis: 'minutes',
    showInProgressTickets: true,
    getStartTime: (format = iso) => moment().startOf('day').format(format),
    getEndTime: (format = iso) => moment().endOf('day').format(format),
    getStartAgentTime: (format = iso) => moment().startOf('day').format(format),
    getEndAgentTime: (format = iso) => moment().endOf('day').format(format),
  },
  {
    label: 'Yesterday',
    disabled: false,
    groupBy: 'hour',
    dateRange: 'hours',
    timeAxis: 'minutes',
    showInProgressTickets: false,
    getStartTime: (format = iso) => moment().subtract(1, 'days').startOf('day').format(format),
    getEndTime: (format = iso) => moment().subtract(1, 'days').endOf('day').format(format),
    getStartAgentTime: (format = iso) => moment().subtract(1, 'days').startOf('day').format(format),
    getEndAgentTime: (format = iso) => moment().subtract(1, 'days').endOf('day').format(format),
  },
  {
    label: 'This week',
    disabled: false,
    groupBy: 'day',
    dateRange: 'day',
    timeAxis: 'minutes',
    showInProgressTickets: true,
    getStartTime: (format = iso) => moment().weekday(1).startOf('day').format(format),
    getEndTime: (format = iso) => moment().endOf('isoWeek').format(format),
    getStartAgentTime: (format = iso) => moment().weekday(1).startOf('day').format(format),
    getEndAgentTime: (format = iso) => moment().format(format),
  },
  {
    label: 'Last week',
    disabled: false,
    groupBy: 'day',
    dateRange: 'day',
    timeAxis: 'minutes',
    showInProgressTickets: false,
    getStartTime: (format = iso) => moment().subtract(1, 'weeks').startOf('isoWeek').format(format),
    getEndTime: (format = iso) => moment().subtract(1, 'weeks').endOf('isoWeek').format(format),
    getStartAgentTime: (format = iso) =>
      moment().subtract(1, 'weeks').startOf('isoWeek').format(format),
    getEndAgentTime: (format = iso) =>
      moment().subtract(1, 'weeks').endOf('isoWeek').format(format),
  },
  {
    label: 'This month',
    disabled: false,
    groupBy: 'day',
    dateRange: 'day',
    timeAxis: 'minutes',
    showInProgressTickets: true,
    getStartTime: (format = iso) => moment().startOf('month').startOf('day').format(format),
    getEndTime: (format = iso) => moment().endOf('month').format(format),
    getStartAgentTime: (format = iso) => moment().startOf('month').startOf('day').format(format),
    getEndAgentTime: (format = iso) => moment().format(format),
  },
  {
    label: 'Last month',
    disabled: false,
    groupBy: 'day',
    dateRange: 'day',
    timeAxis: 'minutes',
    showInProgressTickets: false,
    getStartTime: (format = iso) => moment().subtract(1, 'months').startOf('month').format(format),
    getEndTime: (format = iso) => moment().subtract(1, 'months').endOf('month').format(format),
    getStartAgentTime: (format = iso) =>
      moment().subtract(1, 'months').startOf('month').format(format),
    getEndAgentTime: (format = iso) => moment().subtract(1, 'months').endOf('month').format(format),
  },
  {
    label: 'This year',
    disabled: false,
    groupBy: 'day',
    dateRange: 'week',
    timeAxis: 'minutes',
    showInProgressTickets: true,
    getStartTime: (format = iso) => moment().startOf('year').format(format),
    getEndTime: (format = iso) => moment().endOf('year').format(format),
    getStartAgentTime: (format = iso) => moment().startOf('year').format(format),
    getEndAgentTime: (format = iso) => moment().format(format),
  },
  {
    label: 'Last year',
    disabled: false,
    groupBy: 'day',
    dateRange: 'month',
    timeAxis: 'minutes',
    showInProgressTickets: false,
    getStartTime: (format = iso) => moment().subtract(1, 'year').startOf('year').format(format),
    getEndTime: (format = iso) => moment().subtract(1, 'year').endOf('year').format(format),
    getStartAgentTime: (format = iso) =>
      moment().subtract(1, 'year').startOf('year').format(format),
    getEndAgentTime: (format = iso) => moment().subtract(1, 'year').endOf('year').format(format),
  },
  {
    label: 'Custom',
    disabled: false,
    groupBy: 'day',
    dateRange: 'day',
    timeAxis: 'minutes',
    showInProgressTickets: false,
  },
];
