import React, { useEffect, useRef } from 'react';
import { clsx } from 'clsx';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';
import { Switch } from 'src/components/ui/switch';
import { useSubscriptionProgressBarGradient } from 'src/hooks/useSubscriptionProgressBar';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import { SubscriptionPieChart } from 'src/pages/SubscriptionPage/CurrentSubscriptionPlansSection/CurrentSubscriptionPlans/SubscriptionPieChart';

const agentColor = '#75F3FB';
const customerColor = '#A498FF';
const biColor = '#DFF800';

const percent1 = '20%';
const percent2 = '30%';

export const MobileTable = ({
  generalPlan,
  planRequestLimit,
  generalPlanRequestLimit,
  infoOnly,
}) => {
  const progressRef = useRef(null);
  const badgeRef = useRef(null);

  useSubscriptionProgressBarGradient({ progressRef, widthGap: '4px' });

  useEffect(() => {
    if (badgeRef.current) {
      badgeRef.current.style.background = `linear-gradient(
      90deg,
      ${customerColor} 0%,
      ${customerColor} calc(${percent1} - 7.5%),
      ${biColor} calc(${percent1} + 7.5%),
      ${biColor} calc(${percent1} + ${percent2} - 7.5%),
      ${agentColor} calc(${percent1} + ${percent2} + 7.5%),
      ${agentColor} 100%
    )`;
    }
  }, []);

  const usedRequests =
    Number(planRequestLimit) - Number(generalPlanRequestLimit) + Number(generalPlan?.credits_count);
  return (
    <div className="gap-[6px] flex flex-col mt-[10px]">
      <div className={clsx('flex flex-col border-[rgba(184,193,198,0.4)] rounded-[5px] border')}>
        <div className="item text-sm border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px]">
          <div className="col name md:text-base text-sm flex justify-between">
            {infoOnly && (
              <div className="item flex items-center text-xs w-full gap-2.5">
                <span className="w-3/12 text-[rgba(184,193,198,1)] font-medium	flex items-center gap-1.5">
                  Current plan:
                </span>
                <p
                  title={generalPlan?.plan?.name}
                  className="hyphens-auto whitespace-pre-wrap truncate text-nowrap text-base font-bold"
                >
                  {generalPlan?.plan?.name}
                </p>
              </div>
            )}
            {!infoOnly && (
              <div className="flex flex-col w-full items-start gap-[8px]">
                <div className="flex justify-between w-full items-center">
                  <p
                    title={generalPlan?.plan?.name}
                    className="hyphens-auto whitespace-pre-wrap truncate text-nowrap"
                  >
                    {generalPlan?.plan?.name}
                  </p>
                </div>
                <div className="h-4 w-full rounded-full bg-[#EAEEF2] p-[2px] relative">
                  <div
                    ref={progressRef}
                    className="w-[calc(100%-4px)] h-[calc(100%-4px)] absolute z-0 bg-blue-300 rounded-full"
                  />
                </div>
                <div className="flex items-center gap-2 z-50">
                  <div ref={badgeRef} className="h-2 w-2 rounded-full bg-light_badge" />
                  <p className="font-medium text-xs text-ebony flex">
                    Replies Used: <span className="font-bold">{usedRequests}</span>
                  </p>
                  {!!Object.keys(generalPlan?.meta_data?.usage_by_products || {})?.length && (
                    <WarningPopover
                      triggerIcon={
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="14" height="14" rx="7" fill="white" />
                          <path
                            d="M6.3 10.5H7.7V6.3H6.3V10.5ZM7 4.9C7.19833 4.9 7.3647 4.8328 7.4991 4.6984C7.6335 4.564 7.70047 4.39787 7.7 4.2C7.69953 4.00213 7.63233 3.836 7.4984 3.7016C7.36447 3.5672 7.19833 3.5 7 3.5C6.80167 3.5 6.63553 3.5672 6.5016 3.7016C6.36767 3.836 6.30047 4.00213 6.3 4.2C6.29953 4.39787 6.36673 4.56423 6.5016 4.6991C6.63647 4.83397 6.8026 4.90093 7 4.9ZM7 14C6.03167 14 5.12167 13.8161 4.27 13.4484C3.41833 13.0807 2.6775 12.582 2.0475 11.9525C1.4175 11.323 0.918867 10.5821 0.551601 9.73C0.184334 8.87786 0.000467553 7.96786 8.86075e-07 7C-0.00046578 6.03213 0.183401 5.12213 0.551601 4.27C0.919801 3.41787 1.41843 2.67703 2.0475 2.0475C2.67657 1.41797 3.4174 0.919333 4.27 0.5516C5.1226 0.183867 6.0326 0 7 0C7.9674 0 8.8774 0.183867 9.73 0.5516C10.5826 0.919333 11.3234 1.41797 11.9525 2.0475C12.5816 2.67703 13.0804 3.41787 13.4491 4.27C13.8178 5.12213 14.0014 6.03213 14 7C13.9986 7.96786 13.8147 8.87786 13.4484 9.73C13.0821 10.5821 12.5834 11.323 11.9525 11.9525C11.3216 12.582 10.5807 13.0809 9.73 13.4491C8.87926 13.8173 7.96926 14.0009 7 14Z"
                            fill="black"
                          />
                        </svg>
                      }
                      side="top"
                      align="start"
                      disableContentIcon
                      contentClassName="!bg-white max-w-96"
                      triggerClassName=""
                      fill="#000000"
                      fillInner="white"
                    >
                      <div className="w-full flex-col min-h-32 min-w-32 flex gap-5">
                        <SubscriptionPieChart
                          data={generalPlan?.meta_data?.usage_by_products || {}}
                        />
                      </div>
                    </WarningPopover>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-2 w-2 rounded-full bg-light_badge" />
                  <p className="font-medium text-xs text-ebony flex">
                    Replies Left: <span className="font-bold">{generalPlanRequestLimit}</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="item flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
          <span className="w-3/12 text-[rgba(184,193,198,1)] font-medium	flex items-center gap-1.5">
            AI replies balance:{' '}
            <WarningPopover
              side="top"
              disableContentIcon
              textColor="text-ebony"
              text="AI replies used / AI replies included in the plan"
              contentClassName="!bg-white"
              triggerClassName=""
              fill="#B8C1C6"
            />
          </span>
          <p className="truncate text-base font-bold flex items-center gap-2">
            <span className="truncate">
              {usedRequests} / {planRequestLimit}
            </span>
          </p>
        </div>
        <div className="item flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
          <span className="w-3/12 text-[rgba(184,193,198,1)] font-medium	">Price:</span>
          <div className="flex flex-col">
            <p className="truncate text-base font-bold">
              $ {generalPlan?.custom_plan_settings?.fixed_price || generalPlan?.plan?.price}
            </p>
            <p className="truncate text-[12px] font-medium opacity-50 text-ebony">
              extra reply ${' '}
              {generalPlan?.custom_plan_settings?.usage_price ||
                generalPlan?.plan?.extra_request_price}
            </p>
          </div>
        </div>
        <div className="item flex items-center text-xs border-[rgba(184,193,198,0.4)] p-[15px] gap-[10px]">
          <span className="w-3/12 text-[rgba(184,193,198,1)] font-medium	">Status:</span>
          <div className="flex flex-col">
            <Switch
              checked={generalPlan.status === 'active'}
              disabled
              aria-readonly
              id="active-plan"
              type="green"
            />
            <label className="cursor-pointer font-medium text-xs" htmlFor="active-plan">
              Active till{' '}
              {transformDateHelper(
                generalPlan.current_period_end,
                DateFormat.MONTH_DAY_YEAR_WITHOUT_COMA,
              )}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
