import React from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';
import { ActivateProjectModal } from './ActivateProjectModal';
import { DeleteProjectModal } from 'src/pages/ProjectPage/ProjectInfoSection/DeleteProjectModal';
import { DemoModeToggle } from 'src/pages/ProjectPage/ProjectInfoSection/DemoModeToggle';
import { ChangeName } from 'src/pages/ProjectPage/ProjectInfoSection/ChangeName/ChangeName';
import { ChangeAiModel } from 'src/pages/ProjectPage/ProjectInfoSection/ChangeAiModel/ChangeAiModel';
import { ChangeKnowledgeBase } from 'src/pages/ProjectPage/ProjectInfoSection/ChangeKnowledgeBase/ChangeKnowledgeBase';

export const ProjectInfoSection = () => {
  const { data: userInfo } = useGetUsersMeQuery();

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  return (
    <CardContainer className="integration">
      <div className="card-header z-50">
        <div className="title cursor-default">
          <span className="!no-underline max-md:hidden">Project info</span>
        </div>
        <div className="relative flex md:gap-2.5 gap-2 items-center">
          <div className="add-button-container max-md:hidden relative flex md:gap-2.5 gap-2">
            {isCosupportManager && <DemoModeToggle />}
          </div>
          <div className="add-button-container relative flex md:gap-2.5 gap-2">
            <ActivateProjectModal disabled={!isCosupportManager} />
            {!isCosupportManager && (
              <WarningPopover triggerClassName="absolute -right-2 -top-2" fill="black" />
            )}
          </div>
          <div className="add-button-container relative flex md:gap-2.5 gap-2">
            <DeleteProjectModal disabled={!isCosupportManager} />
            {!isCosupportManager && (
              <WarningPopover triggerClassName="absolute -right-2 -top-2" fill="black" />
            )}
          </div>
        </div>
      </div>
      <div className="card-body flex w-full gap-2.5 max-md:flex-col">
        <ChangeName />
        <ChangeAiModel />
        <ChangeKnowledgeBase />
        <div className="add-button-container md:hidden relative flex justify-end md:gap-2.5 gap-2">
          {isCosupportManager && <DemoModeToggle />}
        </div>
      </div>
    </CardContainer>
  );
};
