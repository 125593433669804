import './KnowledgeBasePage.css';
import React, { useEffect, useState, useRef } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import PageContentWrapper from 'src/components/PageContentWrapper/PageContentWrapper';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { clsx } from 'clsx';
import { useParams } from 'react-router-dom';
import {
  useGetProjectByIdQuery,
  useGetProjectFilesQuery,
  useAddProjectFileMutation,
  useUpdateProjectFilesMutation,
  useDeleteProjectFilesMutation,
  useAddProjectDocumentMutation,
  useDeleteProjectFileMutation,
} from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useDispatch } from 'react-redux';
import {
  createFile,
  createFolder,
  filterBranchByName,
  findActiveBranch,
  getCheckedFiles,
  getChildrenFiles,
  getFoldersStructure,
  setCheckedChildren,
  sortByType,
  updateBranchInTree,
  updateCheckedPaths,
  sortByName,
  sortByDate,
} from 'src/utils/fileUtils';
import { FilesManagerListView } from 'src/components/FileManager/ListView/ListView';
import Button from 'src/components/Button/Button';
import { AddFolderPopup } from 'src/components/FileManager/AddFolderPopup/AddFolderPopup';
import { UploadFileIcon } from 'src/assets/icons/UploadFileIcon';
import { CreateDocumentPopup } from 'src/components/FileManager/CreateDocumentPopup/CreateDocumentPopup';
import { KNOWLEDGE_BASE_FILE_EXTENSIONS } from 'src/shared/constants';
import {
  DeleteContentIcon,
  DeleteFilesIcon,
  MoveContentIcon,
  MoveFilesIcon,
} from 'src/assets/icons/FileIcons';
import { MoveFilesPopup } from 'src/components/FileManager/MoveFilesPopup/MoveFilesPopup';
import { DeletePopup } from 'src/components/DeletePopup/DeletePopup';
import { SearchFilesContainer } from 'src/components/FileManager/SearchFilesContainer/SearchFilesContainer';
import { FilePathContainer } from 'src/components/FileManager/FilePathContainer/FilePathContainer';
import { AddContentPopup } from 'src/components/FileManager/AddContentPopup/AddContentPopup';
import { getKnowledgeBaseConfig, setKnowledgeBaseConfig } from 'src/utils/pageStorageConfig';
import { Checkbox } from 'src/components/ui/checkbox';

const Counter = ({ count }) => (
  <div className={'text-ebony text-xs font-bold py-0.5 px-2 rounded-sm bg-purple'}>{count}</div>
);

const getInitialTree = () => {
  return {
    name: 'Knowledge Base',
    type: 'folder', // can be 'folder', 'file'
    path: '/',
    root: true,
    parent: false,
    children: [],
  };
};

const KnowledgeBasePage = () => {
  const initialTree = getInitialTree();
  const [dragStarted, setDragStarted] = useState(false);
  const [tree, setTree] = useState(initialTree);
  const [activeBranch, setActiveBranch] = useState(initialTree);
  const [moveContentMode, setMoveContentMode] = useState(false);
  const [deleteContentMode, setDeleteContentMode] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [syncArg, setSyncArg] = useState(true);
  const [selectedItem, setSelectedItem] = useState(activeBranch);
  const [viewType, setViewType] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const notification = useNotification();
  const loader = useLoader();
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const { id } = useParams();
  const [files, setFiles] = useState(false);
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const { data: project, isLoading, error, isError } = useGetProjectByIdQuery({ id: id });

  const {
    data: projectFilesResponse,
    error: errorProjectFiles,
    isError: isErrorProjectFiles,
    isLoading: isLoadingProjectFiles,
    isSuccess: isSuccessProjectFiles,
    refetch: refetchProjectFiles,
  } = useGetProjectFilesQuery(
    {
      projectId: id,
      sync: syncArg,
    },
    { skip: !project?.id },
  );

  const [
    addProjectFile,
    {
      error: errorAddProjectFile,
      isError: isErrorAddProjectFile,
      isSuccess: isSuccessAddProjectFile,
    },
  ] = useAddProjectFileMutation();

  const [
    addProjectDocument,
    {
      error: errorAddProjectDocument,
      isError: isErrorAddProjectDocument,
      isSuccess: isSuccessAddProjectDocument,
    },
  ] = useAddProjectDocumentMutation();

  const [
    deleteProjectFiles,
    {
      error: errorDeleteProjectFiles,
      isError: isErrorDeleteProjectFiles,
      isSuccess: isSuccessDeleteProjectFiles,
    },
  ] = useDeleteProjectFilesMutation();
  const [
    deleteProjectFile,
    {
      error: errorDeleteProjectFile,
      isError: isErrorDeleteProjectFile,
      isSuccess: isSuccessDeleteProjectFile,
    },
  ] = useDeleteProjectFileMutation();
  const [
    updateProjectFiles,
    {
      error: errorUpdateProjectFiles,
      isError: isErrorUpdateProjectFiles,
      isSuccess: isSuccessUpdateProjectFiles,
    },
  ] = useUpdateProjectFilesMutation();

  useEffect(() => {
    const { viewType } = getKnowledgeBaseConfig();

    setViewType(viewType || 'list');
  }, []);

  useEffect(() => {
    viewType &&
      setKnowledgeBaseConfig({
        viewType,
      });
  }, [viewType]);

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorProjectFiles) {
      notification.error(getErrorMessage(errorProjectFiles));
    }

    if (isErrorAddProjectFile) {
      notification.error(getErrorMessage(errorAddProjectFile));
    }

    if (isErrorUpdateProjectFiles) {
      notification.error(getErrorMessage(errorUpdateProjectFiles));
    }

    if (isErrorDeleteProjectFile) {
      notification.error(getErrorMessage(errorDeleteProjectFile));
    }

    if (isErrorDeleteProjectFiles) {
      notification.error(getErrorMessage(errorDeleteProjectFiles));
    }

    if (isErrorAddProjectDocument) {
      notification.error(getErrorMessage(errorAddProjectDocument));
    }
  }, [
    isErrorProjectFiles,
    isErrorAddProjectFile,
    isErrorUpdateProjectFiles,
    isErrorDeleteProjectFile,
    isErrorDeleteProjectFiles,
    isErrorAddProjectDocument,
  ]);

  useEffect(() => {
    if (isLoadingProjectFiles) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingProjectFiles]);

  useEffect(() => {
    if (projectFilesResponse) {
      setFiles(projectFilesResponse);
    }
  }, [projectFilesResponse]);

  useEffect(() => {
    let timeout;

    if (!files) {
      return;
    }

    if (files.length) {
      const foldersStructure = getFoldersStructure(files);
      const branch = findActiveBranch(foldersStructure);

      setTree(foldersStructure);
      updateActiveBranch(branch || foldersStructure);

      const deletingExist = files.some((file) => file.state === 'deleting');

      if (deletingExist) {
        timeout = setTimeout(async () => {
          const { isError, error } = await refetchProjectFiles();

          if (isError) {
            notification.error(getErrorMessage(error));
          }
        }, 1500);
      }
    } else {
      const emptyTree = getInitialTree();

      setTree(emptyTree);
      updateActiveBranch(emptyTree);
    }

    return () => timeout && clearTimeout(timeout);
  }, [files]);

  useEffect(() => {
    if (!activeBranch.isTemporary && isSuccessProjectFiles) {
      const config = getKnowledgeBaseConfig();

      config.activeBranchPath = activeBranch.path;
      setKnowledgeBaseConfig(config);
    }
  }, [activeBranch]);

  useEffect(() => {
    onCheckedChange();
  }, [selectAllChecked]);

  const updateActiveBranch = (branch) => {
    loader.show();
    const { sortBy } = getKnowledgeBaseConfig();

    switch (sortBy) {
      case 'type':
        sortByType(branch.children, 'folder');
        break;
      case 'name':
        sortByName(branch.children);
        break;
      case 'date':
        sortByDate(branch.children);
        break;
      default:
        sortByType(branch.children, 'folder');
        break;
    }

    setActiveBranch(branch);
    setSelectedItem(branch);
    loader.hide();
  };

  useEffect(() => {
    if (!moveContentMode && !deleteContentMode) {
      handleSelectAllChange(false);
      setSelectedCount(0);
    }
  }, [moveContentMode, deleteContentMode]);

  const uploadFiles = (files) => {
    const newFiles = [];

    loader.show({ progress: true });

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const request = new FormData();
        const filePath = activeBranch.path || '';

        request.append('file', file, file.name);
        request.append('file_path', filePath);

        const response = addProjectFile({
          projectId: id,
          body: request,
        });

        loader.updateProgress(((i + 1) / files.length) * 100);

        newFiles.push({
          name: file.name,
          path: filePath,
          state: 'uploading',
        });

        response.then(({ error, data }) => {
          if (error) {
            const children = activeBranch.children.filter(
              (child) => !(child.name === file.name && child.state === 'uploading'),
            );
            const newActiveBranch = { ...activeBranch, children };

            updateBranchInTree(tree, activeBranch, newActiveBranch);
            updateActiveBranch(newActiveBranch);

            return;
          }

          const children = activeBranch.children.map((child) => {
            const childPath = child.path.replace(/\/\//, '/');
            const path = `${data.path || ''}/${data.name}`.replace(/\/\//, '/');

            if (child.name === file.name && childPath === path) {
              return {
                ...child,
                ...data,
                path,
              };
            }

            return child;
          });
          const newActiveBranch = { ...activeBranch, children };

          updateBranchInTree(tree, activeBranch, newActiveBranch);
          updateActiveBranch(newActiveBranch);
        });
      }
    }

    loader.hide();

    return newFiles;
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newFiles = uploadFiles(event.dataTransfer.files);

    addFilesToBranch(newFiles, activeBranch);
    setDragStarted(false);
  };

  const addNewFolder = (folder_name) => {
    const folder = createFolder(folder_name, activeBranch);
    const newActiveBranch = {
      ...activeBranch,
    };
    newActiveBranch.children.push(folder);
    updateBranchInTree(tree, activeBranch, newActiveBranch);
    updateActiveBranch(newActiveBranch);
    setMoveContentMode(false);
    setDeleteContentMode(false);
  };

  const addFilesToBranch = (files, branch) => {
    const newBranch = {
      ...branch,
    };

    files.forEach((file) => {
      const fileItem = createFile(file, newBranch);

      newBranch.children.push(fileItem);
    });

    sortByType(newBranch.children, 'folder');
    updateBranchInTree(tree, branch, newBranch);

    if (branch === activeBranch) {
      updateActiveBranch(newBranch);
    }
  };

  const onFileChange = async (event) => {
    if (!event.target.files.length) {
      return;
    }

    const newFiles = uploadFiles(event.target.files);

    addFilesToBranch(newFiles, activeBranch);
  };

  const createDocument = async (data) => {
    const newFiles = [];

    loader.show();
    const response = await addProjectDocument({
      projectId: id,
      body: {
        ...data,
        path: activeBranch.path,
      },
    });
    loader.hide();

    if (!response.data.status) {
      newFiles.push(response.data);
    }

    newFiles.length && addFilesToBranch(newFiles, activeBranch);
  };

  const handleMoveItems = async (moveToFolder) => {
    const checkedFiles = getCheckedFiles(tree);
    const filesWithUpdatedPaths = updateCheckedPaths(checkedFiles, moveToFolder.path);
    const request = filesWithUpdatedPaths
      .filter((file) => file.type === 'file')
      .map((file) => {
        return {
          id: file.id,
          path: file.newPath.replace(`/${file.name}`, ''),
          name: file.name,
        };
      });

    if (request.length) {
      loader.show();
      await updateProjectFiles({
        projectId: id,
        body: request,
      });
      loader.hide();
      setMoveContentMode(false);
    }
  };

  const handleDeleteItems = async (files) => {
    const filesToDelete = files || getCheckedFiles(tree);
    const request = filesToDelete.filter((file) => file.type === 'file').map((file) => file.id);

    if (filesToDelete.length && !request.length) {
      const newActiveBranch = { ...activeBranch };

      newActiveBranch.children = activeBranch.children.filter((child) => {
        return !child.checked;
      });

      updateActiveBranch(newActiveBranch);
      updateBranchInTree(tree, activeBranch, newActiveBranch);
      setDeleteContentMode(false);
      return;
    }

    if (request.length) {
      loader.show();
      await deleteProjectFiles({
        projectId: id,
        body: request,
      });

      if (syncArg) {
        setSyncArg(false);
      } else {
        const { isError, error } = await refetchProjectFiles();

        if (isError) {
          notification.error(getErrorMessage(error));
        }
      }

      loader.hide();
      setDeleteContentMode(false);
    }
  };

  const handleSingleItemMove = async (moveToFolder, item) => {
    item.checked = true;
    if (item.type === 'folder') {
      setCheckedChildren(item);
    }

    await handleMoveItems(moveToFolder);
  };
  const handleSingleItemRename = async (name, item) => {
    let files = [item];

    if (item.type === 'folder') {
      files = getChildrenFiles(item);
    }

    let request = files.map((file) => {
      if (item.type === 'file') {
        return {
          id: file.id,
          name: name,
          path: file.parent.path,
        };
      }
      if (item.type === 'folder') {
        const newPath = item.path.replace(`/${item.name}`, `/${name}`);

        return {
          id: file.id,
          name: file.name,
          path: file.path.replace(`${item.path}`, newPath).replace(`/${file.name}`, ''),
        };
      }
    });

    if (request.length) {
      loader.show();
      await updateProjectFiles({
        projectId: id,
        body: request,
      });
      loader.hide();
    }
  };
  const handleSingleItemDelete = async (item) => {
    if (item.type === 'file') {
      loader.show();
      await deleteProjectFile({
        projectId: id,
        fileId: item.id,
      });
      loader.hide();
    }

    if (item.type === 'folder') {
      const files = getChildrenFiles(item);

      await handleDeleteItems(files);
    }
  };

  const onHandleSearch = (value) => {
    if (value) {
      let newActiveBranch = filterBranchByName(value, tree);
      newActiveBranch.isTemporary = true;

      updateActiveBranch(newActiveBranch);
    } else {
      const newActiveBranch = findActiveBranch(tree);

      updateActiveBranch(newActiveBranch || tree);
    }
  };

  const handleFileSelect = (files) => {
    setSelectedItem(files[0]);
  };

  const onViewTypeChange = (viewType) => {
    setViewType(viewType);
  };

  const onSortFilesChange = (sortBy) => {
    updateActiveBranch({ ...activeBranch });
  };

  const handleSelectAllChange = (checked) => {
    const newBranch = { ...activeBranch };

    setSelectAllChecked(checked);
    setCheckedChildren(newBranch, checked);
    updateBranchInTree(tree, activeBranch, newBranch);
    updateActiveBranch(newBranch);
  };

  const onCheckedChange = () => {
    if (moveContentMode || deleteContentMode) {
      const checkedFiles = getCheckedFiles(tree);
      setSelectedCount(checkedFiles.length);
    }
  };

  return (
    <React.Fragment>
      <PageContentWrapper
        className={clsx('knowledge-base-page-container', !isLgDown && 'relative')}
        isMobileMarginTop={false}
        isProjectBreadcrumbs={true}
        projectBreadcrumbsContent={'Knowledge Base'}
      >
        <div className="z-10 gap-[10px] flex flex-col relative h-[100%]">
          <CardContainer className="gap-[16px]">
            {project && project.is_active ? (
              <>
                <SearchFilesContainer
                  onViewTypeChange={onViewTypeChange}
                  onSearch={onHandleSearch}
                  onSortFilesChange={onSortFilesChange}
                  activeBranch={activeBranch}
                />
                <div className={'knowledge-base-container'}>
                  <div className="knowledge-base-buttons flex justify-start gap-[8px]">
                    {isLgDown ? (
                      <AddContentPopup
                        activeBranch={activeBranch}
                        addFolder={addNewFolder}
                        createDocument={createDocument}
                        onUploadFile={() => inputFile.current.click()}
                      />
                    ) : (
                      <>
                        <div>
                          <AddFolderPopup activeBranch={activeBranch} addFolder={addNewFolder} />
                        </div>
                        <div>
                          <Button
                            className="transparent black gap-[10px]"
                            onClick={() => inputFile.current.click()}
                          >
                            Upload file
                            <UploadFileIcon />
                          </Button>
                        </div>
                        <div>
                          <CreateDocumentPopup createDocument={createDocument} />
                        </div>
                      </>
                    )}
                    <div className="flex flex-1 justify-end items-center gap-[20px]">
                      {!moveContentMode && !deleteContentMode && (
                        <>
                          {' '}
                          <div
                            className={clsx(moveContentMode && 'active', 'move-content-button')}
                            onClick={() => {
                              setMoveContentMode(!moveContentMode);
                              !moveContentMode && setDeleteContentMode(false);
                            }}
                          >
                            <MoveContentIcon />
                            Move {!isLgDown && 'content'}
                          </div>
                          <div
                            className={clsx(deleteContentMode && 'active', 'delete-content-button')}
                            onClick={() => {
                              setDeleteContentMode(!deleteContentMode);
                              !deleteContentMode && setMoveContentMode(false);
                            }}
                          >
                            <DeleteContentIcon />
                            Delete {!isLgDown && 'content'}
                          </div>{' '}
                        </>
                      )}
                    </div>
                    <div className="flex justify-center gap-3.5">
                      {(moveContentMode || deleteContentMode) && (
                        <Button
                          className="transparent black"
                          onClick={() => {
                            setMoveContentMode(false);
                            setDeleteContentMode(false);
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                      {moveContentMode && (
                        <MoveFilesPopup
                          moveFilesCallback={handleMoveItems}
                          item={tree}
                          triggerEl={
                            <Button className="purple knowledge-base-move-files-button">
                              Move
                              <MoveFilesIcon />
                            </Button>
                          }
                        />
                      )}
                      {deleteContentMode && (
                        <DeletePopup
                          title={'Delete content'}
                          description={
                            'Are you sure you want to delete this content irretrievably?'
                          }
                          deleteHandler={handleDeleteItems}
                          triggerIcon={
                            <Button className="red knowledge-base-delete-files-button">
                              Delete content
                              <DeleteFilesIcon />
                            </Button>
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={clsx(
                      'knowledge-base-select-all flex justify-start gap-[8px] mt-5 items-center',
                      moveContentMode || deleteContentMode ? '' : 'invisible pointer-events-none',
                    )}
                  >
                    <Checkbox
                      id="knowledge-base-select-all-checkbox"
                      checked={selectAllChecked}
                      className={
                        'rounded-[2px] border-ebony opacity-30 data-[state=checked]:opacity-100'
                      }
                      onCheckedChange={handleSelectAllChange}
                    />
                    <label htmlFor="knowledge-base-select-all-checkbox">Select All</label>
                    <Counter count={selectedCount} />
                  </div>
                  <section
                    className={clsx(
                      dragStarted && 'drag-active',
                      'max-h-[70vh] overflow-hidden mt-[20px]',
                    )}
                    onDrop={handleDrop}
                    onDragEnter={(event) => {
                      event.preventDefault();
                      setDragStarted(true);
                    }}
                    onDragOver={(event) => {
                      event.preventDefault();
                    }}
                    onDragLeave={(event) => {
                      event.preventDefault();
                      setDragStarted(false);
                    }}
                  >
                    <input
                      type="file"
                      ref={inputFile}
                      className={'hidden'}
                      onChange={onFileChange}
                      multiple={true}
                      accept={KNOWLEDGE_BASE_FILE_EXTENSIONS.join(', ')}
                    />
                    {activeBranch && (
                      <FilesManagerListView
                        className={clsx(
                          'h-[60vh] overflow-auto',
                          viewType ? `view-type-${viewType}` : 'view-type-list',
                        )}
                        handleSingleItemMove={handleSingleItemMove}
                        handleSingleItemRename={handleSingleItemRename}
                        handleSingleItemDelete={handleSingleItemDelete}
                        checkboxVisible={moveContentMode || deleteContentMode}
                        activeBranch={activeBranch}
                        updateActiveBranch={updateActiveBranch}
                        onFileSelect={handleFileSelect}
                        onCheckedChange={onCheckedChange}
                      />
                    )}
                  </section>
                  <FilePathContainer file={selectedItem} updateActiveBranch={updateActiveBranch} />
                </div>
              </>
            ) : (
              <div className="flex items-center w-full md:p-[20px] p-[16px]">
                <p className="font-medium text-base text-ebony opacity-70">
                  Project is not active. Please contact your CoSupport Manager.
                </p>
              </div>
            )}
          </CardContainer>
        </div>
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default KnowledgeBasePage;
