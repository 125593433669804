import React, { useEffect, useRef, useState } from 'react';
import './select.css';
import { clsx } from 'clsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';

const CloseIcon = ({ isOpen }) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isOpen ? 'translate' : ''}
    >
      <path
        d="M7.47395 11.4853L15.9592 19.9706L24.4445 11.4853"
        stroke="#05050D"
        strokeOpacity="0.7"
        strokeWidth="2"
      />
    </svg>
  );
};

export const CustomSelect = ({
  label = 'Please Select',
  readonly = false,
  options = [],
  onChange = () => {},
  align,
  value,
  dropdownMenuClassNames = '',
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(options[0]);
  const inputRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    setSelectedValue(options?.find((option) => option?.label === value?.label));
  }, [value]);

  useEffect(() => {
    const handler = (e) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(e.target) &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  const handleInputClick = () => {
    setShowMenu((prevState) => !prevState);
  };

  const onItemClick = (option) => {
    setSelectedValue(option);
    onChange(option);
    setShowMenu((prevState) => !prevState);
  };

  const isSelected = (option) => {
    if (!selectedValue) {
      return false;
    }

    return selectedValue.label === option.label;
  };

  return (
    <div
      className={clsx('custom--dropdown-container', readonly ? 'cursor-auto' : 'cursor-pointer')}
    >
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className={clsx(
          showMenu ? 'rounded-t-[7px]' : 'rounded-[7px]',
          'dropdown-input',
          readonly && 'pointer-events-none opacity-70 cursor-auto',
        )}
      >
        <div
          className={`flex items-center gap-[7px] dropdown-selected-value ${!selectedValue || selectedValue.length === 0 ? 'placeholder' : ''}`}
        >
          {selectedValue ? (
            <>
              {selectedValue.icon}
              <span className="text-start hyphens-auto">{selectedValue.label}</span>
            </>
          ) : (
            label
          )}
        </div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <CloseIcon isOpen={showMenu} />
          </div>
        </div>
      </div>

      {showMenu && (
        <div
          className={clsx(
            dropdownMenuClassNames,
            `dropdown-menu relative alignment--${align || 'auto'} w-full max-h-80`,
          )}
          ref={menuRef}
        >
          {options?.map((option, index) => (
            <TooltipProvider key={`${option?.label}-${index}`}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    disabled={option?.disabled ?? false}
                    onClick={() => onItemClick(option)}
                    className={`flex items-center gap-[7px] w-full dropdown-item ${isSelected(option) && 'selected'}`}
                  >
                    {option?.icon}
                    <span className="text-start hyphens-auto">{option?.label}</span>
                  </button>
                </TooltipTrigger>
                {option?.toolTipDescription && (
                  <TooltipContent className="max-w-2xl">{option.toolTipDescription}</TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      )}
    </div>
  );
};
