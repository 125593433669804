import React from 'react';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import { clsx } from 'clsx';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import getSymbolFromCurrency from 'currency-symbol-map';
import { PaymentHistoryPdfPopup } from 'src/pages/SubscriptionPage/PaymentHistorySection/PaymentHistoryPdfPopup/PaymentHistoryPdfPopup';
import { CheckIcon } from 'src/assets/icons/checkIcon';
import { CancelIcon } from 'src/assets/icons/cancelIcon';
import { PaidCardIcon } from 'src/assets/icons/paidCardIcon';

export const PaymentHistory = ({ isFetching, payments }) => {
  const isMdDown = useMediaQuery('(max-width: 767px)');

  const isEmpty = !Boolean(payments && payments.length);

  return isEmpty || (!payments && isFetching) ? (
    <div className="empty-message">
      <LoaderWrapper loader={<Skeleton className="w-full h-5" />} isLoading={isFetching}>
        There is no payment history.
      </LoaderWrapper>
    </div>
  ) : (
    <section
      className={clsx('transition', isFetching && 'animate-pulse blur-sm pointer-events-none')}
    >
      {isMdDown ? (
        <div className="gap-[6px] flex flex-col mt-[10px]">
          {payments &&
            payments.map((payment) => {
              return (
                <div
                  key={payment.id}
                  className="flex flex-col border-[rgba(184,193,198,0.4)] rounded-[5px] border"
                >
                  <div className="text-sm border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px]">
                    <div className="col name md:text-base text-sm flex gap-2 justify-between">
                      <div className="flex items-center gap-2 w-full">
                        <PaidCardIcon height="34" width="34" className="min-w-8" />
                        <p className="text-sm font-semibold hyphens-auto text-ellipsis max-w-full break-all">
                          {payment.description}
                        </p>
                      </div>
                      <div className="flex gap-2 flex-nowrap items-center">
                        <PaymentHistoryPdfPopup payment={payment} />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">Amount:</span>
                    <p className="truncate">
                      -{' '}
                      {payment?.amount
                        ? `${payment.amount} ${payment?.currency ? getSymbolFromCurrency(payment.currency) : ''}`
                        : '-----'}
                    </p>
                  </div>
                  <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">Date:</span>
                    <div className="flex items-center truncate">
                      <span className="text-xs opacity-70 font-medium">
                        {transformDateHelper(payment.created, DateFormat.MONTH_DAY_YEAR)},{' '}
                        {transformDateHelper(payment.created, DateFormat.HOURS_AM_PM)}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center text-xs border-[rgba(184,193,198,0.4)] border-b-[1px] p-[15px] gap-[10px]">
                    <span className="text-[rgba(184,193,198,1)] font-medium	">Status:</span>
                    <div className="bg-light_badge py-1.5 px-2 w-fit rounded-full flex gap-1 items-center">
                      {payment.status === 'succeeded' ? <CheckIcon /> : <CancelIcon />}
                      <p className="truncate capitalize">{payment.status}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-center">Date</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="text-right"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {payments &&
                payments.map((payment) => {
                  return (
                    <TableRow key={payment.id}>
                      <TableCell className="max-w-80">
                        <div className="flex items-center gap-3">
                          <PaidCardIcon />
                          <div className="flex flex-col">
                            <span className="text-base font-medium">
                              {transformDateHelper(payment.created, DateFormat.MONTH_DAY_YEAR)}
                            </span>
                            <span className="text-xs opacity-50 font-medium">
                              {transformDateHelper(payment.created, DateFormat.HOURS_AM_PM)}
                            </span>
                          </div>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className="col">
                          <p className="text-base font-medium text-ebony">{payment.description}</p>
                        </div>
                      </TableCell>
                      <TableCell className="max-w-80">
                        <div className="col">
                          <p className="text-base font-medium text-ebony">
                            -{' '}
                            {payment?.amount
                              ? `${payment.amount} ${payment?.currency ? getSymbolFromCurrency(payment.currency) : ''}`
                              : '-----'}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="col">
                          <div className="bg-light_badge py-1.5 px-2 w-fit rounded-full flex gap-1 items-center">
                            {payment.status === 'succeeded' ? <CheckIcon /> : <CancelIcon />}
                            <p className="text-xs font-medium text-ebony opacity-70 capitalize">
                              {payment.status}
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="col edit flex-5">
                          <PaymentHistoryPdfPopup payment={payment} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </>
      )}
    </section>
  );
};
