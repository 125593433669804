import React, { useEffect, useRef, useState } from 'react';
import './MultiSelect.css';
import { clsx } from 'clsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Checkbox } from 'src/components/ui/checkbox';

const CloseIcon = ({ isOpen }) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={isOpen ? 'translate' : ''}
    >
      <path
        d="M7.47395 11.4853L15.9592 19.9706L24.4445 11.4853"
        stroke="#05050D"
        strokeOpacity="0.7"
        strokeWidth="2"
      />
    </svg>
  );
};

export const MultiSelect = ({
  options = [],
  onChange = () => {},
  onClose = () => {},
  values = [],
  align,
  dropdownMenuClassNames = '',
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValues, setSelectedValues] = useState([options[0]]);
  const inputRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  useEffect(() => {
    if (!showMenu) {
      onClose && onClose(selectedValues);
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(e.target) &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  const handleInputClick = () => {
    setShowMenu((prevState) => !prevState);
  };

  const onItemClick = (checked, option) => {
    let selected;

    if (checked) {
      selected = [...selectedValues, option];
      setSelectedValues(selected);
    } else {
      selected = selectedValues.filter((value) => value.label !== option.label);
      setSelectedValues(selected);
    }

    onChange(selected, option, checked);
  };

  const isSelected = (option) => {
    if (!selectedValues.length) {
      return false;
    }

    return selectedValues.some((value) => value.label === option.label);
  };

  return (
    <div className="custom--multiselect-container">
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className={clsx(showMenu ? 'rounded-t-[7px]' : 'rounded-[7px]', 'dropdown-input')}
      >
        <div
          className={`flex items-center gap-[7px] dropdown-selected-value ${!selectedValues || selectedValues.length === 0 ? 'placeholder' : ''}`}
        >
          <p className="w-[150px] text-ellipsis	whitespace-nowrap overflow-hidden">
            {selectedValues.map((selectedValue, index, arr) => {
              return (
                <span
                  key={`${selectedValue.label.toLowerCase()}_${index}`}
                  className="text-start hyphens-auto"
                >
                  {selectedValue.label}
                  {index !== arr.length - 1 && ', '}
                </span>
              );
            })}
          </p>
        </div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <CloseIcon isOpen={showMenu} />
          </div>
        </div>
      </div>
      {showMenu && (
        <div
          className={clsx(
            dropdownMenuClassNames,
            `dropdown-menu relative alignment--${align || 'auto'} w-full max-h-80`,
          )}
          ref={menuRef}
        >
          {options?.map((option, index) => {
            const key = `${option?.label.toLowerCase().replace(/\s/g, '-')}-${index}`;

            return (
              <TooltipProvider key={key}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className={`flex flex-row items-center gap-[7px] w-full dropdown-item ${isSelected(option) && 'selected'}`}
                    >
                      <Checkbox
                        id={`multiselect-${key}`}
                        checked={isSelected(option)}
                        className={'rounded-[2px]'}
                        onCheckedChange={(checked) => {
                          onItemClick(checked, option);
                        }}
                      />
                      <label htmlFor={`multiselect-${key}`}>{option?.label}</label>
                    </div>
                  </TooltipTrigger>
                  {option?.toolTipDescription && (
                    <TooltipContent className="max-w-2xl">
                      {option.toolTipDescription}
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            );
          })}
        </div>
      )}
    </div>
  );
};
