import './IntegrationFormZendesk.css';
import Input from '../../Input/Input';
import React, { useState } from 'react';
import { Checkbox } from '../../Checkbox/Checkbox';
import { EMAIL_REGEX } from '../../../shared/constants';

export const IntegrationFormZendesk = ({ integration, onSubmit }) => {
  const [email, setEmail] = useState(integration.integration_data.auth_email);
  const [viaEmail, setViaEmail] = useState(integration.integration_data.via_email);
  const [viaChat, setViaChat] = useState(integration.integration_data.via_chat);
  const [apiToken, setApiToken] = useState(integration.integration_data.api_token);
  const [subdomain, setSubdomain] = useState(integration.integration_data.subdomain);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isSubdomainValid, setIsSubdomainValid] = useState(true);
  const [isApiTokenValid, setIsApiTokenValid] = useState(true);

  const validateEmail = () => {
    const isValid = EMAIL_REGEX.test(email);

    setIsEmailValid(isValid);
  };

  const submit = (event) => {
    event.preventDefault();

    const data = {
      ...integration,
      integration_data: {
        ...integration.integration_data,
        auth_email: email,
        api_token: apiToken,
        via_chat: viaChat,
        via_email: viaEmail,
        subdomain,
      },
    };

    onSubmit(data);
  };

  return (
    <form id="integration-form" onSubmit={submit}>
      <div className={'input-container'}>
        <Input
          type={'text'}
          label={'Integration Type'}
          placeholder={'Integration Type'}
          value={integration.integration_type}
          readonly={true}
          isValid={true}
        />
      </div>
      <div className={'input-container'}>
        <Input
          type={'text'}
          label={'Credits'}
          placeholder={'Credits'}
          value={integration.integration_data.credits}
          readonly={true}
          isValid={true}
        />
      </div>
      <div className={'input-container'}>
        <Input
          type={'text'}
          label={'Subdomain'}
          placeholder={'Subdomain'}
          value={subdomain}
          invalidMessage={subdomain ? 'Invalid Format' : 'Required'}
          isValid={isSubdomainValid}
          onChange={(event) => setSubdomain(event.currentTarget.value)}
          onBlur={() => setIsSubdomainValid(subdomain && subdomain.length > 2)}
          onFocus={() => setIsSubdomainValid(true)}
        />
      </div>
      <div className="sub-title items-start md:flex-row flex-col gap-[16px] md:gap-[20px]">
        <h4>Credentials:</h4>
      </div>
      <div className={'input-container'}>
        <Input
          type={'email'}
          label={'Email'}
          placeholder={'email@email.com'}
          invalidMessage={email ? 'Invalid Format' : 'Required'}
          value={email}
          isValid={isEmailValid}
          onChange={(event) => setEmail(event.currentTarget.value)}
          onBlur={() => validateEmail(false)}
          onFocus={() => setIsEmailValid(true)}
        />
      </div>
      <div className={'input-container'}>
        <Input
          type={'password'}
          label={'API Token'}
          placeholder={'••••••••••••••••••••••••••••'}
          invalidMessage={'Invalid'}
          isValid={isApiTokenValid}
          value={apiToken}
          onChange={(event) => setApiToken(event.currentTarget.value)}
          onBlur={() => setIsApiTokenValid(apiToken && apiToken.length > 2)}
          onFocus={() => setIsApiTokenValid(true)}
        />
      </div>
      <div className="sub-title integrate-via items-start md:flex-row flex-col gap-[16px] md:gap-[20px]">
        <h4>Integrate Via:</h4>
        <Checkbox
          label={'Email'}
          checked={viaEmail}
          onChange={(event) => setViaEmail(event.target.checked)}
        />
        <Checkbox
          label={'Chat'}
          checked={viaChat}
          onChange={(event) => setViaChat(event.target.checked)}
        />
      </div>
    </form>
  );
};
