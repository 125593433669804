import React from 'react';
import { CheckIcon } from 'src/assets/icons/checkIcon';
import { CancelIcon } from 'src/assets/icons/cancelIcon';
import { useTheme } from 'src/provider/ThemeProvider';
import { clsx } from 'clsx';
import { ThemeColors } from 'src/constants/theme-colors';
import { ProjectPlanStatus } from 'src/constants/projects';

export const StatusBadgeColors = ({ status }) => {
  const { config } = useTheme();

  const isCancelIcon = [ProjectPlanStatus.INACTIVE, ProjectPlanStatus.CANCELED].includes(status);

  const isCheckIcon = [ProjectPlanStatus.ACTIVE].includes(status);

  const Icon = () => {
    if (isCheckIcon) {
      return <CheckIcon fill={config.colorFill} stroke={config.colorFill} />;
    }
    if (isCancelIcon) {
      return <CancelIcon />;
    }
  };
  return (
    <div
      className={clsx(
        config.className === ThemeColors.purple && 'bg-purple_light_bg',
        config.className === ThemeColors.blue && 'bg-malibu_light_bg',
        config.className === ThemeColors.green && 'bg-light_badge',
        config.className === ThemeColors.yellow && 'bg-chartreuse_yellow_light_bg',
        isCancelIcon && '!bg-error_bg_light',
        'bg-light_badge py-1.5 px-2 w-fit rounded-full flex gap-1 items-center',
      )}
    >
      <Icon />
      <p className="text-xs font-medium text-ebony capitalize">{status}</p>
    </div>
  );
};
