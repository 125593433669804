import './AnalyticsAgentChartPopup.css';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import React, { useState } from 'react';
import { CloseAnalyticsAgentChartPopupIcon } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentChartPopup/CloseAnalyticsAgentChartPopupIcon';
import { OpenAnalyticsAgentChartPopupIcon } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentChartPopup/OpenAnalyticsAgentChartPopupIcon';
import { AnalyticsUsersList } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsTabs/AnalyticsUsersList/AnalyticsUsersList';

export const AnalyticsAgentChartPopup = ({
  disabled = false,
  title = '',
  className = '',
  teamList,
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <div className="bg-[#fff] p-[9px] rounded-[4px] cursor-pointer icon-container">
          <OpenAnalyticsAgentChartPopupIcon />
        </div>
      </DialogTrigger>
      <DialogContent closeButtonHidden={true} className={clsx(className, '!max-w-[90vw]')}>
        <DialogHeader className={'flex-row items-center justify-between'}>
          <DialogTitle className="text-start">
            <div className={'flex flex-row gap-[8px] items-center justify-center'}>
              <span>{title}</span>
              <span
                className={
                  'rounded-[50px] bg-white shadow-utc px-[14px] py-[4px] text-ebony text-sm'
                }
              >
                UTC
              </span>

              <AnalyticsUsersList userList={teamList} />
            </div>
          </DialogTitle>
          <DialogClose
            className={'!m-0 rounded-[4px] bg-[#fff] p-[7px] analytics-popup-close-icon'}
          >
            <CloseAnalyticsAgentChartPopupIcon />
          </DialogClose>
        </DialogHeader>
        <DialogFooter className="max-md:flex-row max-md:gap-2.5 bg-white rounded-[7px]">
          <div className={'w-full h-full'}>{children}</div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
