import '../ProjectPage.css';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { TeamList } from 'src/components/TeamList/TeamList';
import { AddTeamMemberPopup } from 'src/components/AddTeamMemberPopup/AddTeamMemberPopup';
import { useLoader } from 'src/provider/LoaderProvider';
import AnimateHeight from 'react-animate-height';
import { LATEST_VIEWED_PROJECT_ID } from 'src/shared/constants';
import PageContentWrapper from '../../../components/PageContentWrapper/PageContentWrapper';
import CardContainer from '../../../components/CardContainer/CardContainer';
import { setProjectId } from 'src/store/slices/projectsSlices';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useGetProjectByIdQuery } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { PaginationComponent } from 'src/components/Pagination/PaginationComponent';
import { usePagination } from 'src/hooks/usePagination';
import { ExpandIcon } from 'src/assets/icons/expandIcon';
import { ProductsType as ProjectsType } from 'src/constants/products';
import { CurrentSubscriptionPlansSection } from 'src/pages/SubscriptionPage/CurrentSubscriptionPlansSection/CurrentSubscriptionPlansSection';
import { ProjectInfoSection } from '../ProjectInfoSection/ProjectInfoSection';

const Counter = ({ items }) => items && <div className={'yellow-counter'}>{items.length}</div>;

export const CurrentProjectScreen = () => {
  const { id } = useParams();
  const [projectTeamMembers, setProjectTeamMembers] = useState([]);
  const loader = useLoader();
  const notification = useNotification();
  const [teamHeight, setTeamHeight] = useState('auto');
  const dispatch = useAppDispatch();
  const { projectType } = useAppSelector((s) => s.projectsSlices);

  const { onChangeRowsPerPage, onChangePage, pagination, onSearch } = usePagination();

  const {
    data: currentProject,
    isFetching: isFetchingProject,
    isError,
    error,
  } = useGetProjectByIdQuery({ id: id });

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (isFetchingProject) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isFetchingProject, projectType]);

  useEffect(() => {
    if (id && currentProject?.project_type) {
      localStorage.setItem(LATEST_VIEWED_PROJECT_ID, id);
      dispatch(
        setProjectId({
          projectId: id,
          projectType: currentProject?.project_type,
        }),
      );
    }
  }, [id, currentProject]);

  useEffect(() => {
    if (currentProject) {
      const teamMembers = currentProject?.team_members?.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / pagination.rowsPerPage);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      setProjectTeamMembers(teamMembers);
    }
  }, [currentProject, pagination]);

  const isAgentType = projectType === ProjectsType.agent;

  return (
    <React.Fragment>
      <PageContentWrapper
        projectBreadcrumbsContent={'Overview'}
        className="project-page-container"
        isMobileMarginTop={false}
      >
        <ProjectInfoSection />
        <CurrentSubscriptionPlansSection infoOnly />
        {isAgentType && (
          <CardContainer className="team">
            <div className="card-header z-50">
              <div
                className={teamHeight ? 'title' : 'title collapsed'}
                onClick={() => setTeamHeight(teamHeight ? 0 : 'auto')}
              >
                <Counter items={currentProject && currentProject.team_members} /> <span>Team</span>{' '}
                <ExpandIcon />
              </div>
              <div className="add-button-container">
                {currentProject && <AddTeamMemberPopup project={currentProject} />}
              </div>
            </div>
            <AnimateHeight height={teamHeight}>
              <div className="card-body flex flex-col gap-3">
                {currentProject && projectTeamMembers && (
                  <TeamList team={projectTeamMembers[pagination.page - 1]} />
                )}
                <div className="w-full flex justify-end">
                  {!!currentProject?.team_members?.length && (
                    <PaginationComponent
                      rowsPerPageOptions={[5, 10, 15]}
                      count={currentProject?.team_members?.length}
                      rowsPerPage={pagination.rowsPerPage}
                      currentPage={pagination.page}
                      onPageChange={onChangePage}
                      onRowsPerPageChange={onChangeRowsPerPage}
                    />
                  )}
                </div>
              </div>
            </AnimateHeight>
          </CardContainer>
        )}
      </PageContentWrapper>
    </React.Fragment>
  );
};
