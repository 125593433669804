import React, { useEffect } from 'react';
import { clsx } from 'clsx';
import { CompanyIcon } from 'src/assets/icons/companyIcon';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/constants/spa-routes';
import { CompanySelectModal } from 'src/components/CompanySelectComponent/CompanySelectModal';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { persistor, useAppSelector } from 'src/store';
import { useDispatch } from 'react-redux';
import { selectManagedAccountId, setIsCompanyChanged } from 'src/store/slices/companySlice';
import { baseApi } from 'src/services';
import {
  refetchTagsWithAccountParam,
  refetchTagsWithAccountParamForSubscription,
} from 'src/constants/refetch-account-params';
import { Drawer } from 'vaul';

const storageCompanyKey =
  typeof window !== 'undefined' && window.localStorage.getItem('persist:company');

export const CompanySelectComponent = ({
  disabled = false,
  isAccordion = false,
  isLgDown = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { managedAccountId } = useAppSelector((s) => s.companySlices);

  const { data: userData } = useGetUsersMeQuery();

  const dispatch = useDispatch();
  const storageSelectedAccount = userData?.managed_accounts?.find(
    (el) => el?.account?.id === Number(managedAccountId),
  )?.account;

  useEffect(() => {
    if (userData?.managed_accounts) {
      if (!storageSelectedAccount) {
        dispatch(selectManagedAccountId(''));
        dispatch(setIsCompanyChanged(false));
      }
    }
  }, [userData?.managed_accounts]);

  const isAccountLocation = location.pathname.includes(PATH_DASHBOARD.ACCOUNT);
  const isSubscriptionLocation = location.pathname.includes(PATH_DASHBOARD.SUBSCRIPTION);

  const handleRefetchAllQuery = () => {
    dispatch(baseApi.util.invalidateTags(refetchTagsWithAccountParam));
    if (isSubscriptionLocation) {
      dispatch(baseApi.util.invalidateTags(refetchTagsWithAccountParamForSubscription));
    }
  };

  const changeAccountHandler = async (accountId) => {
    if (!isAccountLocation && !isSubscriptionLocation) {
      await new Promise((resolve) => {
        navigate(PATH_DASHBOARD.ACCOUNT);
        setTimeout(resolve, 0);
      });
    }
    dispatch(selectManagedAccountId(accountId));
    if (!isSubscriptionLocation) {
      if (String(accountId) === JSON.parse(storageCompanyKey)?.managedAccountId) {
        dispatch(setIsCompanyChanged(false));
      } else {
        dispatch(setIsCompanyChanged(true));
      }
    }
    handleRefetchAllQuery();
    if (!accountId) {
      persistor.flush().then(() => {
        return persistor.purge();
      });
    }
  };

  const AccordionContentItem = ({ children, className }) => {
    return isLgDown ? (
      <Drawer.Close asChild className={className}>
        {children}
      </Drawer.Close>
    ) : (
      children
    );
  };

  return isAccordion ? (
    <Accordion disabled={disabled} type="single" collapsible className="w-full">
      <AccordionItem value="companySelect" className="border-b-0">
        <AccordionTrigger
          className={clsx(
            !disabled
              ? '[&_p]:hover:opacity-100 [&_svg:last-child]:hover:opacity-100'
              : 'opacity-40',
            'rounded w-full [&[data-state=open]]:bg-woodsmoke [&_svg:first-child]:hover:opacity-100 [&_svg:first-child]:opacity-50 [&_p]:transition-all [&_svg]:transition-all [&[data-state=open]_p]:opacity-100 [&[data-state=open]_svg]:opacity-100 [&_svg:last-child]:opacity-50 [&_p]:opacity-60',
          )}
        >
          <div className="flex items-center gap-2.5">
            <CompanyIcon fill="#FFFFFF99" fillInner={'#05050D'} />
            <p className="text-white text-lg font-medium">
              {storageSelectedAccount ? storageSelectedAccount.company_name : 'Company'}
            </p>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="ml-11 mt-2 flex flex-col gap-1.5 relative">
            {userData?.managed_accounts?.map((el) => {
              const activeAccount = Number(managedAccountId) === Number(el?.account.id);
              return (
                <AccordionContentItem key={el?.id}>
                  <div
                    className={clsx(
                      activeAccount ? '[&_span]:opacity-100' : '[&_span]:opacity-60',
                      '[&_span]:hover:opacity-100 [&_span]:transition-all relative text-left cursor-pointer',
                    )}
                    onClick={() => changeAccountHandler(el?.account?.id)}
                  >
                    <button className="py-2 text-left">
                      <span
                        className={clsx(
                          activeAccount && 'font-bold',
                          'text-white font-medium text-base',
                        )}
                      >
                        {el?.account?.company_name}
                      </span>
                    </button>
                  </div>
                </AccordionContentItem>
              );
            })}
            <AccordionContentItem
              className={clsx(Boolean(!managedAccountId) && 'pointer-events-none opacity-40')}
            >
              <div
                className={clsx(
                  '[&_span]:opacity-60',
                  '[&_span]:hover:opacity-100 [&_span]:transition-all relative text-left cursor-pointer',
                )}
                onClick={() => changeAccountHandler('')}
              >
                <button className="py-2 text-left">
                  <span className={clsx('text-white font-medium text-base')}>Reset</span>
                </button>
              </div>
            </AccordionContentItem>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  ) : (
    <CompanySelectModal
      disabled={disabled}
      accounts={userData?.managed_accounts}
      changeAccountHandler={changeAccountHandler}
      storageSelectedAccount={storageSelectedAccount}
    />
  );
};
