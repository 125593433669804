import './MoveFilesPopup.css';
import React, { useEffect, useState } from 'react';
import Button from '../../Button/Button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import Input from 'src/components/Input/Input';
import { MoveFilesIcon } from 'src/assets/icons/FileIcons';
import { getFoldersArrayFromTree, getRootFolder } from 'src/utils/fileUtils';

export const MoveFilesPopup = ({ moveFilesCallback, triggerEl, item, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [folders, setFolders] = useState([]);
  const tree = getRootFolder(item);
  const foldersArray = getFoldersArrayFromTree(tree);

  const onChange = (event) => {
    const value = event.target.value;

    if (value) {
      const newFolders = foldersArray.filter((folder) =>
        folder.name.toLowerCase().includes(value.toLowerCase()),
      );

      setFolders(newFolders);
    } else {
      setFolders(foldersArray);
    }

    console.log(event, foldersArray);
  };
  const submit = (event) => {
    event.preventDefault();
    moveFilesCallback(checked);
    setOpen(false);
  };

  useEffect(() => {
    setFolders(foldersArray);
  }, [tree]);

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger
          disabled={disabled}
          asChild
          className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
        >
          {triggerEl}
        </DialogTrigger>
        <DialogContent className="knowledge-base-move-files-popup">
          <DialogHeader>
            <DialogTitle>Select new folder</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="content">
              <form id="move-to-folder" onSubmit={submit} className={'flex flex-col gap-[20px]'}>
                <div className={'input-container'}>
                  <Input
                    className={'purple'}
                    type={'text'}
                    label={''}
                    placeholder={'Search'}
                    isValid={true}
                    onChange={onChange}
                  />
                </div>
                <ul className="flex flex-col">
                  {folders.map((folder) => {
                    const id = folder.path || 'knowledge-base';
                    return (
                      <li
                        key={folder.path || 'knowledge-base'}
                        className={'filter-list-item w-full'}
                      >
                        <label htmlFor={id} className={'flex flex-row items-center w-full'}>
                          <input
                            name={'task-complexity'}
                            value={folder.path}
                            id={id}
                            type="radio"
                            checked={folder === checked}
                            onChange={() => setChecked(folder)}
                            className="w-auto"
                          />
                          <p>{folder.path || '/'}</p>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </form>
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button style={{ width: 'auto' }} className="transparent black">
                Cancel
              </Button>
            </DialogClose>
            <Button
              style={{ width: 'auto' }}
              type="submit"
              form="move-to-folder"
              className="purple"
            >
              Yes, move
              <MoveFilesIcon />
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
