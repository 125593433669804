import React from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { CurrentSubscriptionPlans } from './CurrentSubscriptionPlans/CurrentSubscriptionPlans';
import { CurrentSubscriptionPlanPopup } from './CurrentSubscriptionPlanPopup/CurrentSubscriptionPlanPopup';
import { ROLES } from 'src/constants/roles';
import { useGetUsersMeQuery } from 'src/services/usersService';
import { WarningPopover } from 'src/components/WarningPopower/WarningPopover';
import { ChargeSetupFeePopup } from './ChargeSetupFeePopup/ChargeSetupFeePopup';

export const CurrentSubscriptionPlansSection = ({ infoOnly = false }) => {
  const { data: userInfo } = useGetUsersMeQuery();

  const isCosupportManager = userInfo?.role === ROLES.COSSUPORT_MANAGER;

  return (
    <CardContainer className="integration">
      <div className="card-header z-50">
        <div className="title cursor-default">
          <span className="!no-underline max-md:hidden">Current subscription plan</span>
        </div>
        {!infoOnly && (
          <div className="relative flex md:gap-2.5 gap-2">
            <div className="add-button-container relative flex md:gap-2.5 gap-2">
              <ChargeSetupFeePopup disabled={!isCosupportManager} />
              {!isCosupportManager && (
                <WarningPopover triggerClassName="absolute -right-2 -top-2" fill="black" />
              )}
            </div>
            <div className="add-button-container relative flex md:gap-2.5 gap-2">
              <CurrentSubscriptionPlanPopup disabled={!isCosupportManager} />
              {!isCosupportManager && (
                <WarningPopover triggerClassName="absolute -right-2 -top-2" fill="black" />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="card-body">
        <CurrentSubscriptionPlans infoOnly={infoOnly} />
      </div>
    </CardContainer>
  );
};
