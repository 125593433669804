import './Breadcrumbs.css';
import React, { useState, useEffect } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { BreadcrumbItem } from './BreadcrumbsItem';
import { useGetAllProjectsQuery } from '../../services';
import { clsx } from 'clsx';

const ProjectBreadcrumb = ({ match, isProjectBreadcrumbs = true, projectBreadcrumbsContent }) => {
  const { data: projects } = useGetAllProjectsQuery();
  const [project, setProject] = useState(null);

  useEffect(() => {
    const currentProject = projects && projects.find((project) => project.id === +match.params.id);

    if (currentProject) {
      setProject(currentProject);
    }
  }, [projects]);

  return (
    project &&
    isProjectBreadcrumbs && (
      <>
        <BreadcrumbItem>
          <span title={project.name} className="line-clamp-1">
            {project.name}
          </span>
          <span
            title={project.is_active ? 'In Work' : 'On Hold'}
            className={clsx(
              'badge text-nowrap md:text-[10px] text-[0px] leading-[4px] md:leading-[19px] py-[2px] px-[4px] md:px-[14px]',
              project.is_active && 'inwork',
              !project.is_active && 'onhold',
            )}
          >
            {project.is_active ? 'In Work' : 'On Hold'}
          </span>
        </BreadcrumbItem>
        {projectBreadcrumbsContent && (
          <BreadcrumbItem>
            <span>{projectBreadcrumbsContent}</span>
          </BreadcrumbItem>
        )}
      </>
    )
  );
};

export const Breadcrumbs = ({ projectBreadcrumbsContent, isProjectBreadcrumbs }) => {
  const breadcrumbs = useBreadcrumbs([
    {
      path: '/',
      breadcrumb: null,
    },
    {
      path: '/dashboard',
      breadcrumb: null,
    },
    {
      path: '/dashboard/account',
      breadcrumb: () => <BreadcrumbItem>Account</BreadcrumbItem>,
    },
    {
      path: '/dashboard/analytics',
      breadcrumb: () => <BreadcrumbItem>Analytics</BreadcrumbItem>,
    },
    {
      path: '/dashboard/projects',
      breadcrumb: () => <BreadcrumbItem>Project</BreadcrumbItem>,
    },
    {
      path: '/dashboard/projects/new',
      breadcrumb: () => <BreadcrumbItem>New Project</BreadcrumbItem>,
    },
    {
      path: '/dashboard/projects/:id',
      breadcrumb: (props) => (
        <ProjectBreadcrumb
          match={props.match}
          projectBreadcrumbsContent={projectBreadcrumbsContent}
          isProjectBreadcrumbs={isProjectBreadcrumbs}
        />
      ),
    },
  ]);

  return <React.Fragment>{breadcrumbs.map(({ breadcrumb }) => breadcrumb)}</React.Fragment>;
};
