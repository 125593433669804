export const AddIcon = ({ strokeColor = '#05050D', className = '' }) => {
  return (
    <svg
      className={className}
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.50001 11L5.50001 -4.22588e-07" stroke={strokeColor} strokeWidth="2" />
      <line x1="1.60275e-07" y1="5.5" x2="11" y2="5.5" stroke={strokeColor} strokeWidth="2" />
    </svg>
  );
};
