import { Controller, useForm } from 'react-hook-form';
import Input from 'src/components/Input/Input';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { booleanScheme, stringScheme } from 'src/utils/validation-schemes';
import { AnalyticsDateRangePopup } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsDateRangePopup/AnalyticsDateRangePopup';
import moment from 'moment';

const FORMAT = 'YYYY-MM-DD';
const initialCustomDate = {
  from: moment().toDate(),
  to: moment().toDate(),
};

export const AnalyticsDate = ({ onDateChange, selectOptions }) => {
  const [customDateDefault, setCustomDateDefault] = React.useState(initialCustomDate);
  const [openCustomPopup, setOpenCustomPopup] = React.useState(false);
  const {
    control,
    trigger,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      date: selectOptions[0],
    },
    resolver: yupResolver(
      object({
        date: object({
          label: stringScheme({ required: true }),
          disabled: booleanScheme(),
        }),
      }),
    ),
  });

  const handleDateChange = (e) => {
    if (e.label === 'Custom') {
      return setOpenCustomPopup(true);
    }
    setCustomDateDefault(initialCustomDate);
    e.label && onDateChange && onDateChange(e);
  };

  const submitCustomDateRange = ({ date }) => {
    const start_time = moment(date.from).startOf('day').format(FORMAT);
    const end_time = moment(date.to).endOf('day').format(FORMAT);

    setCustomDateDefault(date);
    onDateChange && onDateChange({ label: 'Custom', start_time, end_time });
  };

  return (
    <div className={'input-container md:min-w-52 text-[.88rem] min-w-[140px]'}>
      <Controller
        control={control}
        name="date"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            type={'select'}
            selectOptions={selectOptions}
            invalidMessage={error?.message}
            value={value}
            isValid={!error?.message}
            onChange={(e) => {
              onChange(e);
              handleDateChange(e, value);
            }}
            onBlur={() => trigger('date')}
          />
        )}
      />
      <AnalyticsDateRangePopup
        open={openCustomPopup}
        setOpen={setOpenCustomPopup}
        dateDefault={customDateDefault}
        submitDateFilter={submitCustomDateRange}
      />
    </div>
  );
};
