import { baseApi } from './base.api';
import { PATH_ANALYTICS_API } from 'src/constants/api-routes';
import moment from 'moment/moment';

export const formatDuration = (period = 0, unit = 'seconds') => {
  let parts = [];
  const duration = moment.duration(period, unit);

  if (!duration || duration.toISOString() === 'P0D') return;

  if (duration.years() >= 1) {
    const years = Math.floor(duration.years());
    parts.push(`${years} y`);
  }

  if (duration.months() >= 1) {
    const months = Math.floor(duration.months());
    parts.push(`${months} m`);
  }

  if (duration.days() >= 1) {
    const days = Math.floor(duration.days());
    parts.push(`${days} d`);
  }

  if (duration.hours() >= 1) {
    const hours = Math.floor(duration.hours());
    parts.push(`${hours} h`);
  }

  if (duration.minutes() >= 1) {
    const minutes = Math.floor(duration.minutes());
    parts.push(`${minutes} min`);
  }

  if (duration.seconds() >= 1) {
    const seconds = Math.floor(duration.seconds());
    parts.push(`${seconds} sec`);
  }

  return parts.join(' ');
};

export const coCustomerAnalyticsService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCoCustomerTimeSeries: build.query({
      query: ({
        project_ids = '',
        start_time = '',
        end_time = '',
        group_by = '',
        tz_offset = '',
      }) => ({
        url: `${PATH_ANALYTICS_API.COCUSTOMER.TIME_SERIES}`,
        params: {
          ...(project_ids !== '' && { project_ids }),
          ...(start_time !== '' && { start_time }),
          ...(end_time !== '' && { end_time }),
          ...(group_by !== '' && { group_by }),
          ...(tz_offset !== '' && { tz_offset }),
        },
      }),
      transformResponse(response, meta, arg) {
        const { group_by } = arg;

        return response
          .map((item) => {
            const resolvedTickets = item.total_resolved_chats;
            const escalatedTickets = item.total_escalated_chats || 0;
            const inProgressTickets = item.total_in_progress;
            const aiRepliesSent = item.total_ai_responses;
            const aiRepliesPerProcessedTicket = item.ai_replies_per_processed_chat_avg || 0;
            const firstResponseTimeAvg_min = item.first_response_time_avg
              ? item.first_response_time_avg / 60
              : 0;
            const firstResponseTimeAvg_hr = item.first_response_time_avg
              ? item.first_response_time_avg / 3600
              : 0;
            const firstResponseTimeAvg_sec = item.first_response_time_avg || 0;
            const responseTimeAvg_min = item.response_time_avg ? item.response_time_avg / 60 : 0;
            const responseTimeAvg_hr = item.response_time_avg ? item.response_time_avg / 3600 : 0;
            const responseTimeAvg_sec = item.response_time_avg || 0;
            const resolutionTimeAvg_min = item.resolution_time_avg
              ? item.resolution_time_avg / 60
              : 0;
            const resolutionTimeAvg_hr = item.resolution_time_avg
              ? item.resolution_time_avg / 3600
              : 0;
            const resolutionTimeAvg_sec = item.resolution_time_avg || 0;
            const totalProcessedTickets = item.total_processed_chats;
            const resolutionRate = item.resolution_rate || 0;

            return {
              ...item,
              resolutionRate,
              totalProcessedTickets,
              resolvedTickets,
              escalatedTickets,
              inProgressTickets,
              aiRepliesSent,
              aiRepliesPerProcessedTicket,
              firstResponseTimeAvg_sec,
              firstResponseTimeAvg_min,
              firstResponseTimeAvg_hr,
              responseTimeAvg_sec,
              responseTimeAvg_min,
              responseTimeAvg_hr,
              resolutionTimeAvg_sec,
              resolutionTimeAvg_min,
              resolutionTimeAvg_hr,
              formattedGroup:
                group_by === 'hour'
                  ? moment(item.grouped_by).format('hh:mm A')
                  : moment(item.grouped_by).format('ll'),
            };
          })
          .sort((a, b) => {
            return moment(a.grouped_by).diff(moment(b.grouped_by));
          });
      },
      providesTags: ['CoCustomerAnalyticsTimeSeries'],
    }),
    getCoCustomerAggregated: build.query({
      query: ({ changes_percentage = '', start_time = '', end_time = '', project_ids = '' }) => ({
        url: `${PATH_ANALYTICS_API.COCUSTOMER.AGGREGATED}`,
        params: {
          ...(changes_percentage !== '' && { changes_percentage }),
          ...(start_time !== '' && { start_time }),
          ...(end_time !== '' && { end_time }),
          ...(project_ids !== '' && { project_ids }),
        },
      }),
      transformResponse(response) {
        const statistics = response.statistics;
        const percentages = response.changes_percentages;
        const resolvedTickets = {
          value: statistics.total_resolved_chats || 0,
          percentage: percentages?.total_resolved_chats,
        };
        const escalatedTickets = {
          value: statistics.total_escalated_chats || 0,
          percentage: percentages?.total_escalated_chats,
        };
        const inProgressTickets = {
          value: statistics.total_in_progress,
          percentage: percentages?.total_in_progress,
        };
        const aiRepliesSent = {
          value: statistics.total_ai_responses,
          percentage: percentages?.total_ai_responses,
        };
        const aiRepliesPerProcessedTicket = {
          value: statistics.ai_replies_per_processed_chat_avg || 0,
          percentage: percentages?.ai_replies_per_processed_chat_avg || 0,
        };
        const firstResponseTimeAvg = {
          value: formatDuration(statistics.first_response_time_avg),
          percentage: percentages?.first_response_time_avg,
        };
        const responseTimeAvg = {
          value: formatDuration(statistics.response_time_avg),
          percentage: percentages?.response_time_avg,
        };
        const resolutionTimeAvg = {
          value: formatDuration(statistics.resolution_time_avg),
          percentage: percentages?.resolution_time_avg,
        };
        const totalProcessedTickets = {
          value: statistics.total_processed_chats,
          percentage: percentages?.total_processed_chats,
        };
        const resolutionRate = {
          value: statistics.resolution_rate || 0,
          percentage: percentages?.resolution_rate || 0,
        };

        return {
          response,
          resolutionRate,
          totalProcessedTickets,
          resolvedTickets,
          escalatedTickets,
          inProgressTickets,
          aiRepliesSent,
          aiRepliesPerProcessedTicket,
          firstResponseTimeAvg,
          responseTimeAvg,
          resolutionTimeAvg,
        };
      },
      providesTags: ['CoCustomerAnalyticsAggregated'],
    }),
    getCoCustomerChatsCount: build.query({
      query: ({ project_ids = '', statuses = '' }) => ({
        url: `${PATH_ANALYTICS_API.COCUSTOMER.CHATS_COUNT}`,
        params: {
          ...(project_ids !== '' && { project_ids }),
          ...(statuses !== '' && { statuses }),
        },
      }),
      providesTags: ['CoCustomerAnalyticsChatsCount'],
    }),
  }),
});

export const {
  useLazyGetCoCustomerTimeSeriesQuery,
  useLazyGetCoCustomerAggregatedQuery,
  useGetCoCustomerChatsCountQuery,
} = coCustomerAnalyticsService;
