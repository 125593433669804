import { clsx } from 'clsx';
import React from 'react';

export const AnalyticsTabsDiffs = ({ diff, oppositTickers }) => {
  const isPositive = oppositTickers ? diff < 0 : diff > 0;

  return (
    <>
      {typeof diff !== 'undefined' && diff !== null && (
        <div
          className={clsx(
            'text-xs font-semibold flex flex-row items-center',
            isPositive ? 'text-[#06A2AC]' : 'text-[#D40A0A]',
          )}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              {isPositive ? (
                <path d="M8.5 4L12.3971 10.75H4.60289L8.5 4Z" fill="#06A2AC" />
              ) : (
                <path d="M8.5 12L4.60289 5.25H12.3971L8.5 12Z" fill="#D40A0A" />
              )}
            </svg>
          </div>
          {diff}%
        </div>
      )}
    </>
  );
};
