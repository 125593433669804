import React, { useEffect, useRef, useState } from 'react';
import Button from '../Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';
import { EditUserForm } from './EditUserForm';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useUpdateUserMutation } from 'src/services/usersService';
import {
  baseApi,
  useAddProjectTeamMembersMutation,
  useDeleteProjectTeamMembersMutation,
} from 'src/services';
import { ProductsTypeBack } from 'src/constants/products';
import { useAppDispatch } from 'src/store';

export const EditUserPopup = ({ user, disabled = false, onOpenChange }) => {
  const contentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const loader = useLoader();
  const [
    updateUserInfoById,
    {
      isSuccess: isSuccessUpdateUserInfoById,
      isError: isErrorUpdateUserInfoById,
      error: errorUpdateUserInfoById,
      isLoading: isLoadingUpdateUserInfoById,
    },
  ] = useUpdateUserMutation();

  const [
    addTeamMember,
    {
      data: addTeamMemberRes,
      error: errorAddTeamMember,
      isError: isErrorAddTeamMember,
      isLoading: isLoadingAddTeamMember,
      isSuccess: isSuccessAddTeamMember,
    },
  ] = useAddProjectTeamMembersMutation();

  const [
    deleteTeamMember,
    {
      isSuccess: isSuccessDeleteTeamMember,
      isError: isErrorDeleteTeamMember,
      error: errorDeleteTeamMember,
      isLoading: isLoadingDeleteTeamMember,
    },
  ] = useDeleteProjectTeamMembersMutation();

  useEffect(() => {
    if (isSuccessUpdateUserInfoById) {
      notification.success('User Updated.');
    }
  }, [isSuccessUpdateUserInfoById]);

  useEffect(() => {
    if (isSuccessDeleteTeamMember) {
      notification.success('Team Member Deleted.');
    }
  }, [isSuccessDeleteTeamMember]);

  useEffect(() => {
    if (isSuccessAddTeamMember) {
      notification.success(`Team Member ${addTeamMemberRes.user.first_name} was added.`);
    }
  }, [isSuccessAddTeamMember]);

  useEffect(() => {
    if (isErrorDeleteTeamMember) {
      notification.error(getErrorMessage(errorDeleteTeamMember));
    }
    if (isErrorUpdateUserInfoById) {
      notification.error(getErrorMessage(errorUpdateUserInfoById));
    }
    if (isErrorAddTeamMember) {
      notification.error(getErrorMessage(errorAddTeamMember));
    }
  }, [isErrorDeleteTeamMember, isErrorUpdateUserInfoById, isErrorAddTeamMember]);

  useEffect(() => {
    if (isLoadingDeleteTeamMember || isLoadingUpdateUserInfoById || isLoadingAddTeamMember) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingDeleteTeamMember, isLoadingUpdateUserInfoById, isLoadingAddTeamMember]);

  useEffect(() => {
    let timeout;
    contentRef?.current && onOpenChange && onOpenChange(open);

    if (open) {
      timeout = setTimeout(() => {
        document.activeElement.blur();
      }, 0);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [open]);

  const submit = (formData) => {
    const promises = [];
    const { selectedProjects, ...data } = formData;
    const projectsToAdd = selectedProjects.filter((selectedProject) => {
      return (
        user.projects && !user.projects.find((userProject) => userProject.id === selectedProject.id)
      );
    });
    const projectsToDelete =
      user.projects &&
      user.projects.filter((userProject) => {
        return !selectedProjects.find((selectedProject) => userProject.id === selectedProject.id);
      });

    if (Object.keys(data).length) {
      const updateUserInfoPromise = updateUserInfoById({ userId: user.id, body: data });

      promises.push(updateUserInfoPromise);
    }

    if (projectsToAdd && projectsToAdd.length) {
      for (let i = 0; i < projectsToAdd.length; i++) {
        const { id, project_type } = projectsToAdd[i];
        const addTeamMemberPromise = addTeamMember({
          id,
          type: ProductsTypeBack[project_type],
          user: user.id,
        });

        promises.push(addTeamMemberPromise);
      }
    }

    if (projectsToDelete && projectsToDelete.length) {
      for (let i = 0; i < projectsToDelete.length; i++) {
        const { id, project_type, team_member_id } = projectsToDelete[i];

        if (team_member_id) {
          const deleteTeamMemberPromise = deleteTeamMember({
            id,
            type: ProductsTypeBack[project_type],
            teamMemberId: team_member_id,
          });

          promises.push(deleteTeamMemberPromise);
        }
      }
    }

    Promise.all(promises)
      .then(() => dispatch(baseApi.util.invalidateTags(['UsersAll'])))
      .then(() => setOpen(false));
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'outline-0 cursor-pointer')}
      >
        <button className={'user-settings-button'}>Edit</button>
      </DialogTrigger>
      <DialogContent ref={contentRef}>
        <DialogHeader>
          <DialogTitle>Edit user information</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {user && <EditUserForm user={user} onSubmit={(formData) => submit(formData)} />}
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button style={{ width: 'auto' }} type="submit" form="edit-user-form" className="blue">
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
