export const AgentCoSupportIcon = ({ height = '25', width = '25', className = '' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.2C0 1.43269 1.43269 0 3.2 0H20.8C22.5673 0 24 1.43269 24 3.2V20.8C24 22.5673 22.5673 24 20.8 24H3.2C1.43269 24 0 22.5673 0 20.8V3.2Z"
        fill="#75F3FB"
      />
      <path
        d="M9.09747 7.4484C7.89832 7.4484 6.7191 7.92815 5.87117 8.78211C5.02324 9.63607 4.54687 10.7943 4.54687 12.002C4.54687 13.2097 5.02324 14.3679 5.87117 15.2218C6.7191 16.0758 7.89832 16.5555 9.09747 16.5555L9.09747 12.002L9.09747 7.4484Z"
        fill="#05050D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.908 16.5556C17.4212 16.5556 19.4586 14.5169 19.4586 12.002C19.4586 9.48713 17.4212 7.44843 14.908 7.44843C12.3948 7.44843 10.3574 9.48713 10.3574 12.002C10.3574 14.5169 12.3948 16.5556 14.908 16.5556ZM14.8969 13.8626C15.9251 13.8626 16.7585 13.0286 16.7585 11.9998C16.7585 10.971 15.9251 10.137 14.8969 10.137C13.8688 10.137 13.0353 10.971 13.0353 11.9998C13.0353 13.0286 13.8688 13.8626 14.8969 13.8626Z"
        fill="#05050D"
      />
    </svg>
  );
};
