import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { Button as ButtonShad } from 'src/components/ui/button';
import Button from 'src//components/Button/Button';
import React, { useEffect, useState } from 'react';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { EditIcon } from 'src/assets/icons/editIcon';
import { baseApi, useGetAllProjectsQuery, useUpdateProjectMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { useDispatch } from 'react-redux';

export const ChangeAiModelModal = ({ disabled, currentProject }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const notification = useNotification();
  const dispatch = useDispatch();

  const [
    updateProject,
    { isLoading, isError: isUpdateProjectError, error: updateProjectError, isSuccess },
  ] = useUpdateProjectMutation();

  const { data: projects = [] } = useGetAllProjectsQuery();

  useEffect(() => {
    if (isSuccess) {
      setIsDialogOpen(false);
      notification.success('AI-model was changed!');
    }
  }, [isLoading, isSuccess]);

  useEffect(() => {
    if (isUpdateProjectError) {
      notification.error(getErrorMessage(updateProjectError));
    }
  }, [isUpdateProjectError]);

  const projectsByType = projects?.reduce(
    (acc, project) => {
      if (!acc[project?.project_type]) {
        acc[project.project_type] = [];
      }
      acc[project?.project_type].push(project);
      return acc;
    },
    { agent: [], bi: [], customer: [] },
  );

  const { setValue, handleSubmit, control, watch } = useForm({
    defaultValues: {
      projectId: currentProject?.cocbi_project_id,
    },
    resolver: yupResolver(
      object({
        projectId: stringScheme({ required: false }).label('Project'),
      }),
    ),
  });
  const selectedAccountId = watch('projectId');

  const dialogOpenHandler = (open) => {
    setIsDialogOpen(open);
    if (open) {
      setValue('projectId', Number(currentProject?.cocbi_project_id));
    }
  };

  const submit = (data) => {
    updateProject({
      name: currentProject.name,
      projectId: currentProject.id,
      ai_reply_generation: currentProject.ai_reply_generation,
      ai_text_lense: currentProject.ai_text_lense,
      cocbi_project_id: Number(data.projectId),
      use_cocbi_model: Boolean(Number(data.projectId)),
    }).then(() => dispatch(baseApi.util.invalidateTags(['ProjectById'])));
  };

  const disableCondition = Number(currentProject?.cocbi_project_id) === Number(selectedAccountId);

  return (
    <Dialog onOpenChange={dialogOpenHandler} open={isDialogOpen}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <ButtonShad
          variant="ghost"
          size="icon"
          className={clsx(disabled && 'pointer-events-none opacity-50')}
        >
          <EditIcon />
        </ButtonShad>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Change AI-model</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <p className="opacity-50 font-medium text-xs"> All available AI-models</p>
          <form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit(submit)();
              }
            }}
            onSubmit={handleSubmit(submit)}
            id="model-form"
            className="bg-white p-5 rounded-lg border border-[#B8C1C666]"
          >
            <Controller
              control={control}
              name="projectId"
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  onChange={onChange}
                  onValueChange={onChange}
                  defaultValue={value}
                  className="flex flex-col space-y-1"
                >
                  <label
                    htmlFor="reset"
                    className="flex items-center space-x-5 space-y-0 border rounded-lg px-5 py-4 cursor-pointer hover:border-black transition-colors"
                  >
                    <RadioGroupItem value={0} id="reset" />
                    <label htmlFor="reset" className="cursor-pointer">
                      <p className="font-medium text-base text-ebony opacity-70 hover:opacity-100 transition">
                        Default
                      </p>
                    </label>
                  </label>
                  {projectsByType.customer.map((project) => (
                    <label
                      htmlFor={project.name}
                      key={project.id}
                      className="flex items-center space-x-5 space-y-0 border rounded-lg px-5 py-4 cursor-pointer hover:border-black transition-colors"
                    >
                      <RadioGroupItem value={project.id} id={project.name} />
                      <label htmlFor={project.name} className="cursor-pointer">
                        <p className="font-medium text-base text-ebony opacity-70 hover:opacity-100 transition">
                          {project.name}
                        </p>
                      </label>
                    </label>
                  ))}
                </RadioGroup>
              )}
            />
          </form>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button className="transparent black" style={{ width: 'auto' }}>
              Cancel
            </Button>
          </DialogClose>
          <Button
            disabled={isLoading || disableCondition}
            type="submit"
            form="model-form"
            className="blue"
            style={{ width: 'auto' }}
          >
            {isLoading ? 'Loading...' : 'Change'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
