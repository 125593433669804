import React, { useEffect } from 'react';
import Button from '../../Button/Button';
import { useNotification } from 'src/hooks/useNotification';
import {
  useActivateProjectMutation,
  useDeactivateProjectMutation,
  useGetProjectByIdQuery,
} from 'src/services';
import { useParams } from 'react-router-dom';
import { getErrorMessage } from 'src/utils/get-error-message';

export const ProjectActivateContent = ({ onlyForm = false, close = () => {} }) => {
  const notification = useNotification();
  const { id } = useParams();

  const { data: currentProject } = useGetProjectByIdQuery({ id: id });

  const isActive = currentProject?.is_active;

  const [
    activateProject,
    { isLoading, isError: isActivateProjectError, error: activateProjectError, isSuccess },
  ] = useActivateProjectMutation();

  const [
    deactivateProject,
    {
      isLoading: deactivateProjectIsLoading,
      isError: isDeactivateProjectError,
      error: deactivateProjectError,
      isSuccess: deactivateProjectIsSuccess,
    },
  ] = useDeactivateProjectMutation();

  useEffect(() => {
    if (isSuccess) {
      close();
      notification.success('Project has been activated!');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (deactivateProjectIsSuccess) {
      close();
      notification.success('Project has been deactivated!');
    }
  }, [deactivateProjectIsSuccess]);

  useEffect(() => {
    if (isActivateProjectError) {
      notification.error(getErrorMessage(activateProjectError));
    }
  }, [isActivateProjectError]);

  useEffect(() => {
    if (isDeactivateProjectError) {
      notification.error(getErrorMessage(deactivateProjectError));
    }
  }, [isDeactivateProjectError]);

  const projectActivateHandler = () => {
    if (isActive) {
      deactivateProject({ projectId: id });
    } else {
      activateProject({ projectId: id });
    }
  };

  return (
    <>
      <div className="content">
        <div className="sub-title flex flex-col gap-1.5">
          <h4>Are you sure you want to {isActive ? 'deactivate' : 'activate'} this project?.</h4>
        </div>
      </div>
      <div className="footer flex justify-end mt-5">
        <div className="help-container"></div>
        <div className="action-buttons flex gap-2.5">
          {!onlyForm && (
            <Button className="transparent black min-w-28" onClick={() => close()}>
              Cancel
            </Button>
          )}
          <Button
            onClick={projectActivateHandler}
            disabled={isLoading || deactivateProjectIsLoading}
            className="blue min-w-28"
          >
            {isLoading || deactivateProjectIsLoading
              ? 'Loading...'
              : isActive
                ? 'Deactivate'
                : 'Activate'}
          </Button>
        </div>
      </div>
    </>
  );
};
