import './AnalyticsCustomerPopup.css';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { CloseAnalyticsCustomerPopupIcon } from 'src/pages/AnalyticsPage/AnalyticsCustomer/AnalyticsCustomerPopup/CloseAnalyticsCustomerPopupIcon';
import { DateItems } from 'src/pages/AnalyticsPage/AnalyticsCustomer/DateItems';
import { useParams } from 'react-router-dom';
import { useLazyGetCoCustomerTimeSeriesQuery } from 'src/services';
import { AnalyticsCustomerLineChartData } from 'src/pages/AnalyticsPage/AnalyticsCustomer/AnalyticsCustomerLineChart/AnalyticsCustomerLineChart';
import { AnalyticsCustomerBarChartData } from 'src/pages/AnalyticsPage/AnalyticsCustomer/AnalyticsCustomerBarChart/AnalyticsCustomerBarChart';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useNotification } from 'src/hooks/useNotification';
import { useLoader } from 'src/provider/LoaderProvider';

export const AnalyticsCustomerPopup = ({
  disabled = false,
  title = '',
  dataKey = '',
  className = '',
  dateRange,
  chartType,
}) => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const notification = useNotification();
  const loader = useLoader();
  const [timeSeriesRequest, setTimeSeriesRequest] = useState({
    project_ids: [id],
    start_time: DateItems[0].getStartTime(),
    end_time: DateItems[0].getEndTime(),
    group_by: DateItems[0].groupBy,
    // tz_offset: moment().utcOffset(),
  });
  const [timeSeriesResponse, setTimeSeriesResponse] = useState([]);
  const [fetchTimeSeriesQuery, { data: timeSeriesData, isFetching, isError, error }] =
    useLazyGetCoCustomerTimeSeriesQuery();

  useEffect(() => {
    if (isFetching) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isFetching]);

  useEffect(() => {
    if (isError) {
      notification.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (!dateRange) {
      return;
    }
    const isCustom = dateRange.label === 'Custom';
    const dateItem = DateItems.find((item) => item.label === dateRange.label);

    setTimeSeriesRequest({
      ...timeSeriesRequest,
      start_time: isCustom ? dateRange.start_time : dateItem.getStartTime(),
      end_time: isCustom ? dateRange.end_time : dateItem.getEndTime(),
      group_by: dateItem.groupBy,
    });
  }, [dateRange]);

  useEffect(() => {
    if (open && timeSeriesRequest) {
      fetchTimeSeriesQuery(timeSeriesRequest);
    }
  }, [open, timeSeriesRequest]);

  useEffect(() => {
    if (timeSeriesData && timeSeriesData.length) {
      setTimeSeriesResponse(timeSeriesData);
    }
  }, [timeSeriesData]);

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <div className="bg-[#fff] p-[9px] rounded-[4px] cursor-pointer icon-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M13.3334 9.81667L16.8667 3.70833L18.3083 4.54167L13.95 12.0833L8.52502 8.95833L4.55002 15.8333H18.3334V17.5H1.66669V2.5H3.33335V14.6167L7.91669 6.66667L13.3334 9.81667Z"
              fill="#05050D"
              fillOpacity="0.5"
            />
          </svg>
        </div>
      </DialogTrigger>
      <DialogContent closeButtonHidden={true} className={clsx(className, '!max-w-[90vw]')}>
        <DialogHeader className={'flex-row items-center justify-between'}>
          <DialogTitle className="text-start">
            <div className={'flex flex-row gap-[8px] items-center justify-center'}>
              <span>{title}</span>
              <span
                className={
                  'rounded-[50px] bg-white shadow-utc px-[14px] py-[4px] text-ebony text-sm'
                }
              >
                UTC
              </span>
            </div>
          </DialogTitle>
          <DialogClose
            className={'!m-0 rounded-[4px] bg-[#fff] p-[7px] analytics-popup-close-icon'}
          >
            <CloseAnalyticsCustomerPopupIcon />
          </DialogClose>
        </DialogHeader>
        <DialogFooter className="max-md:flex-row max-md:gap-2.5 bg-white rounded-[7px]">
          <div className={'w-full h-full'}>
            {chartType === 'lineChart' && (
              <AnalyticsCustomerLineChartData
                title={title}
                data={timeSeriesResponse}
                dataKey={dataKey}
                groupBy={timeSeriesRequest.group_by}
              />
            )}
            {chartType === 'barChart' && (
              <AnalyticsCustomerBarChartData
                title={title}
                data={timeSeriesResponse}
                dataKey={dataKey}
                groupBy={timeSeriesRequest.group_by}
              />
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
