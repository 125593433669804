import { Controller, useForm } from 'react-hook-form';
import Input from 'src/components/Input/Input';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { booleanScheme, stringScheme } from 'src/utils/validation-schemes';

export const AnalyticsTeamMultiSelect = ({ onClose, values, onChange, options }) => {
  const {
    control,
    trigger,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      team: [options[0]],
    },
    resolver: yupResolver(
      object({
        team: object({
          label: stringScheme({ required: true }),
          disabled: booleanScheme(),
        }),
      }),
    ),
  });

  return (
    <div className={'input-container md:min-w-52 text-[.88rem]'}>
      <Controller
        control={control}
        name="team"
        render={({ field: { value }, fieldState: { error } }) => (
          <Input
            type={'multi-select'}
            selectOptions={options}
            invalidMessage={error?.message}
            value={values}
            isValid={!error?.message}
            onChange={(selectedOptions, option, checked) => {
              let options = [];
              if (checked && option.label === 'Team') {
                options = [option];
              } else {
                options = selectedOptions.filter((opt) => opt.label !== 'Team');
              }
              onChange && onChange(options);
            }}
            onClose={onClose}
            onBlur={() => trigger('team')}
          />
        )}
      />
    </div>
  );
};
