import React, { useEffect } from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { AddPaymentMethodPopup } from './AddPaymentMethodPopup/AddPaymentMethodPopup';
import { useGetPaymentMethodsQuery } from 'src/services';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';
import { useNotification } from 'src/hooks/useNotification';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useLoader } from 'src/provider/LoaderProvider';
import { useAppSelector } from 'src/store';

export const PaymentMethodCard = () => {
  const notification = useNotification();
  const loader = useLoader();

  const { companyChanged } = useAppSelector((s) => s.companySlices);

  const {
    data: paymentMethods,
    error: paymentMethodsError,
    isError: paymentMethodsIsError,
    isFetching: paymentMethodsIsFetching,
  } = useGetPaymentMethodsQuery({}, { refetchOnMountOrArgChange: companyChanged });

  useEffect(() => {
    if (paymentMethodsIsFetching) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [paymentMethodsIsFetching]);

  useEffect(() => {
    if (paymentMethodsIsError) {
      notification.error(getErrorMessage(paymentMethodsError));
    }
  }, [paymentMethodsIsError]);

  const defaultPaymentMethod = paymentMethods?.data.find((paymentMethod) => paymentMethod.default);

  return (
    <CardContainer className={`half-width`}>
      <div className="card-header">
        <div className="title">
          <p className="text-ebony font-bold text-sm">Payment method</p>
        </div>
        <div className="edit-button-container">
          <AddPaymentMethodPopup
            paymentMethods={paymentMethods}
            disabled={paymentMethodsIsFetching}
          />
        </div>
      </div>
      <div className="card-body  h-full py-1">
        <div
          className={
            'input-container full-width border rounded-[7px] h-full px-4 py-3.5 flex justify-between items-center'
          }
        >
          <LoaderWrapper
            loader={
              <div className="flex justify-between w-full items-end">
                <Skeleton className="h-4 w-full" />
              </div>
            }
            isLoading={paymentMethodsIsFetching}
          >
            {defaultPaymentMethod ? (
              <p className="font-medium text-base text-ebony opacity-70">
                <span className="capitalize">{defaultPaymentMethod.brand}</span> card
                <span> ****{defaultPaymentMethod.last4}</span>
              </p>
            ) : (
              <p className="font-medium text-base text-ebony opacity-70">
                No default payment methods.
              </p>
            )}
          </LoaderWrapper>
        </div>
      </div>
    </CardContainer>
  );
};
