import moment from 'moment/moment';

const constants = {
  accuracy: 'Accuracy',
  resolved_with_ai: 'Requests Resolved with AI',
  subdomain_resolved_with_ai: 'Requests Resolved with AI',
  bad_suggestions: 'Bad Suggestions Reported',
  subdomain_bad_suggestions: 'Bad Suggestions Reported',
  response_time: 'Average Response Time',
  subdomain_response_time: 'Average Response Time',
  first_response_time: 'Average First Response Time',
  subdomain_first_response_time: 'Average First Response Time',
  initialRequest: {
    project_id: null,
    date_range: 'day',
    time_axis: 'minutes',
    start_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    end_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    metrics: [
      // 'first_response_time',
      // 'response_time',
      // 'resolved_with_ai',
      // 'bad_suggestions',
      'accuracy',
      'subdomain_response_time',
      'subdomain_bad_suggestions',
      'subdomain_first_response_time',
      'subdomain_resolved_with_ai',
    ],
    agents: [],
    data_visualization: 'linear',
  },
  personal_metrics: [
    'accuracy',
    'first_response_time',
    'response_time',
    'resolved_with_ai',
    'bad_suggestions',
  ],
  subdomain_metrics: [
    'accuracy',
    'subdomain_response_time',
    'subdomain_bad_suggestions',
    'subdomain_first_response_time',
    'subdomain_resolved_with_ai',
  ],
  colors: [
    '#6257DA',
    '#F01C42',
    '#FFF500',
    '#00AE84',
    '#FF05C8',
    '#1400FF',
    '#FFB72C',
    '#33F932',
    '#AA20FF',
    '#9A7B4D',
    '#8D8B89',
    '#A5DF00',
    '#FF9CB3',
    '#009FD1',
    '#F86D1F',
  ],
};

export default constants;
