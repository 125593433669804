import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import {
  FileMdIcon,
  FilePdfIcon,
  FileTxtIcon,
  UploadingFileIcon,
} from 'src/assets/icons/FileIcons';
import { getFileExtension } from 'src/utils/fileUtils';
import { Checkbox } from 'src/components/Checkbox/Checkbox';
import { FileSettingsPopover } from 'src/components/FileManager/FileSettingsPopover/FileSettingsPopover';
import { useMediaQuery } from 'src/hooks/useMediaQuery';

export const ListFileView = ({
  item,
  onClick,
  onCheckedChange,
  className,
  checkboxVisible,
  handleSingleItemMove,
  handleSingleItemRename,
  handleSingleItemDelete,
  ...props
}) => {
  const isLgDown = useMediaQuery('(max-width: 992px)');
  const ext = getFileExtension(item.name);
  const [checked, setChecked] = useState(Boolean(item.checked));
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    item.checked = event.target.checked;
    onCheckedChange && onCheckedChange(item);
  };

  useEffect(() => {
    if (!checkboxVisible) {
      setChecked(false);
      item.checked = false;
    }
  }, [checkboxVisible]);

  useEffect(() => {
    setChecked(item.checked);
  }, [item, item.checked]);

  return (
    <>
      <div
        className={clsx(
          className,
          item.disabled && 'disabled',
          item.checked && 'checked',
          'file-list-item file',
        )}
        onClick={onClick}
        {...props}
      >
        <div className={'file-list-name pointer-events-none'}>
          <div
            className={clsx(
              item.root
                ? 'invisible pointer-events-none'
                : checkboxVisible
                  ? 'pointer-events-auto'
                  : 'invisible pointer-events-none',
              'file-list-checkbox',
            )}
            onDoubleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Checkbox label={''} checked={checked} onChange={handleCheckboxChange} />
          </div>

          {item.state === 'uploading' ? (
            <div className="list-view-spinner"></div>
          ) : (
            <div className={'file-list-icon'}>
              {!ext && <FileTxtIcon />}
              {ext === 'pdf' && <FilePdfIcon />}
              {ext === 'md' && <FileMdIcon />}
              {ext === 'txt' && <FileTxtIcon />}
            </div>
          )}
          <div className={'file-list-text md:pl-2.5'}>
            <span className={'overflow-ellipsis'}>{item.name}</span>
            {item.updated_at && isLgDown && (
              <div className={'file-list-settings-date'}>{item.localDate}</div>
            )}
          </div>
          <div className={'file-list-settings'}>
            {item.updated_at && !isLgDown && (
              <div className={'file-list-settings-date'}>{item.localDate}</div>
            )}
            <div className={'file-list-settings-icon pointer-events-auto'}>
              {!checkboxVisible && !item.disabled && item.state !== 'uploading' && (
                <FileSettingsPopover
                  item={item}
                  moveItem={(moveToFolder) => handleSingleItemMove(moveToFolder, item)}
                  renameItem={(name) => handleSingleItemRename(name, item)}
                  deleteItem={() => handleSingleItemDelete(item)}
                />
              )}
              {item.state === 'uploading' && <UploadingFileIcon />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
