import './IntegrationSettingsPopup.css';
import React, { useState, useRef, useEffect } from 'react';
import Button from '../Button/Button';
import { IntegrationFormZendesk } from './IntegrationFormZendesk/IntegrationFormZendesk';
import { IntegrationFormSlack } from './IntegrationFormSlack/IntegrationFormSlack';
import { useNotification } from 'src/hooks/useNotification';
import { useParams } from 'react-router-dom';
import { useLoader } from 'src/provider/LoaderProvider';
import { SlackIntegrationLink } from './IntegrationFormSlack/SlackIntegrationLink';
import { IntegrationFormFreshdesk } from './IntegrationFormFreshdesk/IntegrationFormFreshdesk';
import { useDeleteIntegrationMutation, useUpdateIntegrationMutation } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { EditIcon } from 'src/assets/icons/editIcon';
import { IntegrationFormZohoSalesIqChat } from './IntegrationFormZohoSalesIqChat/IntegrationFormZohoSalesIqChat';
import { IntegrationFormZendeskChat } from './IntegrationFormZendeskChat/IntegrationFormZendeskChat';
import { IntegrationFormZendeskEmail } from './IntegrationFormZendeskEmail/IntegrationFormZendeskEmail';

const isDataEqual = (integration, data) => {
  let isEqual = true;

  if (integration.integration_type === 'slack') {
    isEqual = false;
  }

  if (integration.integration_type === 'zendesk') {
    for (let key in integration.integration_data) {
      if (data.integration_data[key] !== integration.integration_data[key]) {
        isEqual = false;
        break;
      }
    }
  }

  if (integration.integration_type === 'freshdesk') {
    for (let key in integration.integration_data) {
      if (data.integration_data[key] !== integration.integration_data[key]) {
        isEqual = false;
        break;
      }
    }
  }

  if (integration.integration_type === 'zoho_salesiq') {
    isEqual = false;
  }

  if (integration.integration_type === 'zendesk_email') {
    isEqual = false;
  }

  if (integration.integration_type === 'zendesk_chat') {
    isEqual = false;
  }

  return isEqual;
};
export const IntegrationSettingsPopup = ({ integration, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const notification = useNotification();
  const { id } = useParams();
  const [integrationType, setIntegrationType] = useState(integration.integration_type);
  const loader = useLoader();
  const popupContentRef = useRef();
  const [
    updateIntegration,
    {
      isSuccess: isSuccessUpdateIntegration,
      isError: isErrorUpdateIntegration,
      error: errorUpdateIntegration,
      isLoading: isLoadingUpdateIntegration,
    },
  ] = useUpdateIntegrationMutation();

  const submit = async (formData) => {
    const isEqual = isDataEqual(integration, formData);

    if (isEqual) {
      notification.info('Nothing changed');
      return setOpen(false);
    }

    updateIntegration({
      projectId: id,
      integrationId: formData.id,
      body: formData,
    });
  };

  useEffect(() => {
    if (isSuccessUpdateIntegration) {
      notification.success('Integration Updated.');
      setOpen(false);
    }
  }, [isSuccessUpdateIntegration]);

  useEffect(() => {
    if (isErrorUpdateIntegration) {
      notification.error(getErrorMessage(errorUpdateIntegration));
    }
  }, [isErrorUpdateIntegration]);

  useEffect(() => {
    if (isLoadingUpdateIntegration) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [isLoadingUpdateIntegration]);

  const renderForm = (popupContentRef) => {
    switch (integrationType) {
      case 'zendesk':
        return (
          <IntegrationFormZendesk
            integration={integration}
            onSubmit={(formData) => submit(formData)}
          />
        );
      case 'zendesk_chat':
        return (
          <IntegrationFormZendeskChat
            integration={integration}
            onSubmit={(formData) => submit(formData)}
          />
        );
      case 'zoho_salesiq':
        return (
          <IntegrationFormZohoSalesIqChat
            integration={integration}
            onSubmit={(formData) => submit(formData)}
          />
        );
      case 'zendesk_email':
        return (
          <IntegrationFormZendeskEmail
            integration={integration}
            onSubmit={(formData) => submit(formData)}
          />
        );
      case 'slack':
        return (
          <IntegrationFormSlack
            popupContentRef={popupContentRef}
            integration={integration}
            onSubmit={(formData) => submit(formData)}
          />
        );
      case 'freshdesk':
        return (
          <IntegrationFormFreshdesk
            popupContentRef={popupContentRef}
            integration={integration}
            onSubmit={(formData) => submit(formData)}
          />
        );
      default:
        return null;
    }
  };

  const renderHelpContainer = () => {
    if (integrationType === 'slack') {
      return <SlackIntegrationLink link={integration.integration_data.slack_url} />;
    }
    return null;
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(disabled && 'pointer-events-none opacity-50', 'cursor-pointer')}
      >
        <div className="edit-button">
          <EditIcon />
        </div>
      </DialogTrigger>
      <DialogContent className={`integration-popup ${integrationType}`}>
        <DialogHeader>
          <DialogTitle>Integration Settings</DialogTitle>
        </DialogHeader>
        <div className="content" ref={popupContentRef}>
          {integrationType && renderForm(popupContentRef)}
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button style={{ width: 'auto' }} className="transparent black">
              Cancel
            </Button>
          </DialogClose>
          <Button style={{ width: 'auto' }} type="submit" form="integration-form" className="blue">
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
