import './Button.css';
import React from 'react';
import { clsx } from 'clsx';

const Button = React.forwardRef(
  ({ children, className = 'transparent', onClick, type, form, disabled, ...props }, ref) => {
    return (
      <>
        <button
          {...props}
          className={clsx(
            className,
            'button mt-2.5 w-full md:px-[20px] md:py-[10px] py-[8px] px-[15px] md:h-[45px] h-[31px]',
            disabled && 'disabled',
          )}
          onClick={onClick}
          type={type}
          form={form}
        >
          {children}
        </button>
      </>
    );
  },
);

export default Button;
