import { baseApi } from './base.api';
import constants from 'src/pages/AnalyticsPage/constants';
import moment from 'moment';
import { PATH_ANALYTICS_API } from 'src/constants/api-routes';

const formatDuration = (period = 0, unit = 'seconds') => {
  let parts = [];
  const duration = moment.duration(period, unit);

  if (!duration || duration.toISOString() === 'P0D') return;

  if (duration.years() >= 1) {
    const years = Math.floor(duration.years());
    parts.push(`${years} y`);
  }

  if (duration.months() >= 1) {
    const months = Math.floor(duration.months());
    parts.push(`${months} m`);
  }

  if (duration.days() >= 1) {
    const days = Math.floor(duration.days());
    parts.push(`${days} d`);
  }

  if (duration.hours() >= 1) {
    const hours = Math.floor(duration.hours());
    parts.push(`${hours} h`);
  }

  if (duration.minutes() >= 1) {
    const minutes = Math.floor(duration.minutes());
    parts.push(`${minutes} min`);
  }

  if (duration.seconds() >= 1) {
    const seconds = Math.floor(duration.seconds());
    parts.push(`${seconds}s`);
  }

  return parts.join(' ');
};

const getDateRange = (firstDate, lastDate) => {
  if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day'))
    return [{ date: moment(lastDate).format('ll') }];
  let date = firstDate;
  const dates = [{ date: moment(date).format('ll') }];
  do {
    date = moment(date).add(1, 'day');
    dates.push({ date: date.format('ll') });
  } while (moment(date).isBefore(lastDate));
  return dates;
};

const sortByDate = (parsedResponse) => {
  parsedResponse.data.sort((item, item2) => {
    const isRange = item.date.includes(' - ');
    const first = isRange ? item.date.split(' - ')[0] : item.date;
    const second = isRange ? item2.date.split(' - ')[0] : item2.date;

    return moment(first).diff(moment(second));
  });
};

const formatDate = (date) => {
  const isRange = date.includes(' - ');

  return isRange
    ? date
        .split(' - ')
        .map((datePart) => moment(datePart).format('ll'))
        .join(' - ')
    : moment(date).creationData().format === 'YYYY-MM-DD HH:mm'
      ? // ? moment(date).format('lll')
        moment(date).format('MMM DD, hh:mm A')
      : moment(date).format('ll');
};

const parseByMetric = (metric, dataKey, response, result) => {
  const data = response.data[dataKey];

  if (result.isByUser) {
    Object.keys(data).forEach((crmUserId) => {
      Object.keys(data[crmUserId]).forEach((dateKey) => {
        const date = formatDate(dateKey);

        result.data = result.data || [];

        const dataItem = result.data.find((item) => {
          return item.date === date;
        });

        if (dataItem) {
          dataItem[crmUserId] = dataItem[crmUserId]
            ? {
                ...dataItem[crmUserId],
                [constants[metric]]: data[crmUserId][dateKey] || 0,
              }
            : {
                [constants[metric]]: data[crmUserId][dateKey] || 0,
              };
        } else {
          result.data.push({
            date,
            [crmUserId]: {
              [constants[metric]]: data[crmUserId][dateKey] || 0,
            },
          });
        }
      });

      // // assign zeros
      // if (result.request.date_range === 'day') {
      //   result.data.forEach((item) => {
      //     item[crmUserId] = item[crmUserId] || {};
      //     item[crmUserId][constants[metric]] =
      //       item[crmUserId][constants[metric]] || 0;
      //   });
      // }
    });
  } else {
    data.subdomain &&
      Object.keys(data.subdomain).forEach((key) => {
        const date = formatDate(key);
        const dataItem = result.data.find((item) => {
          return item.date === date;
        });

        if (dataItem) {
          dataItem[constants[metric]] = data.subdomain[key] || 0;
        } else {
          result.data.push({
            date,
            [constants[metric]]: data.subdomain[key] || 0,
          });
        }
      });

    // // assign zeros
    // if (result.request.date_range === 'day') {
    //   result.data.forEach((item) => {
    //     item[constants[metric]] = item[constants[metric]] || 0;
    //   });
    // }
  }
};

const parseResponse = (request, response) => {
  const isByUser = !request.metrics.some((metric) => metric.includes('subdomain_'));
  const result = {
    request,
    isByUser,
    meta: response.meta,
    data: request.date_range === 'day' ? getDateRange(request.start_date, request.end_date) : [],
  };

  result.meta.first_response_time.formattedValue = formatDuration(
    result.meta.first_response_time.value,
    request.time_axis,
  );

  result.meta.response_time.formattedValue = formatDuration(
    result.meta.response_time.value,
    request.time_axis,
  );

  request.metrics.forEach((metric) => {
    let dataKey = metric;

    dataKey = dataKey.replace('subdomain_', '');

    if (!response.data[dataKey]) {
      return;
    }

    parseByMetric(metric, dataKey, response, result);
  });

  return result;
};

export const coAgentAnalyticsService = baseApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCoAgentAnalytics: build.mutation({
      query: (params) => ({
        url: `${PATH_ANALYTICS_API.COAGENT}`,
        method: 'POST',
        body: params,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        let parsedResponse = parseResponse(arg, baseQueryReturnValue);

        if (!parsedResponse) {
          return baseQueryReturnValue;
        }

        sortByDate(parsedResponse);

        return { response: baseQueryReturnValue, parsedResponse };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: fetchedAnalytics } = await queryFulfilled;
          dispatch(
            coAgentAnalyticsService.util.updateQueryData(
              'fetchCoAgentAnalytics',
              undefined,
              (draft) => {
                draft?.push(fetchedAnalytics);
              },
            ),
          );
        } catch {}
      },
    }),
  }),
});

export const { useFetchCoAgentAnalyticsMutation } = coAgentAnalyticsService;
