import React, { useEffect, useState } from 'react';
import { Tabs, TabsList } from 'src/components/ui/tabs';
import { AnalyticsTabsTrigger } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsTabs/AnalyticsTabsTrigger/AnalyticsTabsTrigger';
import { AnalyticsTabsContent } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsTabs/AnalyticsTabsContent/AnalyticsTabsContent';
import { AnalyticsAgentBarChart } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentBarChart/AnalyticsAgentBarChart';
import { AnalyticsAgentLineChart } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentLineChart/AnalyticsAgentLineChart';
import { AnalyticsUsersList } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsTabs/AnalyticsUsersList/AnalyticsUsersList';
import { AnalyticsAgentChartPopup } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentChartPopup/AnalyticsAgentChartPopup';
import { AnalyticsAgentCustomizePopup } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentCustomizePopup/AnalyticsAgentCustomizePopup';

const TABS = [
  {
    tabId: 'requests-resolved-with-ai',
    tabTitle: 'Requests resolved with AI',
    teamColor: '#65D5DC',
  },
  {
    tabId: 'accuracy',
    tabTitle: 'Accuracy',
    teamColor: '#65D5DC',
  },
  {
    tabId: 'bad-suggestions-reported',
    tabTitle: 'Bad suggestions reported',
    teamColor: '#C56A8B',
  },
  {
    tabId: 'average-first-response-time',
    tabTitle: 'Average first response time',
    teamColor: '#4C8FD7',
  },
  {
    tabId: 'average-response-time',
    tabTitle: 'Average response time',
    teamColor: '#4C8FD7',
  },
];

export const AnalyticsTabs = ({ data, teamMembers, onCustomizeChange, ...rest }) => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (data && data.colors) {
      const list = teamMembers
        .map((member, index) => {
          return {
            ...member,
            key: `${member.full_name.replace(/ /g, '-')}-${index}`,
          };
        })
        .filter((member) => {
          const isTeam = member.full_name === 'Team';

          if (data.isByUser) {
            const isSelected = Boolean(
              member?.crm_data?.find(({ crm_user_id }) =>
                data.request.agents.includes(crm_user_id),
              ),
            );

            return !isTeam && isSelected;
          } else {
            return isTeam;
          }
        });

      setUserList(list);
    } else {
      setUserList([]);
    }
  }, [data]);

  const onTabChange = (tabId) => {
    const tab = TABS.find((tab) => tab.tabId === tabId);
    const users = userList.map((user) => {
      if (user.full_name === 'Team') {
        user.color = tab.teamColor;
      }

      return user;
    });

    setUserList(users);
  };

  return (
    <Tabs className="TabsRoot w-full" defaultValue={TABS[0].tabId} onValueChange={onTabChange}>
      <TabsList
        className="TabsList w-full p-0 h-auto flex-wrap justify-between bg-transparent gap-[1%] relative"
        aria-label="Manage your account"
      >
        <AnalyticsTabsTrigger
          title={TABS[0].tabTitle}
          tabId={TABS[0].tabId}
          value={data?.meta?.resolved_with_ai?.value || 0}
          diff={data?.meta?.resolved_with_ai?.diff || 0}
          className={
            'data-[state=active]:bg-[#e0f9fd] bg-[#e0f9fd] data-[state=active]:border-[#e0f9fd] data-[state=active]:drop-shadow-tabs'
          }
        />
        <AnalyticsTabsTrigger
          title={TABS[1].tabTitle}
          tabId={TABS[1].tabId}
          value={data?.meta?.accuracy?.value || 0}
          diff={data?.meta?.accuracy?.diff || 0}
          className={
            'data-[state=active]:bg-[#e0f9fd] bg-[#e0f9fd] data-[state=active]:border-[#e0f9fd] data-[state=active]:drop-shadow-tabs'
          }
        />
        <AnalyticsTabsTrigger
          title={TABS[2].tabTitle}
          tabId={TABS[2].tabId}
          value={data?.meta?.bad_suggestions?.value || 0}
          diff={data?.meta?.bad_suggestions?.diff || 0}
          oppositTickers={true}
          className={
            'data-[state=active]:bg-[#F7EAF4] bg-[#F7EAF4] data-[state=active]:border-[#F7EAF4] data-[state=active]:drop-shadow-tabs'
          }
        />
        <AnalyticsTabsTrigger
          title={TABS[3].tabTitle}
          tabId={TABS[3].tabId}
          value={data?.meta?.first_response_time?.formattedValue || 0}
          diff={data?.meta?.first_response_time?.diff}
          oppositTickers={true}
          className={
            'data-[state=active]:bg-[#DFEEFE] bg-[#DFEEFE] data-[state=active]:border-[#DFEEFE] data-[state=active]:drop-shadow-tabs'
          }
        />
        <AnalyticsTabsTrigger
          title={TABS[4].tabTitle}
          tabId={TABS[4].tabId}
          value={data?.meta?.response_time?.formattedValue || 0}
          diff={data?.meta?.response_time?.diff || 0}
          oppositTickers={true}
          className={
            'data-[state=active]:bg-[#DFEEFE] bg-[#DFEEFE] data-[state=active]:border-[#DFEEFE] data-[state=active]:drop-shadow-tabs'
          }
        />
      </TabsList>
      <AnalyticsTabsContent
        tabId={TABS[0].tabId}
        className={'bg-[#e0f9fd] drop-shadow-tabs-content gap-3.5'}
      >
        <div className={'flex flex-row justify-between items-center mb-2.5'}>
          <AnalyticsUsersList userList={userList} />
          <div className={'flex flex-row gap-2'}>
            <AnalyticsAgentChartPopup
              title={TABS[0].tabTitle}
              teamList={userList}
              className={'bg-[#e0f9fd]'}
            >
              <AnalyticsAgentBarChart
                data={data?.data}
                isByUser={data?.isByUser}
                dataKey={'Requests Resolved with AI'}
                dateRange={data?.request.date_range}
                userColors={data?.colors}
              />
            </AnalyticsAgentChartPopup>
            <AnalyticsAgentCustomizePopup request={data?.request} onSubmit={onCustomizeChange} />
          </div>
        </div>
        <AnalyticsAgentBarChart
          data={data?.data}
          isByUser={data?.isByUser}
          dataKey={'Requests Resolved with AI'}
          dateRange={data?.request.date_range}
          userColors={data?.colors}
        />
      </AnalyticsTabsContent>
      <AnalyticsTabsContent
        tabId={TABS[1].tabId}
        className={'bg-[#e0f9fd] drop-shadow-tabs-content'}
      >
        <div className={'flex flex-row justify-between items-center mb-2.5'}>
          <AnalyticsUsersList userList={userList} />
          <div className={'flex flex-row gap-2'}>
            <AnalyticsAgentChartPopup
              title={TABS[1].tabTitle}
              teamList={userList}
              className={'bg-[#e0f9fd]'}
            >
              <AnalyticsAgentBarChart
                data={data?.data}
                isByUser={data?.isByUser}
                dataKey={'Accuracy'}
                dateRange={data?.request.date_range}
                userColors={data?.colors}
              />
            </AnalyticsAgentChartPopup>
            <AnalyticsAgentCustomizePopup request={data?.request} onSubmit={onCustomizeChange} />
          </div>
        </div>
        <AnalyticsAgentBarChart
          data={data?.data}
          isByUser={data?.isByUser}
          dataKey={'Accuracy'}
          dateRange={data?.request.date_range}
          userColors={data?.colors}
        />
      </AnalyticsTabsContent>
      <AnalyticsTabsContent
        tabId={TABS[2].tabId}
        className={'bg-[#F7EAF4] drop-shadow-tabs-content'}
      >
        <div className={'flex flex-row justify-between items-center mb-2.5'}>
          <AnalyticsUsersList userList={userList} />
          <div className={'flex flex-row gap-2'}>
            <AnalyticsAgentChartPopup
              title={TABS[2].tabTitle}
              teamList={userList}
              className={'bg-[#F7EAF4]'}
            >
              <AnalyticsAgentBarChart
                data={data?.data}
                isByUser={data?.isByUser}
                dataKey={'Bad Suggestions Reported'}
                dateRange={data?.request.date_range}
                color={TABS[2].teamColor}
                userColors={data?.colors}
              />
            </AnalyticsAgentChartPopup>
            <AnalyticsAgentCustomizePopup request={data?.request} onSubmit={onCustomizeChange} />
          </div>
        </div>
        <AnalyticsAgentBarChart
          data={data?.data}
          isByUser={data?.isByUser}
          dataKey={'Bad Suggestions Reported'}
          dateRange={data?.request.date_range}
          color={TABS[2].teamColor}
          userColors={data?.colors}
        />
      </AnalyticsTabsContent>
      <AnalyticsTabsContent
        tabId={TABS[3].tabId}
        className={'bg-[#DFEEFE] drop-shadow-tabs-content'}
      >
        <div className={'flex flex-row justify-between items-center mb-2.5'}>
          <AnalyticsUsersList userList={userList} />
          <div className={'flex flex-row gap-2'}>
            <AnalyticsAgentChartPopup
              title={TABS[3].tabTitle}
              teamList={userList}
              className={'bg-[#DFEEFE]'}
            >
              <AnalyticsAgentLineChart
                data={data?.data}
                isByUser={data?.isByUser}
                dataKey={'Average First Response Time'}
                dateRange={data?.request.date_range}
                timeAxis={data?.request.time_axis}
                color={TABS[3].teamColor}
                userColors={data?.colors}
              />
            </AnalyticsAgentChartPopup>
            <AnalyticsAgentCustomizePopup request={data?.request} onSubmit={onCustomizeChange} />
          </div>
        </div>
        <AnalyticsAgentLineChart
          data={data?.data}
          isByUser={data?.isByUser}
          dataKey={'Average First Response Time'}
          dateRange={data?.request.date_range}
          timeAxis={data?.request.time_axis}
          color={TABS[3].teamColor}
          userColors={data?.colors}
        />
      </AnalyticsTabsContent>
      <AnalyticsTabsContent
        tabId={TABS[4].tabId}
        className={'bg-[#DFEEFE] drop-shadow-tabs-content'}
      >
        <div className={'flex flex-row justify-between items-center mb-2.5'}>
          <AnalyticsUsersList userList={userList} />
          <div className={'flex flex-row gap-2'}>
            <AnalyticsAgentChartPopup
              title={TABS[4].tabTitle}
              teamList={userList}
              className={'bg-[#DFEEFE]'}
            >
              <AnalyticsAgentLineChart
                data={data?.data}
                isByUser={data?.isByUser}
                dataKey={'Average Response Time'}
                dateRange={data?.request.date_range}
                timeAxis={data?.request.time_axis}
                color={TABS[4].teamColor}
                userColors={data?.colors}
              />
            </AnalyticsAgentChartPopup>
            <AnalyticsAgentCustomizePopup request={data?.request} onSubmit={onCustomizeChange} />
          </div>
        </div>
        <AnalyticsAgentLineChart
          data={data?.data}
          isByUser={data?.isByUser}
          dataKey={'Average Response Time'}
          dateRange={data?.request.date_range}
          timeAxis={data?.request.time_axis}
          color={TABS[4].teamColor}
          userColors={data?.colors}
        />
      </AnalyticsTabsContent>
    </Tabs>
  );
};
