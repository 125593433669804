import React, { useEffect, useState } from 'react';
import { useNotification } from 'src/hooks/useNotification';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useGetInvoicePdfQuery } from 'src/services';
import { getErrorMessage } from 'src/utils/get-error-message';
import { useLoader } from 'src/provider/LoaderProvider';

export const PaymentHistoryPdfPopupForm = ({ close, payment }) => {
  const [downloadPdf, setDownloadPdf] = useState(false);
  const notification = useNotification();
  const loader = useLoader();

  const {
    data: invoicePdf,
    error: invoicePdfError,
    isError: invoicePdfIsError,
    isFetching: invoicePdfIsFetching,
    isSuccess: invoicePdfIsSuccess,
  } = useGetInvoicePdfQuery(
    {
      payment_id: payment.id,
    },
    { skip: !downloadPdf },
  );

  useEffect(() => {
    if (invoicePdfIsFetching) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [invoicePdfIsFetching]);

  useEffect(() => {
    if (invoicePdfIsError) {
      notification.error(getErrorMessage(invoicePdfError));
    }
  }, [invoicePdfIsError]);

  useEffect(() => {
    if (invoicePdfIsSuccess) {
      close();
    }
  }, [invoicePdfIsSuccess]);

  useEffect(() => {
    if (invoicePdf?.invoice_pdf_url) {
      const url = invoicePdf.invoice_pdf_url;
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  }, [invoicePdf]);

  const submit = (e) => {
    e.preventDefault();
    setDownloadPdf(true);
  };

  return (
    <form id="download-receipt-form" onSubmit={submit}>
      <div className="flex flex-col gap-3.5">
        <div>
          <p className="text-base font-medium text-ebony font-jamjuree">
            {transformDateHelper(payment.created, DateFormat.MONTH_DAY_YEAR)}
          </p>
        </div>
        <div>
          <p className="text-base font-medium text-ebony">{payment.description}</p>
        </div>
        <div>
          <p className="text-base font-medium text-ebony">
            {payment?.amount
              ? `${payment.amount} ${payment?.currency ? getSymbolFromCurrency(payment.currency) : ''}`
              : '-----'}
          </p>
        </div>
      </div>
    </form>
  );
};
