import React from 'react';

export const AnalyticsUsersList = ({ userList }) => {
  return (
    <div className={'flex flex-row flex-1 gap-4 flex-wrap'}>
      {userList.map((user) => {
        return (
          <div key={user.key} className={'flex flex-row items-center gap-1'}>
            <div className={'rounded-[50%] w-2.5 h-2.5'} style={{ background: user.color }}></div>
            <span className={'text-xs font-medium'}>{user.full_name}</span>
          </div>
        );
      })}
    </div>
  );
};
