import React from 'react';

export const EditIcon = ({ fill = '#05050D' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5 21.0001C4.45 21.0001 3.97933 20.8044 3.588 20.4131C3.19667 20.0218 3.00067 19.5508 3 19.0001V5.0001C3 4.4501 3.196 3.97943 3.588 3.5881C3.98 3.19676 4.45067 3.00076 5 3.0001H13.925L11.925 5.0001H5V19.0001H19V12.0501L21 10.0501V19.0001C21 19.5501 20.8043 20.0211 20.413 20.4131C20.0217 20.8051 19.5507 21.0008 19 21.0001H5ZM9 15.0001V10.7501L18.175 1.5751C18.375 1.3751 18.6 1.2251 18.85 1.1251C19.1 1.0251 19.35 0.975098 19.6 0.975098C19.8667 0.975098 20.121 1.0251 20.363 1.1251C20.605 1.2251 20.8257 1.3751 21.025 1.5751L22.425 3.0001C22.6083 3.2001 22.75 3.4211 22.85 3.6631C22.95 3.9051 23 4.15076 23 4.4001C23 4.6501 22.9543 4.8961 22.863 5.1381C22.7717 5.3801 22.6257 5.60076 22.425 5.8001L13.25 15.0001H9ZM11 13.0001H12.4L18.2 7.2001L17.5 6.5001L16.775 5.8001L11 11.5751V13.0001Z"
        fill={fill}
        fillOpacity="0.5"
      />
    </svg>
  );
};
