import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from 'recharts';
import { formatDuration } from 'src/services/coCustomerAnalyticsService';

export const AnalyticsAgentLineChart = ({
  dataKey = '',
  data = [],
  dateRange = 'day',
  timeAxis = 'seconds',
  color = '#65D5DC',
  isByUser = false,
  userColors,
}) => {
  const isDayRange = dateRange === 'day';
  const userNames = [];
  const chartData = isByUser
    ? data.map((dataItem) => {
        return Object.keys(dataItem).reduce((previousValue, currentValue) => {
          if (currentValue === 'date') {
            return {
              ...previousValue,
              [currentValue]: dataItem[currentValue],
            };
          }

          if (!userNames.includes(currentValue)) {
            userNames.push(currentValue);
          }

          return {
            ...previousValue,
            [currentValue]: dataItem[currentValue][dataKey] || 0,
          };
        }, {});
      })
    : data;
  const tooltipFormatter = (value) => {
    return value ? formatDuration(value, timeAxis) : 0;
  };

  const isSingleDot =
    chartData.filter((charDataItem) => {
      return isByUser ? Object.keys(charDataItem).length > 1 : charDataItem[dataKey];
    }).length <= 1;

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      minHeight={'500px'}
      className={'bg-white rounded-[6px]'}
    >
      <LineChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 50,
          right: 50,
          left: 10,
          bottom: 50,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="1 1" />
        <XAxis dataKey="date">
          <Label
            value={isDayRange ? 'Days' : 'Hours'}
            offset={-10}
            position="insideBottomRight"
            className={'fill-ebony text-sm font-semibold'}
          />
        </XAxis>
        <YAxis>
          <Label
            value={timeAxis}
            position="insideTopLeft"
            className={'fill-ebony text-sm font-semibold'}
            content={({ value }) => {
              return (
                <text
                  offset="0"
                  x="10"
                  y="20"
                  className="recharts-text fill-ebony text-sm font-semibold capitalize"
                  textAnchor="middle"
                  fill="#808080"
                >
                  <tspan x={60} dy="0.71em">
                    {value}
                  </tspan>
                </text>
              );
            }}
          />
        </YAxis>
        <Tooltip
          formatter={tooltipFormatter}
          labelStyle={{
            color: '#FFF',
            fontFamily: 'Bai Jamjuree',
            fontSize: '14px',
          }}
          contentStyle={{
            borderRadius: '5px',
            background: '#05050D',
          }}
          itemStyle={{
            fontFamily: 'Bai Jamjuree',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
          }}
        />
        {Boolean(data.length) && (
          <ReferenceLine
            x={data[parseInt((data.length / 2).toFixed(0)) - 1]['date']}
            stroke="#CCCCCC"
            strokeDasharray="1 1"
          />
        )}
        {isByUser ? (
          userNames.map((userName) => {
            const stroke = userColors ? userColors[userName] : color;

            return (
              <Line
                type="linear"
                key={userName}
                dataKey={userName}
                name={userName}
                stroke={stroke}
                strokeWidth={3}
                connectNulls={true}
                dot={isSingleDot ? { stroke, strokeWidth: 10 } : false}
              />
            );
          })
        ) : (
          <Line
            type="linear"
            dataKey={dataKey}
            name={dataKey}
            stroke={color}
            strokeWidth={3}
            fontSize={'10px'}
            connectNulls={true}
            dot={isSingleDot ? { stroke: color, strokeWidth: 10 } : false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};
