import './StatusBadge.css';
import React from 'react';
import { clsx } from 'clsx';

export const StatusBadge = ({ isActive }) => {
  return (
    <div
      className={clsx(
        'status-wrapper md:gap-[6px] md:text-[12px] text-[0px] leading-[4px] md:leading-[15px] md:py-[6px] md:px-[14px]',
        !isActive && 'onhold',
      )}
      title={isActive ? 'Active' : 'Hold'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <circle
          cx="5"
          cy="5"
          r="5"
          transform="rotate(-180 5 5)"
          fill={isActive ? '#07B5C0' : '#05050d80'}
        />
      </svg>
      {isActive ? 'Active' : 'Hold'}
    </div>
  );
};
