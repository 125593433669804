import React, { useEffect, useState } from 'react';
import Input from '../Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import {
  emailScheme,
  passwordConfirmScheme,
  passwordScheme,
  stringScheme,
} from 'src/utils/validation-schemes';
import { useNotification } from 'src/hooks/useNotification';
import { ProjectsMultiSelect } from 'src/components/ProjectsMultiSelect/ProjectsMultiSelect';
import { useGetAllProjectsQuery } from 'src/services';
import { ROLES, ROLES_PRETTY } from 'src/constants/roles';
import { ProductsOrder } from 'src/constants/products';
import { useGetUsersMeQuery } from 'src/services/usersService';

export const EditUserForm = ({ user, onSubmit }) => {
  const [roles, setRoles] = useState([
    { label: ROLES_PRETTY.cosupport_manager, value: ROLES.COSSUPORT_MANAGER },
    { label: ROLES_PRETTY.admin, value: ROLES.ADMIN },
    { label: ROLES_PRETTY.manager, value: ROLES.MANAGER },
    { label: ROLES_PRETTY.support_team_lead, value: ROLES.SUPPORT_TEAM_LEAD },
    { label: ROLES_PRETTY.support, value: ROLES.SUPPORT },
  ]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const notification = useNotification();
  const { data: userInfo } = useGetUsersMeQuery();
  const {
    handleSubmit,
    control,
    trigger,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      password: '',
      password2: '',
      role: roles.find((role) => role.value === user.role),
    },
    resolver: yupResolver(
      object({
        email: emailScheme({ required: true }).label('Email'),
        first_name: stringScheme({ required: true }).label('First name'),
        last_name: stringScheme({ required: false }).label('Last name'),
        password: passwordScheme({
          required: false,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Password'),
        password2: passwordConfirmScheme({
          required: false,
          min: 3,
          minMessage: 'Min. 3 characters',
        }).label('Confirm password'),
      }),
    ),
  });

  const { data: projects = [] } = useGetAllProjectsQuery();

  useEffect(() => {
    if (projects && projects.length) {
      const clone = [...projects];

      clone.sort((a, b) => {
        return ProductsOrder[a.project_type] - ProductsOrder[b.project_type];
      });

      setSortedProjects(clone);

      const selected = clone.filter(
        (project) =>
          user.projects && user.projects.find((userProject) => userProject.id === project.id),
      );

      setSelectedProjects(selected);
    }
  }, [projects]);

  useEffect(() => {
    if (userInfo?.role !== ROLES.COSSUPORT_MANAGER) {
      setRoles(roles.filter((role) => role.value !== ROLES.COSSUPORT_MANAGER));
    }
  }, [userInfo]);

  const submit = (formValues) => {
    const filteredData = Object.keys(dirtyFields).reduce(
      (acc, key) => {
        if (dirtyFields[key]) {
          acc[key] = formValues[key];
        }
        return acc;
      },
      {
        selectedProjects,
      },
    );

    if (filteredData.role) {
      filteredData.role = filteredData.role.value;
    }

    if (!Object.keys(filteredData).length) {
      notification.info('Nothing changed');
    } else {
      onSubmit(filteredData);
    }
  };

  return (
    <form id="edit-user-form" onSubmit={handleSubmit(submit)}>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'email'}
              label={'Email'}
              placeholder={'email@email.com'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
              onBlur={() => trigger('email')}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="first_name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              autoFocus
              type={'text'}
              label={'First Name'}
              placeholder={'Josh'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
              onBlur={() => trigger('firstName')}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="last_name"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={'Last Name'}
              placeholder={'Brawn'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <ProjectsMultiSelect
          label={'Project'}
          projects={sortedProjects}
          defaultValues={selectedProjects}
          onChange={(selected) => {
            setSelectedProjects(selected);
          }}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'password'}
              label={'Password'}
              placeholder={'••••••••••••••••••••••••••••'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="password2"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'password'}
              label={'Confirm Password'}
              placeholder={'••••••••••••••••••••••••••••'}
              invalidMessage={error?.message}
              value={value}
              isValid={!error?.message}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className={'input-container'}>
        <Controller
          control={control}
          name="role"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type={'select'}
              label={'Role'}
              placeholder={'Admin'}
              invalidMessage={error?.message}
              value={value}
              selectOptions={roles}
              isValid={!error?.message}
              onChange={onChange}
              readonly={
                userInfo.role !== ROLES.COSSUPORT_MANAGER && user.role === ROLES.COSSUPORT_MANAGER
              }
            />
          )}
        />
      </div>
    </form>
  );
};
