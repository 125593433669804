import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { Calendar } from 'src/components/ui/calendar';
import { cn } from 'src/lib/utils';
import { buttonVariants } from 'src/components/ui/button';
import moment from 'moment';

export const AnalyticsDateRangePopupForm = ({ onSubmit, dateDefault }) => {
  const [monthState, setMonthState] = useState(
    moment(dateDefault.to).subtract(1, 'months').toDate(),
  );
  const { watch, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      date: {
        from: '',
        to: '',
      },
    },
    resolver: yupResolver(
      object({
        date: object({
          from: stringScheme({ required: false }).label('Date from'),
          to: stringScheme({ required: false }).label('Date to'),
        }),
      }),
    ),
  });
  const date = watch('date');

  const submit = (formValues) => {
    onSubmit(formValues);
  };

  useEffect(() => {
    setValue('date', dateDefault);
  }, [dateDefault]);

  return (
    <form id="aply-date-filter-form" onSubmit={handleSubmit(submit)}>
      <p className="text-ebony font-semibold text-sm">
        {date && moment(date.from).format('ll')} - {date && moment(date.to).format('ll')}
      </p>
      <div className="flex mt-2.5 gap-2.5 items-center justify-center max-laptop:flex-wrap">
        <Controller
          control={control}
          name="date"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Calendar
              initialFocus
              mode="range"
              disabled={{ after: new Date() }}
              month={monthState}
              defaultValues={dateDefault}
              selected={value}
              onSelect={onChange}
              numberOfMonths={2}
              onMonthChange={setMonthState}
              classNames={{
                day_selected:
                  'bg-purple_opacity_20 text-ebony hover:text-ebony focus:purple_light focus:text-ebony',
                day_today: 'bg-purple_opacity_20 text-accent-foreground',
                day_range_end: 'day-range-end !bg-purple',
                day_range_start: 'day-range-start !bg-purple',
                day_range_middle:
                  'aria-selected:bg-purple_opacity_20 aria-selected:text-accent-foreground',
                day: cn(
                  buttonVariants({ variant: 'ghost' }),
                  'h-9 w-9 p-0 font-normal aria-selected:opacity-100 hover:bg-purple_light',
                ),
              }}
            />
          )}
        />
      </div>
    </form>
  );
};
