import './ViewTypesTabs.css';
import { Tabs, TabsList, TabsTrigger } from 'src/components/ui/tabs';
import { FilesGridViewIcon, FilesListViewIcon } from 'src/assets/icons/FileIcons';
import { getKnowledgeBaseConfig } from 'src/utils/pageStorageConfig';
import { useMediaQuery } from 'src/hooks/useMediaQuery';
import { ViewTypesButton } from 'src/components/FileManager/SearchFilesContainer/ViewTypesButton/ViewTypesButton';

export const ViewTypesTabs = ({ onViewTypeChange }) => {
  const { viewType } = getKnowledgeBaseConfig();
  const isLgDown = useMediaQuery('(max-width: 992px)');

  return isLgDown ? (
    <ViewTypesButton onViewTypeChange={onViewTypeChange} />
  ) : (
    <Tabs
      defaultValue={viewType || 'list'}
      orientation="vertical"
      className={'files-view-type-tabs'}
      onValueChange={onViewTypeChange}
    >
      <TabsList className={'tab-list'}>
        <TabsTrigger value="list">
          <FilesListViewIcon />
          <span>List</span>
        </TabsTrigger>
        <TabsTrigger value="grid">
          <FilesGridViewIcon />
          <span>Grid</span>
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
};
