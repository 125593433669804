import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import { EditIcon } from 'src/assets/icons/editIcon';
import { Button } from 'src/components/ui/button';
import { ProjectEditNameContent } from 'src/components/ProjectSettings/Popups/ProjectEditNameContent';

export const ChangeNameModal = ({ disabled = false }) => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={disabled ? false : open}>
      <DialogTrigger disabled={disabled} asChild>
        <Button
          variant="ghost"
          size="icon"
          className={clsx(disabled && 'pointer-events-none opacity-50')}
        >
          <EditIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit project name</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <ProjectEditNameContent close={closePopup} />
        </div>
      </DialogContent>
    </Dialog>
  );
};
