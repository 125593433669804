import './Accordion.css';
import React, { useState, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import { clsx } from 'clsx';

export const Accordion = ({ className, title, disabled, children, arrowIcon, onClick }) => {
  const [height, setHeight] = useState(0);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setHeight(collapsed ? 0 : 'auto');
  }, [collapsed]);

  const onHeaderClick = () => {
    onClick ? onClick(collapsed, setCollapsed) : setCollapsed(!collapsed);
  };

  return (
    <div
      className={clsx(
        className,
        'accordion-wrapper',
        collapsed ? 'collapsed' : 'expanded',
        disabled && 'opacity-50',
      )}
    >
      <div
        className={clsx('accordion-header', disabled && '!cursor-not-allowed')}
        onClick={disabled ? null : onHeaderClick}
      >
        <div className={'accordion-title'}>{title}</div>
        <div className={'accordion-arrow'}>
          {arrowIcon}
          {!arrowIcon && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="13"
              viewBox="0 0 22 13"
              fill="none"
            >
              <path d="M21 1.00011L10.5 11L1.00001 1.0001" stroke="#07B5C0" strokeWidth="2" />
            </svg>
          )}
        </div>
      </div>
      <AnimateHeight duration={500} height={height}>
        <div className={'accordion-body'}>{children}</div>
      </AnimateHeight>
    </div>
  );
};
