import './ListView.css';
import React, { useEffect } from 'react';
import { ListFolderView } from './ListFolderView';
import { ListFileView } from './ListFileView';
import { clsx } from 'clsx';
import { useShiftSelected } from 'src/hooks/useShiftSelected';

export const FilesManagerListView = ({
  activeBranch,
  updateActiveBranch,
  checkboxVisible,
  handleSingleItemMove,
  handleSingleItemRename,
  handleSingleItemDelete,
  className,
  onFileSelect,
  onCheckedChange,
}) => {
  const [selectedItems, setSelectedItems] = React.useState([]);
  const change = (addOrRemove, files) => {
    // if (!addOrRemove) {
    setSelectedItems(files.map((file) => file.path));
    // } else {
    // if (files.length) {
    //   setSelectedItems(files.map((file) => file.path));
    // }
    // }

    onFileSelect(files);
    // console.log('addOrRemove:', addOrRemove, files);
  };
  const onChange = useShiftSelected(activeBranch.children, change);

  const handleContextMenu = (event) => {
    const path = event.target.dataset.path;

    if (path) {
      event.preventDefault();
      event.stopPropagation();
      const item = activeBranch.children.find((item) => item.path === path);

      if (selectedItems.includes(path)) {
        console.log('context menu:', selectedItems);
      } else {
        console.log('context menu:', [item.path]);
        setSelectedItems([item.path]);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  });

  const handleClick = (e, file) => {
    onChange(e, file);
  };

  const handleDoubleClick = (file) => {
    if (file.type && file.type === 'folder') {
      updateActiveBranch(file);
    }
  };

  const parent = { ...activeBranch.parent, name: '..' };

  return (
    <div className={clsx(className, 'file-list-view')}>
      {!activeBranch.root && (
        <ListFolderView
          className={clsx(selectedItems.includes(parent.path) && 'selected')}
          item={parent}
          onClick={(e) => handleClick(e, activeBranch.parent)}
          onDoubleClick={() => handleDoubleClick(activeBranch.parent)}
        />
      )}
      {activeBranch.children.map((file, index) =>
        file.type === 'folder' ? (
          <ListFolderView
            item={file}
            className={clsx(selectedItems.includes(file.path) && 'selected')}
            key={`${file.name}-${index}`}
            onClick={(e) => handleClick(e, file)}
            onDoubleClick={() => handleDoubleClick(file)}
            onCheckedChange={onCheckedChange}
            data-path={file.path}
            checkboxVisible={checkboxVisible}
            handleSingleItemMove={handleSingleItemMove}
            handleSingleItemRename={handleSingleItemRename}
            handleSingleItemDelete={handleSingleItemDelete}
          />
        ) : (
          <ListFileView
            item={file}
            className={clsx(
              selectedItems.includes(file.path) && 'selected',
              file.state === 'uploading' && 'uploading opacity-50',
            )}
            key={`${file.name}-${index}`}
            onClick={(e) => handleClick(e, file)}
            onCheckedChange={onCheckedChange}
            data-path={file.path}
            checkboxVisible={checkboxVisible}
            handleSingleItemMove={handleSingleItemMove}
            handleSingleItemRename={handleSingleItemRename}
            handleSingleItemDelete={handleSingleItemDelete}
          />
        ),
      )}
    </div>
  );
};
